const SUCCESS = "marketplace/setCopyEventId/SUCCESS";

export default function reducer(state = false, action = {}) {
  switch (action.type) {
    case SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const setCopyEventId = (eventId) => (dispatch) => {
  dispatch({ type: SUCCESS, payload: eventId });
};
