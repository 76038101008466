import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import Login from "./Login";

const INITIAL_STATE = {
  isOpen: false
};

class MobileNav extends React.Component {
  state = INITIAL_STATE;

  toggleDropdown() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  renderMarketplaceLinks() {
    const { appLinks } = this.props;
    let links;
    if (appLinks) {
      links = appLinks.map(({ url, label }) => {
        return (
          <a href={url} target="_blank" rel="noopener noreferrer" key={url}>
            <button className="w-full p-3 font-bold tracking-wide text-lg uppercase header-link">
              {label}
            </button>
          </a>
        );
      });
    }
    return links;
  }

  renderAdminLinks() {
    const { adminLinks } = this.props;
    const links = adminLinks.map(({ url, label }) => {
      return (
        <Link to={url} key={url}>
          <li className="p-3 font-bold tracking-wide uppercase header-link">
            {label}
          </li>
        </Link>
      );
    });

    return links;
  }

  renderLogin() {
    return (
      <Login buttonClasses="w-full p-3 font-bold tracking-wide uppercase header-link" />
    );
  }

  render() {
    const links = this.props.isAdmin
      ? this.renderAdminLinks()
      : this.renderMarketplaceLinks();
    return (
      <nav className="block w-full sm:hidden print:hidden">
        <ul className="flex items-center justify-center flex-wrap">
          <button
            className={`w-full text-center p-3 font-bold tracking-wide text-lg uppercase header-link ${
              this.state.isOpen ? "border-b-2" : ""
            }`}
            onClick={() => this.toggleDropdown()}
          >
            Menu{" "}
            {this.state.isOpen ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </button>
          <div
            className={`${
              this.state.isOpen ? "block w-full text-center" : "hidden"
            }`}
          >
            <div className="border-b">{links}</div>
            {this.renderLogin()}
          </div>
        </ul>
      </nav>
    );
  }
}

MobileNav.propTypes = {
  isAdmin: PropTypes.bool,
  adminLinks: PropTypes.array.isRequired,
  appLinks: PropTypes.array.isRequired
};

export default MobileNav;
