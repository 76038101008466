const initialState = { data: {} };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case "SELL_TICKETS_REQUEST":
      return { ...state, isFetching: true };
    case "SELL_TICKETS_SUCCESS":
      return { ...state, isFetching: false, data: action.payload };
    case "SELL_TICKETS_FAILURE":
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload.message
      };
    default:
      return state;
  }
}

export const sellTickets = () => dispatch => {
  dispatch({ type: "SELL_TICKETS_REQUEST" });
  //do something
};
