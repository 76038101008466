const SUCCESS = "marketplace/eventForm/SUCCESS";

export default function reducer(state = [], action = {}) {
  switch (action.type) {
    case SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const validateEventForm = (invalid, field) => (dispatch, getState) => {
  const errors = getState().admin.eventFormErrors;
  if (invalid && !errors.includes(field)) {
    errors.push(field);
  } else if (!invalid && errors.includes(field)) {
    errors.splice(errors.indexOf(field), 1);
  }
  dispatch({ type: SUCCESS, payload: errors });
};

export const clearFormErrors = () => (dispatch, getState) => {
  let errors = getState().admin.eventFormErrors;
  errors = [];
  dispatch({ type: SUCCESS, payload: errors });
};
