import React from "react";
import PropTypes from "prop-types";

import AdminInput from "../core/AdminInput";
import EventBlock from "./EventBlock";

import uniqid from "uniqid";
import { scrollToTop, validateFieldLength } from "../../../utils";

class EventPools extends React.Component {
  componentDidMount() {
    scrollToTop();
  }

  render() {
    const poolBlocks = [];
    const { pools, displayErrors, poolIds } = this.props;
    if (poolIds.length <= 0) {
      poolIds.push(uniqid());
    }

    for (let i = 0; i < poolIds.length; i++) {
      const id = poolIds[i];
      const pool = pools[poolIds[i]];

      let name, quantity;

      if (pool) {
        name = pool.name;
        quantity = pool.quantity;
      }

      poolBlocks.push(
        <EventBlock
          formBlock="Pool"
          handleFormBlockRemoval={() => this.props.handleBlockRemoval(id)}
          numberOfFormBlocks={poolIds.length}
          key={id}
          name={name}
          emptyBlock={!name && !quantity}
        >
          <AdminInput
            field="name"
            fieldName="Pool Name"
            type="text"
            onChange={this.props.handleBlockChange}
            width="2/3"
            id={id}
            value={name || ""}
            required={true}
            displayError={displayErrors}
            validateField={validateFieldLength}
            maxLength={30}
          />
          <AdminInput
            field="quantity"
            fieldName="Number of Tickets"
            type="number"
            onChange={this.props.handleBlockChange}
            width="1/3"
            id={id}
            value={quantity || ""}
            required={true}
            displayError={displayErrors}
          />
        </EventBlock>
      );
    }
    return poolBlocks;
  }
}

EventPools.propTypes = {
  pools: PropTypes.object.isRequired,
  displayErrors: PropTypes.bool,
  handleBlockChange: PropTypes.func,
  handleBlockRemoval: PropTypes.func,
  poolIds: PropTypes.array
};

export default EventPools;
