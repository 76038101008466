/* eslint-disable no-undef */
export const theme = {
  NAME: "bandwagon",
  TENANT: "mytbs2",
  LINKS: [
    {
      url: "https://bandwagonfanclub.com/",
      label: "BandwagonFanClub"
    },
    {
      url: "https://idealseat.com/",
      label: "IdealSeat"
    }
  ],
  SOCIAL:
    "https://www.instagram.com/idealseat/ https://www.facebook.com/IdealSeat/ https://www.linkedin.com/company/idealseat/",
  HEADING_1: "BandwagonFanClub!",
  HEADING_2: "Welcome to an IdealSeat marketplace for",
  HIDESEARCHBAR: true
};
