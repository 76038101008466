import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { CardElement, injectStripe } from "react-stripe-elements";
import { restrictions } from "@bandwagonfanclub/marketplace-shared";

import Captcha from "./Captcha";
import Buttons from "./Buttons";
import FormInputs from "./FormInputs";
import EmptyPage from "../core/EmptyPage";
import Summary from "./Summary";

import { createPaymentIntent } from "../../ducks/stripeIntent";
import { setOrderSuccess } from "../../ducks/orderSuccess";
import { createClaim } from "../../ducks/claim";
import { selectTickets } from "../../ducks/selectedTickets";
import { setCaptchaToken } from "../../ducks/captchaToken";
import { addToWaitlist } from "../../ducks/waitlist";

const INITIAL_STATE = {
  charging: false,
  error: false,
  claimIsValid: true,
  shippingSameAsBilling: true,
  promo: undefined,
  couponCodes: [],
  previousPromo: undefined,
  errorMessage: "Something went wrong! Try again.",
  disableForm: false
};

// credit card input styling
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px"
      }
    },
    hidePostalCode: true
  };
};

class Payment extends Component {
  state = INITIAL_STATE;

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.cardRef = React.createRef();

    const { ticketBlocks, extras } = this.props.selectedTickets;
    const items = {};
    let waitlistItems = {};

    for (const [blockId, { waitlist, numberOfTickets }] of Object.entries(
      ticketBlocks
    )) {
      if (!waitlist) {
        items[blockId] = {
          count: parseInt(numberOfTickets, 10)
        };
      } else {
        waitlistItems = {
          count: parseInt(numberOfTickets, 10)
        };
      }
    }

    for (const [extraId, { numberOfTickets }] of Object.entries(extras)) {
      items[extraId] = { count: parseInt(numberOfTickets, 10) };
    }

    this.items = items;
    this.waitlist = waitlistItems;
  }

  componentDidMount() {
    this.props.createPaymentIntent({ items: this.items });
  }

  componentDidUpdate(prevProps) {
    const { stripeIntent } = this.props;
    const { couponDetails } = stripeIntent;
    if (stripeIntent !== prevProps.stripeIntent && stripeIntent.error) {
      const disableFlag =
        stripeIntent.error !== "MALFORMED_ID" &&
        stripeIntent.error !== "NO_SUCH_COUPON";
      if (!disableFlag) {
        this.props.createPaymentIntent({ items: this.items });
      }
      let errorMessage = "Something went wrong, try again later!";
      if (stripeIntent.error === "MALFORMED_ID") {
        errorMessage =
          "Coupons must be between 3 and 15 characters and contain only letters and numbers.";
      } else if (stripeIntent.error === "NO_SUCH_COUPON") {
        errorMessage = "Coupon does not exist.";
      }
      this.setState({
        error: true,
        errorMessage: errorMessage,
        disableForm: disableFlag
      });
    }

    if (
      couponDetails &&
      couponDetails !== prevProps.stripeIntent.couponDetails
    ) {
      this.setState({
        couponCodes: Object.keys(couponDetails)
      });
    }
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheckboxChange = e => {
    this.setState({ [e.target.name]: e.target.checked });
  }

  handlePromoChange = e => {
    this.setState({ promo: e.target.value });
  };

  handleApplyPromo = ev => {
    ev.preventDefault();
    const { couponCodes, promo } = this.state;
    const promoNotYetApplied =
      this.props.stripeIntent.couponDetails[promo] === undefined;
    const promoHasChanged = promo !== this.state.previousPromo;

    if (promoNotYetApplied && promoHasChanged) {
      couponCodes.push(promo);
      this.props.createPaymentIntent({
        items: this.items,
        couponCodes: couponCodes
      });
    }
    this.setState({ error: false, errorMessage: "", disableFlag: false });
    this.setState({ previousPromo: this.state.promo });
  };

  checkClaim() {
    const { ticketBlocks, claimRestrictionArray } = this.props.selectedTickets;
    const items = {};
    const sectionIds = Object.keys(ticketBlocks);

    sectionIds.forEach(section => {
      items[section] = ticketBlocks[section].numberOfTickets;
    });

    try {
      restrictions.checkClaimRestrictions(claimRestrictionArray, { items });
    } catch (error) {
      this.setError();
      this.setState({
        errorMessage: "Too many tickets selected!"
      });
      this.props.setCaptchaToken(null);
    }
  }

  setError() {
    const timeout = 2000;
    this.setState({ error: true });
    setTimeout(() => {
      this.setState({ error: false });
    }, timeout);
  }

  async submit(ev) {
    ev.preventDefault();
    await this.checkClaim();
    const { email, name, error } = this.state;
    const { stripeIntent, selectedTickets, captchaToken } = this.props;
    const { bwClaimCookie, intentId, price } = stripeIntent;
    const { event, shippingAddressRequired } = selectedTickets;
    const data = {
      bwClaimCookie,
      intentId,
      email,
      name,
      captchaToken
    };

    if (shippingAddressRequired) {
      if (this.state.shippingSameAsBilling) {
        const {
          billing_address_line1: line1,
          billing_address_line2: line2,
          billing_address_city: city,
          billing_address_state: state,
          billing_address_zip: zip
        } = this.state;
        data.shippingAddress = `${line1}, ${line2 ? `${line2}, ` : ""}${city}, ${state} ${zip}`;
      } else {
        const {
          shipping_address_line1: line1,
          shipping_address_line2: line2,
          shipping_address_city: city,
          shipping_address_state: state,
          shipping_address_zip: zip
        } = this.state;
        data.shippingAddress = `${line1}, ${line2 ? `${line2}, ` : ""}${city}, ${state} ${zip}`;
      }
    }

    if (captchaToken && !error) {
      this.setState({ charging: true });
      const { error, payload } = await this.props.createClaim(event, data);

      if (error) {
        this.setState({
          charging: false,
          error: true
        });
        this.props.setCaptchaToken(null);

        if (!payload.message.includes("Captcha was unsuccessful")) {
          this.setState({
            claimIsValid: false
          });
        }
      } else {
        if (price !== "USD 0.00") {
          this.processStripeCharge();
        } else {
          this.setState({ charging: false });
          this.props.selectTickets({});
          this.props.setOrderSuccess();
        }
        if (this.waitlist.count) {
          this.props.addToWaitlist(event, email, {
            items: { waitlist: this.waitlist }
          });
        }
      }
    } else {
      this.setError();
    }
  }

  async processStripeCharge() {
    const stripe_secret = this.props.stripeIntent.intentClientSecret;
    // Add paymentIntent to following const to access success data
    const { error } = await this.props.stripe.confirmCardPayment(
      stripe_secret,
      {
        payment_method: {
          card: this.cardRef.current.getElement(),
          billing_details: {
            name: this.state.name,
            email: this.state.email,
            address: {
              city: this.state.billing_address_city,
              line1: this.state.billing_address_line1,
              line2: this.state.billing_address_line2,
              postal_code: this.state.billing_address_zip,
              state: this.state.billing_address_state
            }
          }
        }
      }
    );

    if (error) {
      this.setError();
      this.setState({
        charging: false,
        errorMessage: "There was a problem processing your payment, try again!"
      });
      this.props.setCaptchaToken(null);
    } else {
      this.setState({ charging: false });
      this.props.selectTickets({});
      this.props.setOrderSuccess();
    }
  }

  renderCreditCardInput() {
    const { shippingSameAsBilling } = this.state;
    return (
      <div className={`py-3 border-t${shippingSameAsBilling ? "" : " mt-2"}`}>
        <label>
          <p className="mb-2 font-bold">Credit Card</p>
        </label>
        <div
          className={`p-3 border bg-white ${
            this.state.charging ? "pointer-events-none opacity-50" : ""
          }`}
        >
          <CardElement ref={this.cardRef} {...createOptions()} />
        </div>
      </div>
    );
  }

  renderForm() {
    const { charging, disableForm, shippingSameAsBilling } = this.state;
    const { selectTickets, captchaToken, selectedTickets } = this.props;
    const { price } = this.props.stripeIntent;
    const resetCaptcha = captchaToken ? false : true;
    return (
      <form
        onSubmit={this.submit}
        className="md:w-1/2 w-full flex flex-wrap flex-col"
      >
        <h1 className="text-2xl py-3 mb-3 border-b w-full">
          {this.props.heading}
        </h1>
        {this.renderError()}
        <FormInputs
          onChange={this.handleInputChange}
          handleCheckboxChange={this.handleCheckboxChange}
          disabled={charging || disableForm}
          selectedTickets={selectedTickets}
          shippingSameAsBilling={shippingSameAsBilling}
        />
        {price && price !== "USD 0.00" && this.renderCreditCardInput()}
        {disableForm ? null : (
          <React.Fragment>
            <div className="flex flex-wrap justify-center my-2">
              <Captcha error={resetCaptcha} />
            </div>
            <Buttons charging={charging} selectTickets={selectTickets} />
          </React.Fragment>
        )}
      </form>
    );
  }

  renderSummary() {
    const { selectedTickets, stripeIntent } = this.props;
    return (
      <Summary
        intent={stripeIntent}
        selectedTickets={selectedTickets}
        handlePromoChange={this.handlePromoChange}
        handleApplyPromo={this.handleApplyPromo}
      />
    );
  }

  renderError() {
    const { error, errorMessage } = this.state;
    if (error) {
      return (
        <p className="text-red-500 text-xl text-center py-2">{errorMessage}</p>
      );
    }
    return null;
  }

  renderRejectedClaim() {
    this.props.selectTickets({});
    return (
      <EmptyPage
        heading="Oops! Those tickets are no longer available!"
        buttonLabel="Shop for New Tickets"
      />
    );
  }

  render() {
    const { claimIsValid } = this.state;

    if (!claimIsValid) {
      return this.renderRejectedClaim();
    }
    return (
      <React.Fragment>
        {this.renderForm()}
        {this.renderSummary()}
      </React.Fragment>
    );
  }
}

Payment.propTypes = {
  addToWaitlist: PropTypes.func.isRequired,
  captchaToken: PropTypes.string,
  createClaim: PropTypes.func.isRequired,
  createPaymentIntent: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  selectedTickets: PropTypes.object.isRequired,
  selectTickets: PropTypes.func.isRequired,
  setCaptchaToken: PropTypes.func.isRequired,
  setOrderSuccess: PropTypes.func.isRequired,
  stripeIntent: PropTypes.object,
  stripe: PropTypes.object
};

const mapStateToProps = state => {
  return {
    stripeIntent: state.stripeIntent.stripe,
    claim: state.claim,
    captchaToken: state.captchaToken,
    selectedTickets: state.selectedTickets
  };
};

export default injectStripe(
  connect(mapStateToProps, {
    createPaymentIntent,
    setOrderSuccess,
    createClaim,
    selectTickets,
    setCaptchaToken,
    addToWaitlist
  })(Payment)
);
