import React from "react";
import PropTypes from "prop-types";
import Button from "../core/Button";

const INITIAL_STATE = {
  checkoutDisabled: false,
  alertMaxTicketsError: false
};

const decimals = 2;

class Order extends React.Component {
  state = INITIAL_STATE;

  componentDidUpdate() {
    const { actualTicketCount } = this.props.ticketCount;

    if (actualTicketCount && !this.state.checkoutDisabled) {
      this.setState({ checkoutDisabled: true });
    } else if (!actualTicketCount && this.state.checkoutDisabled) {
      this.setState({ checkoutDisabled: false });
    }
  }

  renderLineItems(items) {
    const lineItems = [];
    for (const { name, total, numberOfTickets } of Object.values(items)) {
      lineItems.push(
        <div className="flex justify-between p-3" key={name}>
          <p>
            {numberOfTickets} X {name}
          </p>
          <p className="font-bold">${total}</p>
        </div>
      );
    }
    return lineItems;
  }

  renderMaxTicketError() {
    const { alertMaxTicketsError } = this.state;
    const {
      ticketCount,
      selectedTickets,
      event,
      restrictedBlocks
    } = this.props;
    const { maxCount, actualTicketCount } = ticketCount;
    const restrictedSections = [];
    let additionalMessage;
    const ticketBlockIds = Object.keys(selectedTickets.ticketBlocks);
    const { perClaimRestrictions } = event;

    ticketBlockIds.forEach(section => {
      if (restrictedBlocks[0] && restrictedBlocks[0].includes(section)) {
        restrictedSections.push(selectedTickets.ticketBlocks[section].section);
      }
    });

    if (perClaimRestrictions.length > 1) {
      additionalMessage = ` from ${restrictedSections.join(" and ")}`;
    }

    if (maxCount && actualTicketCount) {
      const maxTicketsPerTransaction = maxCount;
      const maxTicketsIsPlural = maxCount > 1 ? "s" : "";
      const ticketsToRemove = actualTicketCount - maxCount;
      const plural = ticketsToRemove > 1 && "s";

      return (
        <div
          className={`px-3 font-bold ${alertMaxTicketsError && "text-red-500"}`}
        >
          Maximum {maxTicketsPerTransaction} ticket{maxTicketsIsPlural} per
          order
          {additionalMessage}, please remove {ticketsToRemove} ticket
          {plural}!
        </div>
      );
    }
    return null;
  }

  disabledButtonOnClick() {
    const timeout = 2500;
    this.setState({ alertMaxTicketsError: true });
    setTimeout(() => {
      this.setState({ alertMaxTicketsError: false });
    }, timeout);
  }

  renderButton() {
    if (this.state.checkoutDisabled) {
      return (
        <Button
          label="Checkout"
          extraClass="mt-8 w-1/2"
          onClick={() => this.disabledButtonOnClick()}
        />
      );
    }
    return (
      <Button
        label="Checkout"
        isLink={true}
        path="/checkout"
        extraClass="mt-8 w-1/2"
      />
    );
  }

  renderTotal() {
    const { ticketBlocks, extras } = this.props.selectedTickets;
    let total = 0;
    for (const ticket of Object.values(ticketBlocks)) {
      total += parseFloat(ticket.total);
    }
    for (const extra of Object.values(extras)) {
      total += parseFloat(extra.total);
    }
    return (
      <div className="flex justify-between p-3 border-t font-bold">
        <p>Total:</p>
        <p>${total.toFixed(decimals)}</p>
      </div>
    );
  }

  render() {
    const { ticketBlocks, extras } = this.props.selectedTickets;
    const hasTickets = ticketBlocks && Object.entries(ticketBlocks).length > 0;
    const hasExtras = extras && Object.entries(extras).length > 0;
    const lineItems = [];

    if (hasTickets) {
      lineItems.push(this.renderLineItems(ticketBlocks));
    }

    if (hasExtras) {
      lineItems.push(this.renderLineItems(extras));
    }

    if (hasTickets || hasExtras) {
      return (
        <div className="mx-3 mt-16 sticky">
          <h1 className="text-2xl mb-6">Order Summary</h1>
          <div className="border p-3 bg-white">
            {this.renderMaxTicketError()}
            {lineItems}
            {this.renderTotal()}
            <div className="flex justify-end">{this.renderButton()}</div>
          </div>
        </div>
      );
    }
    return null;
  }
}

Order.propTypes = {
  selectedTickets: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  ticketCount: PropTypes.object,
  restrictedBlocks: PropTypes.array
};

export default Order;
