import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import PropTypes from "prop-types";

class DefaultLayout extends React.Component {
  render() {
    return (
      <div className="flex flex-col min-h-screen">
        <Header isAdmin={this.props.isAdmin} />
        {this.props.children}
        <Footer isAdmin={this.props.isAdmin} />
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isAdmin: PropTypes.bool
};

export default DefaultLayout;
