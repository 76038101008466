const REQUEST = "marketplace/stripeKey/REQUEST";
const SUCCESS = "marketplace/stripeKey/SUCCESS";
const FAILURE = "marketplace/stripeKey/FAILURE";
const DESTROY = "marketplace/stripeKey/DESTROY";

const initialState = { key: undefined };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case SUCCESS:
      return { ...state, isFetching: false, key: action.payload };
    case DESTROY:
      return { ...state, isFetching: false, key: undefined };
    case FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload.message
      };
    default:
      return state;
  }
}

export const getStripeKey = clearKey => (dispatch, getState) => {
  dispatch({ type: REQUEST });
  if (clearKey) {
    dispatch({ type: DESTROY });
    return null;
  }
  return getState().apis.marketplace
    .get("/config/stripePublishableKey")
    .then(results => dispatch({ type: SUCCESS, payload: results.data }))
    .catch(error => dispatch({ type: FAILURE, payload: error, error: true }));
};
