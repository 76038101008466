import React from "react";
import { connect } from "react-redux";
import { restrictions } from "@bandwagonfanclub/marketplace-shared";

import { getEvent } from "../../ducks/event";
import { selectTickets } from "../../ducks/selectedTickets";
import MainContainer from "../core/MainContainer";
import Content from "./Content";

const INITIAL_STATE = {
  soldOut: false,
  restrictedBlocks: []
};

class Event extends React.Component {
  state = INITIAL_STATE;

  componentDidMount() {
    const { selectedTickets } = this.props;
    this.props.getEvent(this.props.match.params.id);
    if (selectedTickets.event !== this.props.match.params.id) {
      this.props.selectTickets({});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedTickets, event } = this.props;
    const { ticketBlocks, perClaimRestrictions } = event;
    const selectedTicketBlocks = selectedTickets.ticketBlocks || {};
    const selectedExtras = selectedTickets.extras || {};
    let maxCount, actualTicketCount;
    const restrictedBlocks = [];
    const items = {};

    for (const [blockId, { numberOfTickets }] of Object.entries(
      selectedTicketBlocks
    )) {
      items[blockId] = numberOfTickets;
    }

    for (const [extraId, { numberOfTickets }] of Object.entries(
      selectedExtras
    )) {
      items[extraId] = numberOfTickets;
    }

    if (Object.keys(items).length > 0) {
      try {
        restrictions.checkClaimRestrictions(perClaimRestrictions, {
          items
        });
        maxCount = undefined;
        actualTicketCount = undefined;
      } catch (error) {
        const result = error.restrictionFailures.filter(
          element => element.restriction.type === "maxTicketCount"
        );

        result.forEach(item => {
          const blocks = item.restriction.includedBlocks;
          if (!restrictedBlocks.includes(blocks)) {
            restrictedBlocks.push(blocks);
          }
        });

        maxCount = result[0].restriction.maxCount;
        actualTicketCount = result[0].actualTicketCount;
      }
    }

    if (prevState.actualTicketCount !== actualTicketCount) {
      this.setState({ actualTicketCount });
    }
    if (prevState.maxCount !== maxCount) {
      this.setState({ maxCount });
    }
    if (prevState.restrictedBlocks.length !== restrictedBlocks.length) {
      this.setState({ restrictedBlocks });
    }

    if (event !== prevProps.event) {
      let availableTicketsTotal = 0;
      let totalPooledTickets = 0;
      if (ticketBlocks) {
        Object.keys(ticketBlocks).forEach(item => {
          if (ticketBlocks[item].poolKey) {
            availableTicketsTotal += ticketBlocks[item].availableTickets;
            totalPooledTickets += ticketBlocks[item].totalTickets;
          }
        });
        if (availableTicketsTotal === 0 && totalPooledTickets > 0) {
          this.setState({ soldOut: true });
        }
      }
    }
  }

  renderContent() {
    const { event, isFetching, selectedTickets, selectTickets } = this.props;
    const {
      soldOut,
      maxCount,
      actualTicketCount,
      restrictedBlocks
    } = this.state;
    const ticketCount = {
      maxCount,
      actualTicketCount
    };
    return (
      <Content
        event={event}
        isFetching={isFetching}
        selectedTickets={selectedTickets}
        selectTickets={selectTickets}
        soldOut={soldOut}
        ticketCount={ticketCount}
        restrictedBlocks={restrictedBlocks}
      />
    );
  }

  render() {
    return (
      <MainContainer extraClass="py-3">{this.renderContent()}</MainContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    event: state.event.data,
    isFetching: state.event.isFetching,
    selectedTickets: state.selectedTickets
  };
};

export default connect(mapStateToProps, {
  getEvent,
  selectTickets
})(Event);
