import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Textarea from "../../core/Textarea";
import { validateEventForm } from "../../../ducks/admin/eventForm";

export class AdminTextarea extends React.Component {
  constructor(props) {
    super(props);
    this.inputId = this.props.field + (this.props.id || "");
  }

  render() {
    const {
      containerClass,
      field,
      label,
      value,
      displayError,
      required,
      id,
      onChange,
      collapsible,
      validateField,
      maxLength
    } = this.props;
    let fieldIsInvalid = false;
    let errorMessage;
    const valueWithoutHtmlMarkup = value.replace(/<[^>]+>/g, "");

    if (required) {
      fieldIsInvalid = valueWithoutHtmlMarkup.length <= 0;
      errorMessage = "This field is required.";
      this.props.validateEventForm(fieldIsInvalid, this.inputId);
    }

    // only validateField if it has a value
    if (value && validateField) {
      const field = validateField(valueWithoutHtmlMarkup, maxLength);
      fieldIsInvalid = field.error;
      errorMessage = field.errorMessage;
      this.props.validateEventForm(fieldIsInvalid, this.inputId);
    }

    return (
      <Textarea
        containerClass={containerClass}
        field={field}
        label={label}
        value={value}
        required={required}
        displayError={displayError}
        id={id}
        onChange={onChange}
        error={fieldIsInvalid && displayError && required}
        collapsible={collapsible}
        errorMessage={errorMessage}
      />
    );
  }
}

AdminTextarea.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string,
  displayError: PropTypes.bool,
  validateEventForm: PropTypes.func.isRequired,
  containerClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  collapsible: PropTypes.bool,
  validateField: PropTypes.func,
  maxLength: PropTypes.number
};

export default connect(null, { validateEventForm })(AdminTextarea);
