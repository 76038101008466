const REQUEST = "marketplace/admin/event/REQUEST";
const UPDATE_EVENT = "marketplace/admin/event/UPDATE_EVENT";
const CREATE_BLOCK = "marketplace/admin/event/CREATE_BLOCK";
const UPDATE_BLOCK = "marketplace/admin/event/UPDATE_BLOCK";
const DELETE_EVENT = "marketplace/admin/event/DELETE_EVENT";
const FAILURE = "marketplace/admin/event/FAILURE";

const initialState = {};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true, success: false };
    case UPDATE_EVENT:
      return { ...state, isFetching: false, success: action.payload };
    case CREATE_BLOCK:
      return { ...state, isFetching: false, success: action.payload };
    case UPDATE_BLOCK:
      return { ...state, isFetching: false, success: action.payload };
    case DELETE_EVENT:
      return { ...state, isFetching: false, success: action.payload };
    case FAILURE:
      return { ...state, isFetching: false, success: action.payload };
    default:
      return state;
  }
}

export const updateEvent = (data, eventSlug) => (dispatch, getState) => {
  dispatch({ type: REQUEST });
  // TODO: pass along errors
  return getState().apis.marketplace
    .put(`/events/${eventSlug}`, data)
    .then(() => dispatch({ type: UPDATE_EVENT, payload: true }))
    .catch(() => dispatch({ type: FAILURE, payload: false }));
};

export const patchEvent = (patch, eventSlug) => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .patch(`/events/${eventSlug}`, patch)
    .then(() => dispatch({ type: UPDATE_EVENT, payload: true }))
    .catch(() => dispatch({ type: FAILURE, payload: false }));
};

export const createEventBlock = (data, eventSlug) => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .post(`/events/${eventSlug}/blocks`, data)
    .then(results =>
      dispatch({ type: CREATE_BLOCK, payload: results.data.newItemId })
    )
    .catch(() => dispatch({ type: FAILURE, payload: false }));
};

export const createExtra = (data, eventSlug) => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .post(`/events/${eventSlug}/extras`, data)
    .then(results =>
      dispatch({ type: CREATE_BLOCK, payload: results.data.newItemId })
    )
    .catch(() => dispatch({ type: FAILURE, payload: false }));
};

export const updateEventBlock =
    (eventSlug, blockId, patch) => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .patch(`/events/${eventSlug}/blocks/${blockId}`, patch)
    .then(results => dispatch({ type: UPDATE_BLOCK, payload: results }))
    .catch(() => dispatch({ type: FAILURE, payload: false }));
};

export const updateExtra =
    (eventSlug, extraId, patch) => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .patch(`/events/${eventSlug}/extras/${extraId}`, patch)
    .then(results => dispatch({ type: UPDATE_BLOCK, payload: results }))
    .catch(() => dispatch({ type: FAILURE, payload: false }));
};
