import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import InputLabel from "../../core/InputLabel";

import { DatePicker } from "antd";
import "antd/dist/antd.css";

class AdminDateInput extends React.Component {
  render() {
    const {
      allowClear,
      containerClass,
      disabledDate,
      field,
      label,
      onChange,
      value,
      required,
      id,
      helpText,
      width
    } = this.props;

    const fieldId = id ? `${id}-${field}` : field;

    return (
      <div className={`w-full${width ? ` sm:w-${width}` : ""} p-2 ${containerClass}`}>
        <InputLabel
          field={fieldId}
          label={label}
          required={required}
          helpText={helpText}
        />
        <DatePicker
          allowClear={allowClear}
          format={"MM/DD/YYYY"}
          disabledDate={disabledDate}
          value={value ? moment(value) : field === "start_date" ? moment() : ""}
          onChange={date => {
            onChange({
              target: {
                name: field,
                value: date ? date.format("YYYY-MM-DD") : ""
              }
            })
          }}
          className="p-2 w-full"
          style={{ borderColor: "#e2e8f0" }}
        />
      </div>
    );
  }
}

AdminDateInput.propTypes = {
  allowClear: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  containerClass: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string,
  helpText: PropTypes.string,
  disabledDate: PropTypes.func,
  width: PropTypes.string
};

export default AdminDateInput;
