const REQUEST = "marketplace/admin/events/REQUEST";
const SUCCESS = "marketplace/admin/events/SUCCESS";
const FAILURE = "marketplace/admin/events/FAILURE";

const initialState = {};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case SUCCESS:
      return { ...state, isFetching: false, data: action.payload };
    case FAILURE:
      return { ...state, isFetching: false, error: action.payload };
    default:
      return state;
  }
}

export const getAdminEvents = (data) => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .get("/admin/events/placeholder", data)
    .then((results) => dispatch({ type: SUCCESS, payload: results }))
    .catch((error) => dispatch({ type: FAILURE, payload: error.response.data }));
};
