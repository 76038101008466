import React from "react";
import PropTypes from "prop-types";

class ErrorMessage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div
        className="text-center text-red-500 p-3 border-b-2 bg-red-100"
        data-testid="form-error"
      >
        {this.props.message}
      </div>
    );
  }
}

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired
};

export default ErrorMessage;
