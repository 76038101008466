import React from "react";
import moment from "moment";
import TableContainer from "../core/TableContainer";
import Content from "./Content";

export class EventsList extends React.Component {
  render() {
    return (
      <TableContainer
        paginationWidth="2/3"
        dataType="events"
        after={moment()
          .utc()
          .format()}
      >
        <Content />
      </TableContainer>
    );
  }
}

export default EventsList;
