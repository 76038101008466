export default function reducer(state = null, action = {}) {
  switch (action.type) {
    case "SET_CAPTCHA_TOKEN_ACTION":
      return action.payload;
    default:
      return state;
  }
}

export const setCaptchaToken = captchaToken => dispatch => {
  dispatch({ type: "SET_CAPTCHA_TOKEN_ACTION", payload: captchaToken });
};
