import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Extra from "./EventExtra";

import uniqid from "uniqid";
import { scrollToTop } from "../../../utils";
import { validateEventForm } from "../../../ducks/admin/eventForm";

class EventExtras extends React.Component {
  componentDidMount() {
    scrollToTop();
  }

  render() {
    const itemBlocks = [];
    const {
      extras,
      displayErrors,
      extraIds,
      handleBlockChange,
      handleBlockRemoval,
      validateEventForm,
      handleImageChange,
      slug
    } = this.props;

    if (extraIds.length <= 0) {
      extraIds.push(uniqid());
    }

    let numberOfExtras = 0;
    for (const id of extraIds) {
      if (!extras[id] || extras[id].status !== "archived") {
        numberOfExtras += 1;
      }
    }

    for (const id of extraIds) {
      itemBlocks.push(
        <Extra
          extra={extras[id] ? { ...extras[id] } : null}
          handleBlockChange={handleBlockChange}
          handleBlockRemoval={handleBlockRemoval}
          handleImageChange={handleImageChange}
          displayErrors={displayErrors}
          validateEventForm={validateEventForm}
          numberOfFormBlocks={numberOfExtras}
          slug={slug}
          id={id}
          key={id}
        />
      );
    }
    return itemBlocks;
  }
}

EventExtras.propTypes = {
  extras: PropTypes.object.isRequired,
  handleBlockChange: PropTypes.func,
  handleBlockRemoval: PropTypes.func,
  handleImageChange: PropTypes.func,
  extraIds: PropTypes.array,
  displayErrors: PropTypes.bool,
  validateEventForm: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired
};

export default connect(null, { validateEventForm })(EventExtras);
