// DUCKS: https://github.com/erikras/ducks-modular-redux
import { combineReducers } from "redux";
import captchaKey from "./captchaKey";
import captchaToken from "./captchaToken";
import claim from "./claim";
import event from "./event";
import events from "./events";
import highlights from "./highlights";
import orderSuccess from "./orderSuccess";
import selectedTickets from "./selectedTickets";
import sellTickets from "./sell";
import stripeKey from "./stripeKey";
import stripeIntent from "./stripeIntent";
import purchasedTickets from "./purchasedTickets";
import ticketConfirmation from "./ticketConfirmation";
import waitlist from "./waitlist";
import admin from "./admin";

const reducers = combineReducers({
  apis: state => state || {},
  captchaKey,
  captchaToken,
  claim,
  event,
  events,
  highlights,
  orderSuccess,
  purchasedTickets,
  selectedTickets,
  sellTickets,
  stripeKey,
  stripeIntent,
  ticketConfirmation,
  waitlist,
  admin
});

export default reducers;
