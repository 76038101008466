import React from "react";
import PropTypes from "prop-types";
import MainContainer from "../core/MainContainer";
import Button from "../core/Button";

import { rollbarConsumer } from "../../rollbar-context";

class ErrorPage extends React.Component {

  componentDidMount() {
    this.props.rollbar.error(this.messageToRender());
  }

  messageToRender() {
    return this.props.message
        ? this.props.message
        : "Oops, this page doesn't exist!";
  }

  renderButton() {
    const { isAdmin } = this.props;
    if (isAdmin) {
      return <Button label="Back to Admin" path="/admin" isLink={true} />;
    }
    return (
      <Button label="Shop for Event Tickets" path="/events" isLink={true} />
    );
  }
  render() {
    const { isAdmin } = this.props;

    return (
      <MainContainer
        extraClass="justify-center items-center mr-3 ml-3 sm:mr-auto sm:ml-auto"
        isAdmin={isAdmin}
      >
        <h1 className="text-2xl text-center">{this.messageToRender()}</h1>
        {this.renderButton()}
      </MainContainer>
    );
  }
}

ErrorPage.propTypes = {
  isAdmin: PropTypes.bool,
  message: PropTypes.string,
  rollbar: PropTypes.object.isRequired
};

export default rollbarConsumer(ErrorPage);
