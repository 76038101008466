import React from "react";
import PropTypes from "prop-types";
import Item from "./Item";

import {
  getMaxTicketRestrictions,
  eventStatus,
  getNumberOfActiveEventItems
} from "../../utils";

const INITIAL_STATE = {
  displayLowTickets: false,
  displayError: false
};

const lowTicketPercentage = 0.1;
const unlimitedQuantity = 99999;

class ItemContainer extends React.Component {
  state = INITIAL_STATE;

  componentDidMount() {
    // display warning if less than 10% tickets left
    const { percentAvailable, unlimited } = this.props;
    if (!unlimited && percentAvailable < lowTicketPercentage) {
      this.setState({ displayLowTickets: true });
    }
  }

  renderSoldOutItem(name) {
    const { isVariation } = this.props;
    return (
      <div className={`${isVariation ? "" : "my-5"} w-full`}>
        <div
          className={`${
            isVariation ? "border-t px-6 py-8" : "border mt-3 p-6"
          } flex justify-between items-center bg-white`}
        >
          <div>
            <p>
              <span className="line-through">{name}</span>:{" "}
              <span className="font-bold text-red-500">Sold Out!</span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderItem(item) {
    const { displayLowTickets, displayError } = this.state;
    const {
      selectedTickets,
      maxTicketsInPool,
      event,
      name,
      itemType,
      isVariation,
      unlimited
    } = this.props;
    const selectedItems = selectedTickets[itemType];
    let { availableQuantity } = item;
    const price = item.price.replace("USD ", "$");
    const maxTicketRestrictions = getMaxTicketRestrictions(
      event.perClaimRestrictions
    );
    const numberOfMaxTicketRestrictions = maxTicketRestrictions.length;
    const numberOfItems = getNumberOfActiveEventItems(event);
    const displayRestrictionAsterisk =
      numberOfMaxTicketRestrictions === 1 &&
      maxTicketRestrictions[0].includedBlocks.includes(item.itemId) &&
      numberOfItems !== maxTicketRestrictions[0].includedBlocks.length;
    const { availability, visibility } = event;
    const eventIsCanceled =
      eventStatus(availability, visibility) === "Canceled";

    //setting input value in case user navigates away from page and comes back
    let numberOfTickets;
    if (selectedItems && selectedItems[item.itemId]) {
      numberOfTickets = selectedItems[item.itemId].numberOfTickets;
    } else {
      numberOfTickets = "";
    }

    if (!availableQuantity) {
      availableQuantity =
        maxTicketsInPool >= 0
          ? maxTicketsInPool + numberOfTickets
          : item.availableTickets || unlimitedQuantity;
    }

    if (availableQuantity > 0 || unlimited) {
      return (
        <Item
          item={item}
          price={price}
          numberOfItems={numberOfTickets}
          displayLowItems={displayLowTickets}
          displayError={displayError}
          handleItemSelection={this.handleTicketSelection}
          isRestricted={displayRestrictionAsterisk}
          eventIsCanceled={eventIsCanceled}
          isVariation={isVariation}
          availableQuantity={parseInt(availableQuantity, 10)}
          itemName={name}
        />
      );
    }
    return this.renderSoldOutItem(name);
  }

  handleTicketSelection = e => {
    const { event, selectedTickets, waitlist, itemType } = this.props;
    const selectedTicketsCopy = { ...selectedTickets };
    const maxTickets = parseInt(e.target.max, 10);
    const hasExistingTickets =
      Object.getOwnPropertyNames(selectedTickets).length > 0;
    const timeout = 3500;
    let numberOfTickets = parseInt(e.target.value, 10) || 0;
    //if user attempts to select more tickets than available,
    //briefly display error &
    //reset input value to max # of tickets available
    if (numberOfTickets > maxTickets) {
      this.setState({ displayError: true });
      setTimeout(() => {
        this.setState({ displayError: false });
      }, timeout);
      numberOfTickets = maxTickets;
    }
    //if redux store has no selectedTickets yet, we need to set up event data
    const eventData = {
      event: event.slug,
      name: event.name,
      date: event.dateAndTime,
      ticketBlocks: {},
      extras: {}
    };
    //use existing selectedTickets data or new event data from above
    const data = hasExistingTickets ? selectedTicketsCopy : eventData;
    //add new tickets to data.ticketBlocks, using ticketblock id as key
    const decimals = 2;
    if (numberOfTickets > 0) {
      data[itemType][e.target.dataset.id] = {
        name: e.target.dataset.name,
        price: e.target.dataset.price,
        numberOfTickets: numberOfTickets,
        total: (e.target.dataset.price * numberOfTickets).toFixed(decimals)
      };
      if (itemType === "ticketBlocks") {
        data[itemType][e.target.dataset.id].poolKey = e.target.dataset.poolkey;
        data[itemType][e.target.dataset.id].waitlist = waitlist;
      }
    } else {
      //when # of tickets changes to 0, remove that block of tickets from ticketBlocks data
      delete data[itemType][e.target.dataset.id];
    }
    data.claimRestrictionArray = event.perClaimRestrictions;
    data.shippingAddressRequired = event.shippingAddressRequired;
    //update redux store's selectedTickets
    this.props.selectTickets(data);
  };

  render() {
    const { item } = this.props;
    return this.renderItem(item);
  }
}

ItemContainer.propTypes = {
  item: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired,
  selectedTickets: PropTypes.object.isRequired,
  selectTickets: PropTypes.func.isRequired,
  isVariation: PropTypes.bool,
  waitlist: PropTypes.bool,
  maxTicketsInPool: PropTypes.number,
  percentAvailable: PropTypes.number,
  unlimited: PropTypes.bool
};

export default ItemContainer;
