/* eslint-disable no-undef */
export const theme = {
  // NAME needs to match the theme folder name EXACTLY
  NAME: "littleherds",
  TENANT: "ihsk9e",
  // External header links
  LINKS: [
    {
      url: "https://www.littleherds.org/",
      label: "Home"
    }
  ],
  // Social media links for footer
  SOCIAL:
    "https://www.instagram.com/little.herds https://www.facebook.com/LittleHerds https://twitter.com/littleherds",
  // This might seem a bit backwards, but HEADING_1 is the larger text on the
  // second line of the landing page, while HEADING_2 is the first line but it's
  // smaller in size
  HEADING_1: "Little Herds!",
  HEADING_2: "Welcome to",
  // Below are optional
  // Check client's questionnaire response for HIDESEARCHBAR
  // DEMO should only be true for sites we use as demos
  HIDESEARCHBAR: true,
  DEMO: false
};
