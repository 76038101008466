import React from "react";

class Ticket extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 1792 1792"
        className="secondary-fill object-cover w-auto m-auto h-full p-2"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1024 452l316 316-572 572-316-316zm-211 979l618-618q19-19 19-45t-19-45l-362-362q-18-18-45-18t-45 18l-618 618q-19 19-19 45t19 45l362 362q18 18 45 18t45-18zm889-637l-907 908q-37 37-90.5 37t-90.5-37l-126-126q56-56 56-136t-56-136-136-56-136 56l-125-126q-37-37-37-90.5t37-90.5l907-906q37-37 90.5-37t90.5 37l125 125q-56 56-56 136t56 136 136 56 136-56l126 125q37 37 37 90.5t-37 90.5z" />
      </svg>
    );
  }
}

export default Ticket;
