import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "./DefaultLayout";

class MainContainer extends React.Component {
  render() {
    return (
      <DefaultLayout isAdmin={this.props.isAdmin}>
        <main
          className={`p-3 flex-grow flex bg-gray-100 text-gray-800 ${this.props.extraMainClass}`}
        >
          <div
            className={`print:w-full container mx-auto flex flex-col ${this.props.extraClass}`}
          >
            {this.props.children}
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
  extraMainClass: PropTypes.string,
  extraClass: PropTypes.string,
  isAdmin: PropTypes.bool
};

export default MainContainer;
