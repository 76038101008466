import React from "react";
import PropTypes from "prop-types";
import InputLabel from "../../core/InputLabel"

import { TimePicker } from 'antd';
import 'antd/dist/antd.css';
import moment from "moment"

class AdminTimeInput extends React.Component {
  render() {
    const {
      allowClear,
      containerClass,
      field,
      label,
      onChange,
      value,
      width,
      required,
      id,
      helpText
    } = this.props;

    const fieldId = id ? `${id}-${field}` : field;

    const arbitraryDate = moment(0).format("YYYY-MM-DD");
    const time = moment(`${arbitraryDate} ${value}`);

    return (
      <div className={`w-full${width ? ` sm:w-${width}` : ""} p-2 ${containerClass}`}>
        <InputLabel
          field={fieldId}
          label={label}
          required={required}
          helpText={helpText}
        />
        <TimePicker
          allowClear={allowClear}
          showNow={false}
          format={"h:mm a"}
          value={value ? moment(time) :
            field === "start_time" ? moment().add(1, "hour").startOf("hour") : ""}
          onChange={time => {
            onChange({
              target: {
                name: field,
                value: time ? time.format("HH:mm:ss") : ""
              }
            })
          }}
          className="p-2 w-full"
          style={{ borderColor: "#e2e8f0" }}
          use12Hours
          minuteStep={5}
        />
      </div>
    );
  }
}

AdminTimeInput.propTypes = {
  allowClear: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  containerClass: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string,
  helpText: PropTypes.string,
  width: PropTypes.string
};

export default AdminTimeInput;
