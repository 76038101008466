const REQUEST = "marketplace/allOrders/REQUEST";
const SUCCESS = "marketplace/allOrders/SUCCESS";
const FAILURE = "marketplace/allOrders/FAILURE";

const initialState = { data: [], more: undefined };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.claims,
        more: action.payload.hasMore
      };
    case FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload.message
      };
    default:
      return state;
  }
}

export const getAllOrders = event => (dispatch, getState) => {
  dispatch({ type: REQUEST });
  return getState().apis.marketplace
    .get("/claims", {
      params: {
        eventSlug: event
      }
    })
    .then(results => dispatch({ type: SUCCESS, payload: results.data }))
    .catch(error => dispatch({ type: FAILURE, payload: error, error: true }));
};
