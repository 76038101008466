import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Elements, StripeProvider } from "react-stripe-elements";
import { formatDate } from "../../utils";

import MainContainer from "../core/MainContainer";
import Loader from "../core/Loader";
import EmptyPage from "../core/EmptyPage";
import Payment from "./Payment";
import Waitlist from "./Waitlist";

import { getStripeKey } from "../../ducks/stripeKey";

class Checkout extends React.Component {
  componentDidMount() {
    const { selectedTickets } = this.props;
    let waitlist = false;

    if (selectedTickets.ticketBlocks) {
      waitlist = Object.keys(selectedTickets.ticketBlocks)[0] === "waitlist";
    }

    if (!waitlist && this.props.hasTickets) {
      this.props.getStripeKey();
    }
  }

  componentWillUnmount() {
    const clearStripeKey = true;
    this.props.getStripeKey(clearStripeKey);
  }

  renderStripeForm(selectedTickets, eventDate) {
    const { stripeIntent, stripeKey } = this.props;
    const { forStripeAccount } = stripeIntent;
    return (
      <div className="mx-3 flex flex-wrap flex-grow">
        <StripeProvider apiKey={stripeKey} stripeAccount={forStripeAccount}>
          <Elements>
            <Payment
              heading={`${selectedTickets.name} on ${eventDate.monthLong} ${eventDate.day}`}
            />
          </Elements>
        </StripeProvider>
      </div>
    );
  }

  renderWaitlistForm(selectedTickets, eventDate) {
    return (
      <div className="mx-3 flex flex-wrap flex-grow">
        <Waitlist
          heading={`${selectedTickets.name} on ${eventDate.monthLong} ${eventDate.day}`}
          selectedTickets={selectedTickets}
        />
      </div>
    );
  }

  renderSuccess() {
    const orderId = this.props.claim.payload.data.claimId.replace("clm_", "");
    return <Redirect push to={`/orders/${orderId}`} />;
  }

  renderEmptyCart() {
    return (
      <EmptyPage
        heading="Your shopping cart is empty!"
        buttonLabel="Shop for Event Tickets"
      />
    );
  }

  renderError() {
    return (
      <EmptyPage
        heading="Something went wrong!"
        buttonLabel="Go Back"
        onClick={this.props.history.goBack}
      />
    );
  }

  renderContent() {
    const { selectedTickets, success, isFetching } = this.props;
    const eventDate = formatDate(selectedTickets.date);
    let isWaitlist;
    if (selectedTickets.ticketBlocks) {
      isWaitlist = Object.keys(selectedTickets.ticketBlocks)[0] === "waitlist";
    }
    const stripeReady = this.props.stripeKey;
    const ticketsInCart =
      Object.getOwnPropertyNames(selectedTickets).length > 0;

    if (isFetching) {
      return <Loader />;
    }

    if (!isFetching && !stripeReady && !isWaitlist && ticketsInCart) {
      return this.renderError();
    }

    if (stripeReady && ticketsInCart) {
      return this.renderStripeForm(selectedTickets, eventDate);
    }
    if (isWaitlist) {
      return this.renderWaitlistForm(selectedTickets, eventDate);
    }
    if (success) {
      return this.renderSuccess();
    }

    return this.renderEmptyCart();
  }

  render() {
    return (
      <MainContainer extraClass="py-3">{this.renderContent()}</MainContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedTickets: state.selectedTickets,
    stripeKey: state.stripeKey.key,
    stripeIntent: state.stripeIntent.stripe,
    isFetching: state.stripeKey.isFetching,
    success: state.orderSuccess,
    claim: state.claim
  };
};

export default connect(mapStateToProps, {
  getStripeKey
})(Checkout);
