/* eslint-disable no-undef */
export const theme = {
  NAME: "backstage_capital",
  TENANT: "pyxu5a",
  LINKS: [
    {
      url: "https://backstagecapital.com/headliners/",
      label: "Headliners"
    },
    {
      url: "https://backstagecapital.com/partners/",
      label: "Partners"
    }
  ],
  SOCIAL:
    "https://twitter.com/Backstage_Cap https://medium.com/greenroom https://www.linkedin.com/company/backstage-capital/ https://www.instagram.com/backstagecapital/ https://www.facebook.com/backstagecap/",
  HEADING_1: "Backstage Capital Tour 2020",
  HEADING_2: ""
};
