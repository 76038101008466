const REQUEST = "marketplace/ticketConfirmation/REQUEST";
const SUCCESS = "marketplace/ticketConfirmation/SUCCESS";
const FAILURE = "marketplace/ticketConfirmation/FAILURE";

const initialState = { ticketInfo: {} };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case SUCCESS:
      return { ...state, isFetching: false, ticketInfo: action.payload };
    case FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload.message
      };
    default:
      return state;
  }
}

export const confirmTicket =
    (claimId, index, signature) => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .get(`/claims/${claimId}/tickets/${index}`, {
      params: {
        s: signature
      }
    })
    .then(results => dispatch({ type: SUCCESS, payload: results.data }))
    .catch(error => dispatch({ type: FAILURE, payload: error, error: true }));
};
