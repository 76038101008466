// DUCKS: https://github.com/erikras/ducks-modular-redux
import { combineReducers } from "redux";
import event from "./event";
import events from "./events";
import orders from "./orders";
import order from "./order";
import allOrders from "./allOrders";
import eventFormErrors from "./eventForm";
import copiedEvent from "./setCopyEventId";
import images from "./images";
import highlights from "./highlightedEvents";
import coupons from "./coupons";

const reducers = combineReducers({
  event,
  events,
  coupons,
  order,
  orders,
  allOrders,
  eventFormErrors,
  copiedEvent,
  images,
  highlights
});

export default reducers;
