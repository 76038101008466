import React from "react";
import PropTypes from "prop-types";

import theme from "../../theme";
import Avatar from "./Avatar";
import NavMobile from "./NavMobile";
import Login from "./Login";

const INITIAL_STATE = {
  isOpen: false
};

const adminLinks = [
  {
    url: "/admin",
    label: "Events"
  }
];
/* eslint-disable no-undef */
const app_links = theme.LINKS;

class Nav extends React.Component {
  state = INITIAL_STATE;

  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  toggleDropdown() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  renderLinks() {
    if (app_links) {
      return app_links.map(({ url, label }) => {
        return (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            key={url}
            className="p-3 w-1/2 md:w-auto text-center font-bold tracking-wide text-lg uppercase header-link"
          >
            {label}
          </a>
        );
      });
    }
    return null;
  }

  renderAdminLinks() {
    return adminLinks.map(({ url, label }) => {
      return (
        <li key={url}>
          <a
            href={url}
            className="p-3 w-1/2 md:w-auto text-center font-bold tracking-wide text-lg uppercase header-link"
          >
            {label}
          </a>
        </li>
      );
    });
  }

  renderDropdown() {
    if (this.state.isOpen) {
      const buttonClasses = "w-full py-3 px-6 text-left";
      return (
        <ul
          className="absolute mt-6 right-0 border bg-white min-w-48 z-10"
          role="region"
        >
          <li>
            <Login buttonClasses={buttonClasses} />
          </li>
        </ul>
      );
    }
    return null;
  }

  renderNav() {
    return (
      <nav className="p-3 hidden sm:block print:hidden">
        <ul className="flex items-center justify-center flex-wrap">
          {this.props.isAdmin ? this.renderAdminLinks() : this.renderLinks()}
          <div ref={this.setWrapperRef} className="relative">
            {this.props.user && (
              <button
                aria-label="User Account"
                onClick={() => this.toggleDropdown()}
                className="header-link"
              >
                <Avatar />
              </button>
            )}
            {this.renderDropdown()}
          </div>
        </ul>
      </nav>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderNav()}
        <NavMobile
          isAdmin={this.props.isAdmin}
          adminLinks={adminLinks}
          appLinks={app_links}
        />
      </React.Fragment>
    );
  }
}

Nav.propTypes = {
  isAdmin: PropTypes.bool,
  user: PropTypes.object
};

export default Nav;
