import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Input from "../../core/Input";
import { validateEventForm } from "../../../ducks/admin/eventForm";

export class AdminInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputId = this.props.field + (this.props.id || "");
  }

  render() {
    const {
      fieldName,
      type,
      field,
      onChange,
      width,
      value,
      required,
      id,
      displayError,
      helpText,
      disabled,
      validateField,
      placeholder,
      maxLength,
      containerClass
    } = this.props;
    let fieldIsInvalid = false;
    let errorMessage;

    if (required) {
      fieldIsInvalid = value.length <= 0;
      errorMessage = "This field is required.";
      this.props.validateEventForm(fieldIsInvalid, this.inputId);
    }

    // only validateField if it has a value
    if (value && validateField) {
      const field = validateField(value, maxLength) || {};
      fieldIsInvalid = field.error;
      errorMessage = field.errorMessage;
      this.props.validateEventForm(fieldIsInvalid, this.inputId);
    }

    return (
      <Input
        label={fieldName}
        type={type}
        field={field}
        onChange={onChange}
        containerClass={`w-full${width ? ` sm:w-${width}` : ""} p-2 ${containerClass}`}
        value={value}
        required={required}
        id={id}
        error={fieldIsInvalid && displayError}
        helpText={helpText}
        disabled={disabled}
        errorMessage={errorMessage}
        placeholder={placeholder}
      />
    );
  }
}

AdminInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  id: PropTypes.string,
  displayError: PropTypes.bool,
  validateEventForm: PropTypes.func.isRequired,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  validateField: PropTypes.func,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  containerClass: PropTypes.string
};

export default connect(null, { validateEventForm })(AdminInput);
