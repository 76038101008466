import React from "react";
import { formatDate, createMarkup } from "../../utils";
import moment from "moment";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTv, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

class Summary extends React.Component {
  renderEventTitle(status, name) {
    const isCanceled = status === "Canceled";
    return (
      <React.Fragment>
        {isCanceled && <span className="uppercase font-bold">Canceled </span>}
        <span className={`${isCanceled && "line-through"}`}>{name}</span>
      </React.Fragment>
    );
  }

  render() {
    const { event, status } = this.props;
    const eventDate = formatDate(event.dateAndTime);

    let eventEnd;
    if (event.endDateAndTime) {
      const endDate = formatDate(event.endDateAndTime);

      const start = moment(event.dateAndTime);
      const end = moment(event.endDateAndTime);
      if (end.isAfter(start, "day")) {
        eventEnd = ` - ${endDate.weekday}, ${endDate.monthLong} ${endDate.day}, ${endDate.time}`;
      } else if (end.isAfter(start, "minute")) {
        eventEnd = ` - ${endDate.time}`;
      }
    }

    const { name, address } = event.venue || {};
    const location = address
      ? `${name ? `${name} at ` : ""}${address}`
      : "Online Event";

    const photo = event.photos[0] || {};

    return (
      <div className="mx-3">
        <Helmet>
          <title>{event.name}</title>
          <meta name="description" content={event.description} />
          <meta property="og:image" content={photo.url} />
          <meta property="og:title" content="Title of the article" />
          <meta property="og:description" content={event.description} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={event.name} />
          <meta name="twitter:description" content={event.description} />
          <meta name="twitter:image" content={photo.url} />
        </Helmet>
        <h1 className="text-2xl py-3 mb-3 border-b-2">
          {this.renderEventTitle(status, event.name)}
        </h1>
        {event.photos && photo && (
          <img
            className="py-3 mx-auto lg:hidden"
            src={photo.url}
            alt={photo.alt}
          />
        )}
        <h2 className="py-1 font-bold">
          {`${eventDate.weekday}, ${eventDate.monthLong} ${eventDate.day}, ${eventDate.time}`}
          {eventEnd}
        </h2>
        <h2>{location}</h2>
        {(event.mediums || []).includes("ONLINE") && <FontAwesomeIcon icon={faTv} size="sm" className="mr-2" />}
        {(event.mediums || []).includes("IN_PERSON") && <FontAwesomeIcon icon={faMapMarkerAlt} size="sm" />}
        <div
          className="my-3"
          dangerouslySetInnerHTML={createMarkup(event.description)}
        />
      </div>
    );
  }
}

Summary.propTypes = {
  event: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired
};

export default Summary;
