import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

class ProgressBar extends React.Component {
  renderSteps() {
    const { numberOfSteps, currentStepNumber } = this.props;
    const steps = [];

    for (let number = 1; number <= numberOfSteps; number++) {
      const current = number === currentStepNumber;
      const completed = number < currentStepNumber;
      const background = current
        ? "bg-white border"
        : completed
        ? "secondary-bg"
        : "bg-gray-300 text-gray-500";
      const lineColor = current || completed ? "secondary-bg" : "bg-gray-300";

      steps.push(
        <React.Fragment key={number}>
          <span className={`${lineColor} h-1 flex-grow rounded`}></span>
          <span
            className={`${background} border w-6 h-6 text-center rounded-full flex justify-center items-center mx-1 text-sm font-bold`}
          >
            {completed ? <FontAwesomeIcon icon={faCheck} /> : number}
          </span>
        </React.Fragment>
      );
    }

    return steps;
  }

  render() {
    return this.renderSteps();
  }
}

ProgressBar.propTypes = {
  numberOfSteps: PropTypes.number.isRequired,
  currentStepNumber: PropTypes.number.isRequired
};

export default ProgressBar;
