import React from "react";
import PropTypes from "prop-types";

import Button from "../core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";

class Buttons extends React.Component {
  onClear() {
    this.props.selectTickets({});
  }

  submitLabel() {
    if (this.props.charging) {
      return (
        <span>
          <FontAwesomeIcon
            icon={faSpinner}
            className="fa-spin"
          ></FontAwesomeIcon>{" "}
          Processing
        </span>
      );
    }
    return <span>Complete Purchase</span>;
  }

  renderStripeLogo() {
    const { waitlist } = this.props;
    if (!waitlist) {
      return (
        <span className="w-full p-3">
          {/* eslint-disable global-require */}
          <img
            className="m-auto bg-white rounded-lg"
            src={require("../../images/powered_by_stripe@2x.png")}
            alt="powered by stripe"
          />
        </span>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="w-full flex flex-col md:flex-row md:justify-center md:items-start flex-wrap">
        <Button
          type="submit"
          label={this.submitLabel()}
          extraClass="w-full md:w-auto block mx-0 md:mx-3"
          disabled={this.props.charging}
        />
        <Button
          onClick={() => this.onClear()}
          label="Cancel Purchase"
          extraClass="w-full md:w-auto block mx-0 md:mx-3"
          disabled={this.props.charging}
          buttonStyle="secondary-outline"
        />
        {this.renderStripeLogo()}
      </div>
    );
  }
}

Buttons.propTypes = {
  charging: PropTypes.bool,
  selectTickets: PropTypes.func,
  waitlist: PropTypes.bool
};

export default Buttons;
