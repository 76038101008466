import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faCaretLeft
} from "@fortawesome/free-solid-svg-icons";

const INITIAL_STATE = {
  displayHelp: false
};

class HelpBubble extends React.Component {
  state = INITIAL_STATE;
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({ displayHelp: false });
    }
  };

  toggleHelp = () => {
    this.setState(prevState => ({
      displayHelp: !prevState.displayHelp
    }));
  };

  render() {
    const { displayHelp } = this.state;
    const { helpText } = this.props;

    return (
      <div className="relative flex-grow">
        <span
          ref={this.wrapperRef}
          onClick={this.toggleHelp}
          className="px-2 mr-auto cursor-pointer"
          aria-label="show help text"
        >
          <FontAwesomeIcon className="text-gray-400" icon={faQuestionCircle} />
        </span>
        {displayHelp && (
          <React.Fragment>
            <FontAwesomeIcon icon={faCaretLeft} className="text-gray-400" />
            <span className="absolute text-sm italic bg-gray-400 px-3 py-1 rounded min-w-40 z-10">
              {helpText}
            </span>
          </React.Fragment>
        )}
      </div>
    );
  }
}

HelpBubble.propTypes = {
  helpText: PropTypes.string.isRequired
};

export default HelpBubble;
