import React from "react";
import PropTypes from "prop-types";

import ConfirmationModal from "../../core/ConfirmationModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const INITIAL_STATE = {
  displayModal: false
};

class EventBlock extends React.Component {
  state = INITIAL_STATE;

  handleBlockRemoval = e => {
    e.preventDefault();
    this.props.handleFormBlockRemoval();
    this.setState({ displayModal: false });
  };

  showModal = e => {
    e.preventDefault();
    this.setState({ displayModal: true });
  };

  hideModal = e => {
    e.preventDefault();
    this.setState({ displayModal: false });
  };

  render() {
    const { formBlock, numberOfFormBlocks, name, emptyBlock } = this.props;
    const { displayModal } = this.state;

    return (
      <div className="p-3 flex flex-wrap justify-center border-b-2">
        {displayModal && !emptyBlock && (
          <ConfirmationModal
            heading={`You are about to delete ${name}.`}
            body="Are you sure? This action cannot be undone."
            actionText="Yes, DELETE"
            cancel={this.hideModal}
            action={this.handleBlockRemoval}
          />
        )}
        {this.props.children}
        {numberOfFormBlocks > 1 && (
          <button
            className="p-2 text-lg text-gray-400 w-full text-center cursor-pointer"
            onClick={emptyBlock ? this.handleBlockRemoval : this.showModal}
          >
            <FontAwesomeIcon icon={faTrashAlt} /> Remove {formBlock}
          </button>
        )}
      </div>
    );
  }
}

EventBlock.propTypes = {
  handleFormBlockRemoval: PropTypes.func.isRequired,
  formBlock: PropTypes.string.isRequired,
  numberOfFormBlocks: PropTypes.number.isRequired,
  children: PropTypes.array.isRequired,
  name: PropTypes.string,
  emptyBlock: PropTypes.bool
};

export default EventBlock;
