/* eslint-disable no-undef */
export const theme = {
  // NAME needs to match the theme folder name EXACTLY
  NAME: "saba",
  TENANT: "itzeb4",
  // External header links
  LINKS: [
    {
      url: "https://sabakarim.com/",
      label: "go to sabakarim.com"
    }
  ],
  // Social media links for footer
  SOCIAL: "https://twitter.com/sabakarimm",
  // This might seem a bit backwards, but HEADING_1 is the larger text on the
  // second line of the landing page, while HEADING_2 is the first line but it's
  // smaller in size
  HEADING_1: "Saba's Upcoming Events",
  HEADING_2: "",
  // Below are optional
  // Check client's questionnaire response for HIDESEARCHBAR
  // DEMO should only be true for sites we use as demos
  HIDESEARCHBAR: true
};
