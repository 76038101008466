import React from "react";
import Button from "../core/Button";
import EventFlag from "../core/EventFlag";
import PropTypes from "prop-types";

class TableRow extends React.Component {
  render() {
    const { dateAndTime, disabled, path, children } = this.props;
    const month = dateAndTime ? dateAndTime.month : "";
    const day = dateAndTime ? dateAndTime.day : "";
    const label = disabled ? "" : "Buy";

    return (
      <div className="py-3 w-full flex justify-between items-center flex-wrap">
        <div className="flex items-center flex-1">
          <EventFlag disabled={disabled} month={month} day={day} />
          <div className="mr-auto p-3 flex-1">{children}</div>
        </div>

        <div className="w-full sm:w-1/4 flex">
          <Button
            isLink={disabled ? false : true}
            label={label}
            path={path}
            extraClass="flex-1 bg-gray-300"
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

TableRow.propTypes = {
  disabled: PropTypes.bool,
  dateAndTime: PropTypes.object,
  path: PropTypes.string,
  children: PropTypes.node
};

export default TableRow;
