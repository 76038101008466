const REQUEST = "marketplace/stripeIntent/REQUEST";
const RESPONSE = "marketplace/stripeIntent/RESPONSE";

const initialState = { stripe: {} };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case RESPONSE:
      return { ...state, isFetching: false, stripe: action.payload };
    default:
      return state;
  }
}

export const createPaymentIntent = items => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .post("/orders/intents", items)
    .then(results => dispatch({ type: RESPONSE, payload: results.data }))
    .catch(error => {
      const errorObject = JSON.parse(error.request.response);
      dispatch({
        type: RESPONSE,
        payload: { error: errorObject.code, errorMessage: errorObject.message }
      });
    });
};
