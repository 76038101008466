import React from "react";
import PropTypes from "prop-types";

import Summary from "./Summary";
import Tickets from "./Tickets";
import Order from "./Order";
import Loader from "../core/Loader";
import EmptyPage from "../core/EmptyPage";
import Button from "../core/Button";
import Extras from "./Extras";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import {
  getMaxTicketRestrictions,
  eventStatus,
  getNumberOfActiveEventItems
} from "../../utils";

class Content extends React.Component {
  renderTicketPools(eventPools) {
    const { event, selectedTickets, selectTickets } = this.props;
    const { ticketBlocks, ticketPools } = event;
    // filter out unlimited ticketBlocks & compare number of pools vs blocks
    // to determine event organization, only display pool title if organized
    // by pools
    const activeTicketBlocks = ticketBlocks.filter(
      item => item.status === "active"
    );
    const activeTicketBlocksWithPools = activeTicketBlocks.filter(
      item => item.poolKey !== undefined
    );

    const displayPoolTitle =
      Object.keys(ticketPools).length !== activeTicketBlocksWithPools.length &&
      Object.keys(ticketPools).length > 1 &&
      activeTicketBlocksWithPools.length > 1;

    return eventPools.map((pool, index) => {
      return (
        <Tickets
          key={index}
          event={event}
          selectedTickets={selectedTickets}
          selectTickets={selectTickets}
          eventPools={eventPools}
          eventPool={pool}
          displayPoolTitle={displayPoolTitle}
        />
      );
    });
  }

  renderWaitlist(soldOut) {
    const { event, selectedTickets, selectTickets } = this.props;
    if (soldOut) {
      return (
        <Tickets
          event={event}
          selectedTickets={selectedTickets}
          selectTickets={selectTickets}
          waitlist={true}
        />
      );
    }
    return null;
  }

  renderExtras() {
    const { event, selectedTickets, selectTickets } = this.props;
    const { extras } = event;
    if (extras.filter(extra => extra.status === "active").length > 0) {
      return (
        <Extras
          event={event}
          selectedTickets={selectedTickets}
          selectTickets={selectTickets}
          extras={extras}
        />
      );
    }
    return null;
  }

  renderMaxTicketsWarning(event) {
    const maxTicketRestrictions = getMaxTicketRestrictions(
      event.perClaimRestrictions
    );

    if (maxTicketRestrictions.length) {
      const numberOfMaxTicketRestrictions = maxTicketRestrictions.length;
      const maxTickets = maxTicketRestrictions[0].maxCount;
      const maxTicketsPlural = maxTickets > 1 ? "s" : "";
      const numberOfItems = getNumberOfActiveEventItems(event);
      const numberOfRestrictedSections =
        maxTicketRestrictions[0].includedBlocks.length;
      const additionalMessage = numberOfItems !==
        numberOfRestrictedSections && (
        <React.Fragment>
          {" "}
          for items marked by{" "}
          <FontAwesomeIcon
            icon={faAsterisk}
            className="fa-xs secondary-color align-baseline"
          />
        </React.Fragment>
      );
      // if multiple max ticket restrictions, avoid confusing UI and use Order
      // error messages to communicate restrictions after ticket selection
      if (numberOfMaxTicketRestrictions === 1) {
        return (
          <span className="italic">
            Maximum {maxTickets} total ticket{maxTicketsPlural} per order
            {additionalMessage}.
          </span>
        );
      }
    }

    return null;
  }

  renderPhoto(photos) {
    if (photos && photos[0]) {
      return (
        <img
          className="p-3 mx-auto hidden lg:block"
          src={photos[0].url}
          alt={photos[0].alt}
        />
      );
    }
    return null;
  }

  renderBackButton() {
    return (
      <div className="text-center lg:text-left">
        <Button
          label={
            <React.Fragment>
              <FontAwesomeIcon icon={faArrowLeft} className="fa-sm" /> Back to
              Events
            </React.Fragment>
          }
          isLink={true}
          path={"/events"}
          buttonStyle="secondary"
        />
      </div>
    );
  }

  render() {
    const {
      event,
      isFetching,
      selectedTickets,
      soldOut,
      ticketCount,
      restrictedBlocks
    } = this.props;
    const { availability, visibility } = event;
    const eventExists = Object.getOwnPropertyNames(event).length > 0;
    const status = eventStatus(availability, visibility);

    if (isFetching) {
      return <Loader />;
    } else if (eventExists) {
      const eventPools = [];
      // get array of only the pool keys that are used
      // includes a pool key of undefined for unlimited pool
      event.ticketBlocks.forEach(section => {
        if (!eventPools.includes(section.poolKey)) {
          eventPools.push(section.poolKey);
        }
      });

      return (
        <div className="event-grid mb-40">
          <div>
            <Summary event={event} status={status} />
            <div className="mx-3 mt-16">
              <span className="text-2xl mr-2">Tickets</span>
              {this.renderMaxTicketsWarning(event)}
              {this.renderTicketPools(eventPools)}
              {this.renderWaitlist(soldOut)}
              {this.renderExtras()}
            </div>
          </div>
          <div className="relative">
            {this.renderPhoto(event.photos)}
            <Order
              selectedTickets={selectedTickets}
              event={event}
              ticketCount={ticketCount}
              restrictedBlocks={restrictedBlocks}
            />
          </div>
          {this.renderBackButton()}
        </div>
      );
    }
    return (
      <EmptyPage
        heading="This event doesn't exist!"
        buttonLabel="Shop for Event Tickets"
      />
    );
  }
}

Content.propTypes = {
  event: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  selectedTickets: PropTypes.object.isRequired,
  selectTickets: PropTypes.func.isRequired,
  soldOut: PropTypes.bool.isRequired,
  ticketCount: PropTypes.object,
  restrictedBlocks: PropTypes.array
};

export default Content;
