import React from "react";
import PropTypes from "prop-types";

class EventActionMenu extends React.Component {
  render() {
    const { display, position } = this.props;

    if (display) {
      return (
        <ul
          className={`absolute mt-3 ${position}-0 border min-w-32 bg-white z-10 font-normal shadow-md text-base`}
          role="region"
          data-testid="event-actions"
        >
          {this.props.children}
        </ul>
      );
    }
    return null;
  }
}

EventActionMenu.propTypes = {
  display: PropTypes.bool.isRequired,
  position: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};

export default EventActionMenu;
