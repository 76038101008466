const REQUEST = "marketplace/event/REQUEST";
const SUCCESS = "marketplace/event/SUCCESS";
const FAILURE = "marketplace/event/FAILURE";

const initialState = { data: {} };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case SUCCESS:
      return { ...state, isFetching: false, data: action.payload };
    case FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload.message
      };
    default:
      return state;
  }
}

export const getEvent = event => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .get(`/events/${event}`)
    .then(results =>
      dispatch({
        type: SUCCESS,
        payload: results.data
      })
    )
    .catch(error => dispatch({ type: FAILURE, payload: error, error: true }));
};
