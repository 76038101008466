import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const INITIAL_STATE = {
  query: undefined
};

export class SearchBar extends React.Component {
  state = INITIAL_STATE;

  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ query: e.target.value });
  };

  handleSubmit(e) {
    e.preventDefault();
    const { pathname } = this.props;
    const search = queryString.parse(this.props.location.search);
    const params = {
      query: this.state.query ? this.state.query : null
    };
    if (search.event) {
      params.event = search.event;
    }
    this.props.history.push({
      pathname: pathname,
      search: queryString.stringify(params)
    });
  }

  render() {
    return (
      <form
        className=" border w-full md:w-3/5 m-3 flex justify-between"
        onSubmit={this.handleSubmit}
      >
        <input
          type="text"
          placeholder={this.props.placeholder}
          className="p-4 w-full"
          onChange={this.handleQueryChange}
        />
        <button type="submit" className="p-4 bg-white">
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </button>
      </form>
    );
  }
}

SearchBar.propTypes = {
  placeholder: PropTypes.string.isRequired,
  history: PropTypes.object,
  pathname: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(SearchBar);
