import React from "react";

class Loader extends React.Component {
  render() {
    return (
      <div className="h-full flex justify-center items-center primary-color">
        <svg
          className="lds-spinner fill-current"
          width="200px"
          height="200px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <g transform="rotate(0 50 50)">
            <rect
              x="47.5"
              y="22.5"
              rx="11.875"
              ry="5.625"
              width="5"
              height="15"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1.5s"
                begin="-1.375s"
                repeatCount="indefinite"
              ></animate>
            </rect>
          </g>
          <g transform="rotate(30 50 50)">
            <rect
              x="47.5"
              y="22.5"
              rx="11.875"
              ry="5.625"
              width="5"
              height="15"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1.5s"
                begin="-1.25s"
                repeatCount="indefinite"
              ></animate>
            </rect>
          </g>
          <g transform="rotate(60 50 50)">
            <rect
              x="47.5"
              y="22.5"
              rx="11.875"
              ry="5.625"
              width="5"
              height="15"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1.5s"
                begin="-1.125s"
                repeatCount="indefinite"
              ></animate>
            </rect>
          </g>
          <g transform="rotate(90 50 50)">
            <rect
              x="47.5"
              y="22.5"
              rx="11.875"
              ry="5.625"
              width="5"
              height="15"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1.5s"
                begin="-1s"
                repeatCount="indefinite"
              ></animate>
            </rect>
          </g>
          <g transform="rotate(120 50 50)">
            <rect
              x="47.5"
              y="22.5"
              rx="11.875"
              ry="5.625"
              width="5"
              height="15"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1.5s"
                begin="-0.875s"
                repeatCount="indefinite"
              ></animate>
            </rect>
          </g>
          <g transform="rotate(150 50 50)">
            <rect
              x="47.5"
              y="22.5"
              rx="11.875"
              ry="5.625"
              width="5"
              height="15"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1.5s"
                begin="-0.75s"
                repeatCount="indefinite"
              ></animate>
            </rect>
          </g>
          <g transform="rotate(180 50 50)">
            <rect
              x="47.5"
              y="22.5"
              rx="11.875"
              ry="5.625"
              width="5"
              height="15"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1.5s"
                begin="-0.625s"
                repeatCount="indefinite"
              ></animate>
            </rect>
          </g>
          <g transform="rotate(210 50 50)">
            <rect
              x="47.5"
              y="22.5"
              rx="11.875"
              ry="5.625"
              width="5"
              height="15"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1.5s"
                begin="-0.5s"
                repeatCount="indefinite"
              ></animate>
            </rect>
          </g>
          <g transform="rotate(240 50 50)">
            <rect
              x="47.5"
              y="22.5"
              rx="11.875"
              ry="5.625"
              width="5"
              height="15"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1.5s"
                begin="-0.375s"
                repeatCount="indefinite"
              ></animate>
            </rect>
          </g>
          <g transform="rotate(270 50 50)">
            <rect
              x="47.5"
              y="22.5"
              rx="11.875"
              ry="5.625"
              width="5"
              height="15"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1.5s"
                begin="-0.25s"
                repeatCount="indefinite"
              ></animate>
            </rect>
          </g>
          <g transform="rotate(300 50 50)">
            <rect
              x="47.5"
              y="22.5"
              rx="11.875"
              ry="5.625"
              width="5"
              height="15"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1.5s"
                begin="-0.125s"
                repeatCount="indefinite"
              ></animate>
            </rect>
          </g>
          <g transform="rotate(330 50 50)">
            <rect
              x="47.5"
              y="22.5"
              rx="11.875"
              ry="5.625"
              width="5"
              height="15"
            >
              <animate
                attributeName="opacity"
                values="1;0"
                keyTimes="0;1"
                dur="1.5s"
                begin="0s"
                repeatCount="indefinite"
              ></animate>
            </rect>
          </g>
        </svg>
      </div>
    );
  }
}

export default Loader;
