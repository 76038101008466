/* eslint-disable no-unused-expressions */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import queryString from "query-string";
import { withRouter } from "react-router";

import { getEvents } from "../../ducks/events";
import { getIndex } from "../../utils";
import { getOrders } from "../../ducks/admin/orders";

import MainContainer from "../core/MainContainer";
import TablePagination from "./TablePagination";

const INITIAL_STATE = {
  currentQuery: "",
  currentIndex: 0,
  currentPage: "1",
  currentEvent: "",
  pages: {},
  pathname: ""
};

const itemsPerPage = 10;

export class TableContainer extends React.Component {
  state = INITIAL_STATE;

  componentDidMount() {
    const { search, pathname } = this.props.location;
    this.setState({ pathname });
    const urlSearchValues = queryString.parse(search);
    const isOrdersScreen = pathname.includes("orders");
    const hasPageNumber = Boolean(urlSearchValues.page);

    if (isOrdersScreen && hasPageNumber) {
      // getOrders has no starting index, so we want to redirect to the first
      // page on initial load
      delete urlSearchValues.page;
      this.props.history.push({
        pathname: this.state.pathname,
        search: queryString.stringify(urlSearchValues)
      });
    } else {
      this.getUrlSearchValues(urlSearchValues);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      this.getUrlSearchValues(queryString.parse(this.props.location.search));
    }

    this.handleSearch(prevState);
    this.handlePageChange(prevState);
    this.updateURL(prevState);
    this.storePageItems(prevProps);
    this.loadNewItems(prevState);
  }

  getUrlSearchValues(url) {
    this.setState({
      currentQuery: url.query,
      currentPage: url.page ? url.page : "1",
      currentEvent: url.event
    });
  }

  updateURL = prevState => {
    if (this.state.currentIndex !== prevState.currentIndex) {
      const params = {
        query: this.state.currentQuery,
        event: this.state.currentEvent
      };
      if (this.state.currentIndex > 0) {
        params.page = (this.state.currentIndex + itemsPerPage) / itemsPerPage;
      }

      this.props.history.push({
        pathname: this.state.pathname,
        search: queryString.stringify(params)
      });
    }
  };

  storePageItems(prevProps) {
    const { dataType } = this.props;
    const items = this.props[dataType];
    const page = this.state.currentPage;
    if (!this.state.pages[page] && prevProps[dataType].data !== items.data) {
      const pages = { ...this.state.pages };
      pages[page] = {
        data: items.data,
        more: items.more
      };
      this.setState({ pages });
    }
  }

  getItems(newQuery) {
    const { currentPage, currentQuery } = this.state;
    const { isAdmin, after, dataType } = this.props;
    const { search } = this.props.location;
    const params = new URLSearchParams(search);
    const event = params.get("event");

    switch (dataType) {
      case "events":
        this.props.getEvents(
          currentQuery,
          getIndex(currentPage),
          isAdmin,
          undefined,
          after
        );
        break;
      case "orders":
        this.props.getOrders(event, currentQuery, newQuery);
        break;
      default:
        null;
    }
  }

  loadNewItems(prevState) {
    const { currentPage, currentQuery } = this.state;
    const { isAdmin, after, dataType } = this.props;
    const newPage =
      currentPage !== prevState.currentPage && !this.state.pages[currentPage];
    const newQuery = currentQuery !== prevState.currentQuery;
    if (newPage || newQuery) {
      switch (dataType) {
        case "events": {
          this.props.getEvents(
            currentQuery,
            getIndex(currentPage),
            isAdmin,
            undefined,
            after
          );
          break;
        }
        case "orders": {
          this.getItems(newQuery);
          break;
        }
        default: {
          throw new Error(`Unknown data type: ${dataType}`);
        }
      }
    }
  }

  handleSearch(prevState) {
    const { currentQuery } = this.state;
    if (currentQuery !== prevState.currentQuery) {
      this.setState({ currentIndex: 0, pages: {} });
    }
  }

  handlePageChange(prevState) {
    const { currentPage } = this.state;
    if (
      currentPage !== prevState.currentPage &&
      !this.state.pages[currentPage]
    ) {
      this.setState({ currentIndex: getIndex(currentPage) });
    }
  }

  handleNext = () => {
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + itemsPerPage
    }));
  };

  handlePrevious = () => {
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex - itemsPerPage
    }));
  };

  handleClearPages = () => {
    this.setState({
      pages: {}
    });
  };

  renderTablePagination() {
    const { paginationWidth, dataType } = this.props;
    const items = this.props[dataType];
    const { currentIndex, currentPage, pages } = this.state;
    const more = !pages[currentPage] ? items.more : pages[currentPage].more;

    if (items.data.length > 0) {
      return (
        <TablePagination
          currentIndex={currentIndex}
          handleNext={this.handleNext}
          handlePrevious={this.handlePrevious}
          more={more}
          width={paginationWidth}
        />
      );
    }
    return null;
  }

  render() {
    const { currentPage, currentQuery, pages } = this.state;
    const { isAdmin } = this.props;

    return (
      <MainContainer extraClass="py-3" isAdmin={isAdmin}>
        {React.cloneElement(this.props.children, {
          clearPages: this.handleClearPages,
          query: currentQuery,
          page: currentPage,
          pages
        })}
        {this.renderTablePagination()}
      </MainContainer>
    );
  }
}

export const mapStateToProps = state => {
  return {
    events: state.events,
    orders: state.admin.orders
  };
};

TableContainer.propTypes = {
  events: PropTypes.object.isRequired,
  orders: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  getEvents: PropTypes.func.isRequired,
  getOrders: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  paginationWidth: PropTypes.string.isRequired,
  dataType: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  after: PropTypes.string
};

export default withRouter(
  connect(mapStateToProps, { getEvents, getOrders })(TableContainer)
);
