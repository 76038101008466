const SELECT = "marketplace/ticketSelection/SELECT";

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case SELECT:
      return action.payload;
    default:
      return state;
  }
}

export const selectTickets = tickets => dispatch => {
  dispatch({ type: SELECT, payload: tickets });
};
