import React from "react";
import { connect } from "react-redux";
import { formatDate } from "../../utils";
import queryString from "query-string";

import { confirmTicket } from "../../ducks/ticketConfirmation";

import Loader from "../core/Loader";
import ErrorPage from "../core/ErrorPage";

export class TicketConfirmation extends React.Component {
  componentDidMount() {
    const { claim, id } = this.props.match.params;
    const urlValues = queryString.parse(this.props.location.search);
    const signature = urlValues.s;

    this.props.confirmTicket(claim, id, signature);
  }

  render() {
    const { ticket, isFetching } = this.props;
    const { id } = this.props.match.params;
    const ticketNumber = parseInt(id, 10) + 1;
    const urlValues = queryString.parse(this.props.location.search);
    const total = urlValues.t;
    const eventDate = formatDate(ticket.eventDateAndTime);
    const ticketExists = Object.getOwnPropertyNames(ticket).length > 0;

    if (isFetching) {
      return (
        <div className="flex items-center min-h-screen justify-center">
          <Loader />
        </div>
      );
    } else if (ticketExists) {
      return (
        <div className="flex items-center min-h-screen mx-6 content-center flex-wrap justify-center">
          <div className="mt-3 ">
            <p className="my-1 text-2xl w-full text-center">
              {ticket.customerName}
            </p>
            <p className="my-1 text-xl w-full text-center">
              Ticket {ticketNumber} of {total}
            </p>
            <p className="my-1 text-xl w-full text-center">
              {ticket.eventName}
            </p>
            <p className="my-1 text-xl w-full text-center">
              {eventDate.monthShort} {eventDate.day}, {eventDate.year}{" "}
              {eventDate.time}
            </p>
            <p className="my-1 text-xl w-full text-center">
              Section: {ticket.blockName}
            </p>
          </div>
        </div>
      );
    }
    return <ErrorPage />;
  }
}

export const mapStateToProps = state => {
  return {
    ticket: state.ticketConfirmation.ticketInfo,
    isFetching: state.ticketConfirmation.isFetching
  };
};

export default connect(mapStateToProps, { confirmTicket })(TicketConfirmation);
