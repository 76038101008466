import React from "react";
import PropTypes from "prop-types";
import InputLabel from "./InputLabel";

class Input extends React.Component {
  render() {
    const {
      containerClass,
      field,
      label,
      type,
      onChange,
      value,
      required,
      disabled,
      id,
      error,
      helpText,
      errorMessage,
      placeholder
    } = this.props;

    const fieldId = id ? `${id}-${field}` : field;

    return (
      <div className={containerClass}>
        <InputLabel
          field={fieldId}
          label={label}
          required={required}
          helpText={helpText}
        />
        <input
          type={type}
          className={`border p-2 w-full${error ? " border-red-500" : ""}${
            disabled ? " text-gray-400 italic" : ""
          }`}
          name={field}
          onChange={onChange}
          value={value}
          required={required}
          disabled={disabled}
          id={fieldId}
          min={type === "number" ? "1" : null}
          aria-required={required}
          aria-invalid={error}
          data-testid="input"
          data-id={id || field}
          placeholder={placeholder}
        ></input>
        {error && (
          <p className="text-xs italic mt-1 text-red-500">{errorMessage}</p>
        )}
      </div>
    );
  }
}

Input.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  type: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  containerClass: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.bool,
  helpText: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string
};

export default Input;
