import React from "react";
import PropTypes from "prop-types";

import PromoCode from "./PromoCode";

const INITIAL_STATE = {
  orderTotal: undefined,
  orderFee: undefined
};

const decimals = 2;

class Summary extends React.Component {
  state = INITIAL_STATE;

  componentDidUpdate() {
    if (
      !this.props.isWaitlist &&
      this.props.intent.price &&
      this.props.intent.fee &&
      this.props.intent.price.replace("USD ", "$") !== this.state.orderTotal
    ) {
      this.setState({
        orderTotal: this.props.intent.price.replace("USD ", "$"),
        orderFee: this.props.intent.idealSeatFees.totalCalculatedFee.replace("USD ", ""),
        stripeFee: this.props.intent.fee.replace("USD ", "")
      });
    }
  }

  calculateDiscount(total, coupons) {
    let discountedTotal = parseFloat(total);
    const couponsArray = Object.values(coupons);
    // TODO: handle multiple coupon codes of same type
    if (couponsArray.length > 0) {
      couponsArray.forEach(coupon => {
        switch (coupon.effect.type) {
          case "percentOff": {
            discountedTotal *= 1 - coupon.effect.factor;
            break;
          }
          default: {
            break;
          }
        }
      });
    }

    return discountedTotal.toFixed(decimals);
  }

  renderLineItemTotal(discountedTotal, total) {
    let displayTotal;
    if (discountedTotal !== total) {
      displayTotal = (
        <p>
          <span className="line-through mr-3 italic text-gray-500">
            ${total}
          </span>
          <span className="font-bold">${discountedTotal}</span>
        </p>
      );
    } else {
      displayTotal = <p className="font-bold">${total}</p>;
    }
    return displayTotal;
  }

  renderLineItem(item) {
    const { intent } = this.props;
    const coupons = intent ? intent.couponDetails : undefined;
    const { name, numberOfTickets, total } = item;
    let discountedTotal = total;
    if (coupons) {
      discountedTotal = this.calculateDiscount(total, coupons);
    }

    return (
      <div className="flex justify-between p-3" key={name}>
        <p>
          {numberOfTickets} X {name}
        </p>
        {this.renderLineItemTotal(discountedTotal, total)}
      </div>
    );
  }

  renderTransactionFee() {
    const { price } = this.props.intent;
    if (price && price !== "USD 0.00" && this.state.orderFee) {
      const totalFee = "$" + (parseFloat(this.state.orderFee) + parseFloat(this.state.stripeFee));
      return (
        <div className="flex justify-between p-3">
          <p>Transaction Fee</p>
          <p className="font-bold">
            {totalFee}
          </p>
        </div>
      );
    }
    return null;
  }

  renderTotalDiscount() {
    const { totalCouponEffect } = this.props.intent;
    if (totalCouponEffect && totalCouponEffect !== "USD 0.00") {
      const discount = totalCouponEffect.replace("USD", "$");
      return (
        <div className="flex justify-between p-3">
          <p>Discount</p>
          <p className="font-bold text-red-500">{discount}</p>
        </div>
      );
    }
    return null;
  }

  renderGrandTotal() {
    if (this.state.orderTotal) {
      const grandTotal = this.state.orderTotal.replace("USD ", "$");

      return (
        <div className="flex justify-between p-3 border-t mt-5">
          <p className="font-bold">Total</p>
          <p className="font-bold">{grandTotal}</p>
        </div>
      );
    }
    return null;
  }

  renderPromoCode() {
    const { intent, handleApplyPromo, handlePromoChange } = this.props;
    const { price } = intent;
    if (price && price !== "USD 0.00") {
      return (
        <PromoCode
          handlePromoChange={handlePromoChange}
          handleApplyPromo={handleApplyPromo}
          intent={intent}
        />
      );
    }
    return null;
  }

  renderPaymentSummary() {
    const { ticketBlocks, extras } = this.props.selectedTickets;
    const { intent } = this.props;
    const items = [];

    if (intent) {
      for (const block of Object.values(ticketBlocks)) {
        items.push(this.renderLineItem(block));
      }
      for (const extra of Object.values(extras)) {
        items.push(this.renderLineItem(extra));
      }
    }
    return (
      <React.Fragment>
        {items}
        {this.renderTransactionFee()}
        {this.renderTotalDiscount()}
        {this.renderGrandTotal()}
        {this.renderPromoCode()}
      </React.Fragment>
    );
  }

  renderWaitlistSummary(ticketBlocks) {
    let sections;

    if (ticketBlocks) {
      sections = Object.values(ticketBlocks).map(item => {
        return this.renderLineItem(item);
      });
    }

    return sections;
  }

  render() {
    const { ticketBlocks } = this.props.selectedTickets;
    let summary;

    if (this.props.isWaitlist) {
      summary = this.renderWaitlistSummary(ticketBlocks);
    } else {
      summary = this.renderPaymentSummary(ticketBlocks);
    }

    return (
      <div className="md:w-1/2 w-full md:pl-16 my-8 md:my-0">
        <h1 className="text-2xl py-3 mb-3 border-b w-full">Order Summary</h1>
        <div className="border p-3 md:mt-12 mt-4 bg-white">{summary}</div>
      </div>
    );
  }
}

Summary.propTypes = {
  selectedTickets: PropTypes.object.isRequired,
  handlePromoChange: PropTypes.func,
  handleApplyPromo: PropTypes.func,
  intent: PropTypes.object,
  isWaitlist: PropTypes.bool
};

export default Summary;
