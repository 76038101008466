const REQUEST = "marketplace/order/REQUEST";
const SUCCESS = "marketplace/order/SUCCESS";
const FAILURE = "marketplace/order/FAILURE";

const initialState = { data: {} };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case SUCCESS:
      return { ...state, isFetching: false, data: action.payload };
    case FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload.message
      };
    default:
      return state;
  }
}

export const getOrder = id => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .get(`/claims/${id}`)
    .then(results => dispatch({ type: SUCCESS, payload: results.data }))
    .catch(error => dispatch({ type: FAILURE, payload: error, error: true }));
};
