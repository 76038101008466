import React from "react";
import PropTypes from "prop-types";
import Highlight from "./Highlight";

export class Highlights extends React.Component {
  renderHighlights() {
    const { highlights } = this.props;
    const { isFetching } = highlights;
    const events = highlights.data;

    if (events) {
      return events.map((event, index) => {
        return (
          <Highlight
            key={index}
            event={event}
            isFetching={isFetching}
          ></Highlight>
        );
      });
    }
    return null;
  }

  render() {
    return (
      <div className="flex w-full md:w-2/3 lg:w-5/6 justify-center items-stretch flex-col lg:flex-row my-10 flex-wrap">
        {this.renderHighlights()}
      </div>
    );
  }
}

Highlights.propTypes = {
  highlights: PropTypes.object
};

export default Highlights;
