import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth0 } from "../../react-auth0-spa";

import theme from "../../theme";
import Nav from "./Nav";

const Header = props => {
  const { user } = useAuth0();
  return (
    <header className="p-3 header">
      <div className="container mx-auto flex justify-between print:justify-center items-center flex-col md:flex-row md:max-h-18 print:max-h-full">
        <Link to="/">
          {/* eslint-disable global-require */}
          <img
            src={theme.LOGO_SRC}
            alt="logo"
            className="max-w-xs md:max-h-18 print:max-w-lg print:max-h-full"
          />
        </Link>

        <Nav isAdmin={props.isAdmin} user={user} />
      </div>
    </header>
  );
};

Header.propTypes = {
  isAdmin: PropTypes.bool
};

export default Header;
