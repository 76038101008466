import React from "react";
import PropTypes from "prop-types";
import ItemContainer from "../ItemContainer";
import ExtraWithVariations from "./ExtraWithVariations";

class Extras extends React.Component {
  renderBasicExtras() {
    const extras = this.props.extras.filter(
      extra => !extra.heading && extra.status === "active"
    );
    const { event, selectedTickets, selectTickets } = this.props;

    const basicExtras = [];
    for (const extra of extras) {
      const percentAvailable = extra.availableQuantity / extra.totalQuantity;
      const unlimited = extra.totalQuantity === "unlimited";
      basicExtras.push(
        <ItemContainer
          item={extra}
          event={event}
          itemType="extras"
          name={extra.name}
          unlimited={unlimited}
          percentAvailable={percentAvailable}
          selectedTickets={selectedTickets}
          selectTickets={selectTickets}
          key={extra.name}
          isVariation={false}
        />
      );
    }
    return basicExtras;
  }

  renderExtrasWithVariations() {
    const { event, selectedTickets, selectTickets } = this.props;
    const extrasVariations = this.props.extras.filter(
      extra => extra.heading && extra.status === "active"
    );
    const extras = {};

    for (const variation of extrasVariations) {
      if (extras[variation.heading]) {
        extras[variation.heading].push(variation);
      } else {
        extras[variation.heading] = [variation];
      }
    }

    const extrasWithVariations = [];
    for (const heading in extras) {
      extrasWithVariations.push(
        <ExtraWithVariations
          variations={extras[heading]}
          event={event}
          selectedTickets={selectedTickets}
          selectTickets={selectTickets}
          key={heading}
        />
      );
    }
    return extrasWithVariations;
  }

  render() {
    return (
      <React.Fragment>
        <span className="text-2xl mr-2">Extras</span>
        {this.renderBasicExtras()}
        {this.renderExtrasWithVariations()}
      </React.Fragment>
    );
  }
}

Extras.propTypes = {
  event: PropTypes.object.isRequired
};

export default Extras;
