/* eslint-disable react/display-name */
import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { Link } from "react-router-dom";
import { useTable, useRowSelect, useSortBy } from "react-table";

import Button from "../../core/Button";
import Heading from "./Heading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortDown,
  faSortUp,
  faSort,
  faFrown,
  faExternalLinkAlt
} from "@fortawesome/free-solid-svg-icons";

const getOrdersData = (orders, page, pages) => {
  const loadingData = [{}, {}, {}, {}, {}];
  let ordersData;
  if (pages[page]) {
    ordersData = pages[page].data;
  } else if (orders.data.length > 0) {
    ordersData = orders.data;
  } else {
    ordersData = loadingData;
  }
  return ordersData;
};

const loadingCell = <div className="h-4 full mr-4 loading" />;

const OrdersTable = props => {
  const loading = props.orders.data.length <= 0;
  const columns = React.useMemo(
    () => [
      {
        Header: "Order #",
        accessor: "order",
        Cell: ({ value }) => (loading ? loadingCell : value)
      },
      {
        Header: "Customer",
        accessor: "customer",
        Cell: ({ value }) => (loading ? loadingCell : value)
      },
      {
        Header: "Timestamp",
        accessor: "timestamp",
        Cell: ({ value }) => (loading ? loadingCell : value)
      },
      {
        Header: "Total $",
        accessor: "total",
        Cell: ({ value }) => (loading ? loadingCell : value)
      },
      {
        Header: "",
        accessor: "view",
        Cell: ({ value }) => (loading ? loadingCell : value),
        disableSortBy: true
      }
    ],
    [loading]
  );

  const { orders, page, pages } = props;
  const rawData = getOrdersData(orders, page, pages);
  const orderData = orders.data.length <= 0 ? rawData : [];
  if (orders.data.length > 0) {
    rawData.forEach(order => {
      orderData.push({
        order: order.id.replace("clm_", ""),
        timestamp: moment(order.lockedAt).format("MM/DD/YY hh:mm a"),
        customer: order.customerInfo.name,
        total: order.invoice.grandTotal.replace("USD ", "$")
      });
    });
  }

  // eslint-disable-next-line
  const data = React.useMemo(() => orderData, [props.orders, page]);
  const sort = column => {
    return (
      <div className="ml-2 inline">
        {column.isSorted ? (
          column.isSortedDesc ? (
            <FontAwesomeIcon icon={faSortDown} />
          ) : (
            <FontAwesomeIcon icon={faSortUp} />
          )
        ) : (
          <FontAwesomeIcon icon={faSort} className="text-gray-400" />
        )}
      </div>
    );
  };

  const header = headerGroups => {
    const columnWidths = {
      order: "w-2/5 sm:w-1/5",
      customer: "w-2/5 sm:w-1/5",
      event: "w-1/4 hidden sm:table-cell",
      total: "sm:w-1/6 lg:w-1/12 hidden sm:table-cell",
      view: "w-1/6 sm:w-1/5"
    };

    return (
      <thead className="bg-gray-200">
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, i) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                key={i}
                className={`text-base sm:text-lg text-left p-3 ${
                  columnWidths[column.id]
                }`}
              >
                {column.render("Header")}
                {column.canSort ? sort(column) : ""}
              </th>
            ))}
          </tr>
        ))}
      </thead>
    );
  };

  const normalCell = cell => {
    const link = cell.row.values.order;
    const hiddenOnMobile =
      cell.column.id !== "order" && cell.column.id !== "customer";

    return (
      <td
        {...cell.getCellProps()}
        className={`text-base sm:text-lg ${
          hiddenOnMobile ? "sm:table-cell hidden " : ""
        }`}
      >
        <Link to={`/admin/orders/${link}`} className="px-3 py-6 block truncate">
          {cell.render("Cell")}
        </Link>
      </td>
    );
  };

  const buttonCell = cell => {
    return (
      <td
        {...cell.getCellProps()}
        className="px-3 flex items-center justify-end"
      >
        <Button
          label="View"
          isLink={true}
          path={`/admin/orders/${cell.row.values.order}`}
          extraClass="min-w-24 lg:min-w-40 hidden sm:block"
        />
        <div className="px-3 py-6 sm:hidden">
          <FontAwesomeIcon icon={faExternalLinkAlt} className="text-2xl" />
        </div>
      </td>
    );
  };

  data.map(row => {
    row.more = {
      current: row.status === "Past" ? false : true,
      link: row.id,
      isPublished: row.status === "Published"
    };
    return row;
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data
    },
    useSortBy,
    useRowSelect,
    /* eslint-disable-next-line */
    hooks => {}
  );

  const renderContent = () => {
    if (props.orders.errorMessage) {
      return (
        <div className="h-full flex justify-center items-center flex-col">
          Something went wrong. Try again later.
          <FontAwesomeIcon
            icon={faFrown}
            className="fa-8x text-gray-300 mt-3"
          />
        </div>
      );
    } else if (props.orders.data.length <= 0 && !props.orders.isFetching) {
      return (
        <div className="h-full flex justify-center items-center flex-col">
          Your search returned no results.
        </div>
      );
    }
    return (
      <div className="m-3">
        <table
          {...getTableProps()}
          className="text-lg bg-white border admin-table mb-12 table-fixed w-full"
        >
          {header(headerGroups)}
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i} className="border-t">
                  {row.cells.map(cell => {
                    if (cell.column.id === "view") {
                      return buttonCell(cell);
                    }
                    return normalCell(cell);
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <React.Fragment>
      <Heading />
      {renderContent()}
    </React.Fragment>
  );
};

OrdersTable.propTypes = {
  orders: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,
  pages: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    orders: state.admin.orders
  };
};

export default withRouter(connect(mapStateToProps, {})(OrdersTable));
