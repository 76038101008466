import React, { useEffect } from "react";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { useAuth0 } from "../../react-auth0-spa";

import EventsDashboard from "./Events";
import Event from "./Event/EventContainer";
import Order from "../Order";
import Orders from "./Orders";
import Share from "./Event/Share";
import ErrorPage from "../core/ErrorPage";
import config from "../../config";

const Admin = () => {
  const { isAuthenticated, loginWithRedirect, loading, user } = useAuth0();
  const validAdminUser =
    user &&
    user["https://idealseat.com/adminForTenants"] &&
    user["https://idealseat.com/adminForTenants"].includes(config.TENANT_ID);
  const history = useHistory();

  // TODO: account for other potential private routes (ie: user)
  // reset URL to an Auth0 allowed callback URL
  if (!isAuthenticated && !loading && window.location.pathname !== "/admin") {
    history.push("/admin");
  }

  useEffect(() => {
    if (!isAuthenticated && !loading) {
      loginWithRedirect({ redirect_uri: window.location.href });
    }
  }, [isAuthenticated, loginWithRedirect, loading]);

  // all Admin URLS require Auth0 login
  if (isAuthenticated && validAdminUser) {
    return (
      <Switch>
        <Route exact path="/admin" component={EventsDashboard} />
        <Route exact path="/admin/events/:id" component={Event} />
        <Route exact path="/admin/orders" component={Orders} />
        <Route exact path="/admin/events/:id/share" component={Share} />
        <Route
          exact
          path="/admin/orders/:id"
          render={props => <Order {...props} isAdmin={true} />}
        />
        <Route exact path="/admin/events">
          <Redirect to="/admin" />
        </Route>
        <Route render={props => <ErrorPage {...props} isAdmin={true} />} />
      </Switch>
    );
  } else if (isAuthenticated) {
    return (
      <Route
        render={props => (
          <ErrorPage
            {...props}
            message="Oops! You don't have admin access for this organization."
          />
        )}
      />
    );
  }
  return null;
};

Admin.propTypes = {
  loggedIn: PropTypes.bool,
  history: PropTypes.object.isRequired
};

export default withRouter(Admin);
