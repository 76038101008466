import React from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAsterisk,
  faChevronDown,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";

import "react-quill/dist/quill.snow.css";

const INITIAL_STATE = {
  expanded: false
};

class Textarea extends React.Component {
  state = INITIAL_STATE;
  constructor(props) {
    super(props);
    const { id, field } = props;
    this.fieldId = id ? `${id}-${field}` : field;
  }

  handleChange = value => {
    const { onChange, field, id } = this.props;
    const event = {
      target: { name: field, value, dataset: { id } }
    };
    onChange(event);
  };

  toggleQuillDisplay = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded
    }));
  };

  renderLabel() {
    const { collapsible, label, required } = this.props;
    const { expanded } = this.state;

    return (
      <label
        htmlFor={this.fieldId}
        className={collapsible && (expanded ? "flex" : "flex border-b")}
      >
        <p className="mb-2 flex items-center flex-wrap">
          {label}{" "}
          {required && (
            <FontAwesomeIcon
              icon={faAsterisk}
              className="text-2xs secondary-color ml-1"
            />
          )}
        </p>
        {collapsible && (
          <div
            className="cursor-pointer px-2 cursor-pointer"
            onClick={this.toggleQuillDisplay}
          >
            <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
          </div>
        )}
      </label>
    );
  }

  renderQuill() {
    const { error, value, collapsible, errorMessage } = this.props;
    const { expanded } = this.state;

    const modules = {
      toolbar: [
        [{ 'header': [1, 2, false] }, { 'font': [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
         {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
      ]
    };

    const formats = [
      'header', 'font', 'size',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image', 'video'
    ];

    if (!collapsible || (collapsible && expanded)) {
      return (
        <React.Fragment>
          <ReactQuill
            modules={modules}
            formats={formats}
            value={value}
            onChange={this.handleChange}
            id={this.fieldId}
            className={error ? "textarea-error" : ""}
          />
          {error && (
            <p className="text-xs italic mt-1 text-red-500">{errorMessage}</p>
          )}
        </React.Fragment>
      );
    }
    return null;
  }

  render() {
    const { containerClass } = this.props;

    return (
      <div className={containerClass}>
        {this.renderLabel()}
        {this.renderQuill()}
      </div>
    );
  }
}

Textarea.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  containerClass: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  collapsible: PropTypes.bool,
  errorMessage: PropTypes.string
};

export default Textarea;
