import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import Button from "../../core/Button";
import SearchBar from "../../core/SearchBar";
import theme from "../../../theme";

class Heading extends React.Component {
  renderToggleOption = view => {
    const { currentTimeframe, setTimeframe } = this.props;
    const baseClasses = "p-2 px-6 flex-grow text-center";
    const activeClasses =
      "rounded-full font-bold bg-white primary-color border";
    const inactiveClasses = "text-gray-500 cursor-pointer";
    const classes = `${baseClasses} ${
      currentTimeframe === view ? activeClasses : inactiveClasses
    }`;
    return (
      <div className={classes} onClick={() => setTimeframe(view)} key={view}>
        {view}
      </div>
    );
  };

  renderToggle = () => {
    const { timeframes } = this.props;
    const options = [];
    timeframes.forEach(timeframe => {
      options.push(this.renderToggleOption(timeframe));
    });
    return (
      <div className="flex mx-3 rounded-full rounded-full border-2 bg-gray-200 min-w-64">
        {options}
      </div>
    );
  };

  render() {
    const { pathname } = this.props.location;
    const isDemo = theme.DEMO;

    return (
      <div className="flex items-center flex-row mx-0 p-1 flex-wrap sm:flex-no-wrap">
        <h1 className="text-3xl mr-auto font-bold px-3">Events</h1>

        {this.renderToggle()}

        <SearchBar placeholder="Search Events" pathname={pathname} />
        {isDemo && (
          <Button
            isLink={true}
            path="/admin/events/demo"
            label="Demo Event"
            extraClass="sm:mx-3 min-w-32 w-full sm:w-auto mt-0 sm:mt-3"
          />
        )}
        <Button
          isLink={true}
          path="/admin/events/new"
          label="Create Event"
          extraClass="sm:mx-3 min-w-32 w-full sm:w-auto mt-0 sm:mt-3"
        />
      </div>
    );
  }
}

Heading.propTypes = {
  location: PropTypes.object.isRequired,
  currentTimeframe: PropTypes.string.isRequired,
  timeframes: PropTypes.array.isRequired,
  setTimeframe: PropTypes.func.isRequired
};

export default withRouter(Heading);
