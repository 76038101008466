/* eslint-disable global-require */
import moment from "moment";
import randomWords from "random-words";

export const formatDate = dateAndTime => {
  const eventDate = new Date(dateAndTime);
  const newDate = {
    monthShort: moment(eventDate).format("MMM"),
    monthLong: moment(eventDate).format("MMMM"),
    day: moment(eventDate).format("DD"),
    time: moment(eventDate).format("h:mm a"),
    weekday: moment(eventDate).format("dddd"),
    year: moment(eventDate).format("YYYY")
  };
  return newDate;
};

export const createMarkup = markup => {
  return { __html: markup };
};

export const getMaxTicketRestrictions = restrictions => {
  return restrictions.filter(
    restriction => restriction.type === "maxTicketCount"
  );
};

export const getIndex = page => {
  const numberOfTableItems = 10;
  return page * numberOfTableItems - numberOfTableItems;
};

const eventStatusDescription = {
  NO_CLAIMS: {
    ADMIN_ONLY: "Draft",
    PUBLIC: "Canceled",
    ARCHIVED: "Deleted"
  },
  LIVE: {
    ADMIN_ONLY: "Hidden",
    PUBLIC: "Published",
    ARCHIVED: "Deleted"
  }
};

export const eventStatus = (availability, visibility) => {
  if (availability && visibility) {
    return eventStatusDescription[availability][visibility];
  }
  return null;
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
  document.activeElement.blur();
};

export const validateFieldLength = (value, maxLength) => {
  const minimumStringLength = 5;
  const defaultMaximumStringLength = 100;
  const maxStringLength = maxLength || defaultMaximumStringLength;
  const lessThanMinimumStringLength = value.length < minimumStringLength;
  const greaterThanMaximumStringLength = value.length > maxStringLength;
  const errorMessage = lessThanMinimumStringLength
    ? "Field must be at least 5 characters long."
    : `Field cannot be longer than ${maxStringLength} characters.`;
  return {
    error: lessThanMinimumStringLength || greaterThanMaximumStringLength,
    errorMessage: errorMessage
  };
};

export const validateCouponCode = (couponCode, maxLength) => {
  if (!couponCode.match(/^[0-9a-zA-Z]+$/)) {
    return {
      error: true,
      errorMessage: "Coupon Code must be alphanumeric characters"
    };
  }
  const minimumStringLength = 4;
  const defaultMaximumStringLength = 15;
  const maxStringLength = maxLength || defaultMaximumStringLength;
  const lessThanMinimumStringLength = couponCode.length < minimumStringLength;
  const greaterThanMaximumStringLength = couponCode.length > maxStringLength;
  const errorMessage = lessThanMinimumStringLength
    ? "Coupon Code must be at least 4 characters long."
    : `Coupon cannot be longer than ${maxStringLength} characters.`;
  return {
    error: lessThanMinimumStringLength || greaterThanMaximumStringLength,
    errorMessage: errorMessage
  };
};


const hour = 19;
const minute = 30;

export const demoEventData = {
  address: "620 Rutherford Street Suite 200",
  start_date: moment()
    .add(1, "days")
    .format("YYYY-MM-DD"),
  description: "<p>This event is a demo for IdealSeat!</p>",
  extraIds: ["kf2vfjzg"],
  extras: {
    kf2vfjzg: {
      name: "Parking Pass",
      price: "10",
      variations: {
        kf2vfjzg: {
          itemId: "kf2vfjzg",
          name: "Lot 100",
          status: "active",
          totalQuantity: "50"
        },
        kf2vfv9n: {
          itemId: "kf2vfv9n",
          name: "Lot 200",
          status: "active",
          totalQuantity: "50"
        }
      }
    }
  },
  hasExtras: true,
  hasRestrictions: true,
  image: require("../styles/themes/bandwagon/experience-better-crowd.jpg"),
  lastStep: "restrictions",
  newEvent: true,
  numberOfSteps: 4,
  number_of_tickets: "100",
  organization: "sections",
  restrictionIds: ["kf2vg7e8"],
  restrictions: {
    kf2vg7e8: {
      limit: "2",
      sections: ["kf2vf1nh"]
    }
  },
  sectionIds: ["kf2vf1nh", "BL2"],
  sections: {
    BL2: { name: "VIP Section", price: "100", quantity: "25" },
    kf2vf1nh: { name: "General Admission", price: "50", quantity: "75" }
  },
  slug: `demo-event-${randomWords({ exactly: 2, join: "-" })}`,
  start_time: moment()
    .set("hour", hour)
    .set("minute", minute)
    .format("HH:mm"),
  title: "Demo Event",
  venue: "Bandwagon Office",
  conferenceUrl: "https://generic.meeting.com/abc-def-ghi"
};

export const getNumberOfActiveEventItems = event => {
  const numberOfItems =
    event.ticketBlocks.filter(block => block.status === "active").length +
    event.extras.filter(extra => extra.status === "active").length;
  return numberOfItems;
};
