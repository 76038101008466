import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";

import SearchBar from "../../core/SearchBar";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { getAllOrders } from "../../../ducks/admin/allOrders.js";
import Button from "../../core/Button.jsx";
import queryString from "query-string";

class Heading extends React.Component {
  componentDidMount() {
    const urlValues = queryString.parse(this.props.location.search);
    this.props.getAllOrders(urlValues.event);
  }

  renderExportButton() {
    const data = _.cloneDeep(this.props.allOrders.data);
    for (const order of data) {
      order.lockedAt = moment(order.lockedAt).format("MM/DD/YY hh:mm a");
    }

    const csvHeaders = [
      { label: "Order #", key: "id" },
      { label: "Customer Name", key: "customerInfo.name" },
      { label: "Customer Email", key: "customerInfo.email" },
      { label: "Event", key: "eventName" },
      { label: "Timestamp", key: "lockedAt" },
      { label: "Total $", key: "invoice.grandTotal" }
    ];

    if (data.length <= 0) {
      return (
        <Button
          label={
            <React.Fragment>
              <FontAwesomeIcon icon={faDownload} /> Export Orders
            </React.Fragment>
          }
          disabled={true}
          extraClass="sm:mx-3 min-w-40 w-full sm:w-auto text-gray-500"
        />
      );
    }
    return (
      <CSVLink
        filename={"Orders"}
        headers={csvHeaders}
        data={data}
        className="sm:mx-3 min-w-40 w-full sm:w-auto primary-bg -button text-center tracking-wide rounded font-semibold p-3"
      >
        <FontAwesomeIcon icon={faDownload} />
        {" Export Orders"}
      </CSVLink>
    );
  }

  render() {
    const { pathname } = this.props.location;

    return (
      <div className="flex items-center flex-row mx-0 p-1 flex-wrap sm:flex-no-wrap">
        <h1 className="text-3xl mr-auto font-bold px-3">Orders</h1>
        <SearchBar placeholder="Search Orders" pathname={pathname} />
        {this.renderExportButton()}
      </div>
    );
  }
}

Heading.propTypes = {
  location: PropTypes.object.isRequired,
  getAllOrders: PropTypes.func.isRequired,
  allOrders: PropTypes.object.isRequired,
  orders: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    allOrders: state.admin.allOrders,
    orders: state.admin.orders
  };
};

export default withRouter(connect(mapStateToProps, { getAllOrders })(Heading));
