import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "../../core/Select";
import { validateEventForm } from "../../../ducks/admin/eventForm";

const INITIAL_STATE = {
  error: true
};

export class AdminSelect extends React.Component {
  state = INITIAL_STATE;

  constructor(props) {
    super(props);
    this.inputId = this.props.field + this.props.id;
  }

  render() {
    const {
      options,
      fieldName,
      field,
      onChange,
      width,
      value,
      required,
      id,
      isMulti,
      displayError
    } = this.props;
    const fieldIsInvalid = value.length <= 0;

    if (required) {
      this.props.validateEventForm(fieldIsInvalid, this.inputId);
    }

    return (
      <Select
        options={options}
        label={fieldName}
        field={field}
        onChange={onChange}
        containerClass={`w-full sm:w-${width} p-2`}
        value={value}
        required={required}
        id={id}
        isMulti={isMulti}
        error={required && displayError && fieldIsInvalid}
      />
    );
  }
}

AdminSelect.propTypes = {
  fieldName: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  validateEventForm: PropTypes.func.isRequired,
  displayError: PropTypes.bool
};

export default connect(null, { validateEventForm })(AdminSelect);
