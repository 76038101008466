import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";

class Ticket extends React.Component {
  render() {
    const { ticket, claim, index, total } = this.props;
    const { ticketId, signature, blockName } = ticket;
    const params = new URLSearchParams({ s: signature, t: total });
    const baseUrl = window.location.origin;
    const confirmation = `/tickets/${claim}/${index}?${params}`;
    const QRconfirmation = `${baseUrl}${confirmation}`;

    return (
      <div className="my-5 print:w-1/3 print:px-3 pagebreak">
        <p className="my-1 text-2xl w-full text-center print:text-left">
          Ticket #{ticketId}: {blockName}
        </p>
        <Link to={confirmation}>
          <QRCode
            value={QRconfirmation}
            className="w-full my-3 print:hidden"
            renderAs="svg"
            size={320}
          />
          <QRCode
            value={QRconfirmation}
            className="w-full my-3 hidden print:block"
            renderAs="svg"
            size={225}
          />
        </Link>
      </div>
    );
  }
}

Ticket.propTypes = {
  ticket: PropTypes.object.isRequired,
  claim: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  total: PropTypes.number
};

export default Ticket;
