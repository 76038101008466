import axios from "axios";

const REQUEST = "marketplace/admin/images/REQUEST";
const UPDATE_EVENT_IMAGE = "marketplace/admin/images/UPDATE_EVENT_IMAGE";
const FAILURE = "marketplace/admin/images/FAILURE";

const initialState = {};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case UPDATE_EVENT_IMAGE:
      return { ...state, isFetching: false, src: action.payload };
    case FAILURE:
      return { ...state, isFetching: false, error: true };
    default:
      return state;
  }
}

export const updateEventImage = image => async (dispatch, getState) => {
  dispatch({ type: REQUEST });
  try {
    const { postResource, getResource } = (
      await getState().apis.marketplace.post("/images")
    ).data;
    const formData = new FormData();
    formData.append("image", image);
    await axios.post(postResource, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    dispatch({ type: UPDATE_EVENT_IMAGE, payload: getResource });
  } catch (e) {
    dispatch({ type: FAILURE, payload: null });
  }
};

export const clearEventImage = () => dispatch => {
  dispatch({ type: UPDATE_EVENT_IMAGE, payload: null });
};
