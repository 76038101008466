import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ReCaptcha } from "react-recaptcha-google";

import { setCaptchaToken } from "../../ducks/captchaToken";
import { getCaptchaKey } from "../../ducks/captchaKey";

class Captcha extends React.Component {
  constructor(props) {
    super(props);
    this.onLoadCallback = this.onLoadCallback.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  componentDidMount() {
    this.props.getCaptchaKey();

    if (this.checkoutCaptcha) {
      this.checkoutCaptcha.reset();
      // this.checkoutCaptcha.execute(); // Used in reCaptcha v2 Invisible
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.error && this.props.error !== prevProps.error) {
      this.checkoutCaptcha.reset();
    }
  }

  onLoadCallback() {
    if (this.checkoutCaptcha) {
      this.checkoutCaptcha.reset();
      // this.checkoutCaptcha.execute(); // Used in reCaptcha v2 Invisible
    }
  }

  verifyCallback(clientToken) {
    this.props.setCaptchaToken(clientToken);
  }

  render() {
    if (this.props.captchaKey) {
      return (
        <div>
          <ReCaptcha
            ref={el => {
              this.checkoutCaptcha = el;
            }}
            size="normal"
            render="explicit"
            sitekey={this.props.captchaKey}
            onloadCallback={this.onLoadCallback}
            verifyCallback={this.verifyCallback}
          />
        </div>
      );
    }
    return null;
  }
}

Captcha.propTypes = {
  getCaptchaKey: PropTypes.func.isRequired,
  setCaptchaToken: PropTypes.func.isRequired,
  captchaKey: PropTypes.string,
  error: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    captchaKey: state.captchaKey.key
  };
};

export default connect(mapStateToProps, {
  setCaptchaToken,
  getCaptchaKey
})(Captcha);
