/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { Link } from "react-router-dom";
import { useTable, useRowSelect, useSortBy } from "react-table";

import { getIndex, eventStatus } from "../../../utils";
import ActionButton from "./ActionButton";
import FeaturedButton from "./FeaturedButton";
import Heading from "./Heading";
//import IndeterminateCheckbox from "./IndeterminateCheckbox";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortDown,
  faSortUp,
  faSort,
  faEdit,
  faFrown
} from "@fortawesome/free-solid-svg-icons";
import { getHighlights } from "../../../ducks/highlights";
import { trackHighlightedEvent } from "../../../ducks/admin/highlightedEvents";
import { patchEvent } from "../../../ducks/admin/event";
import { getEvents } from "../../../ducks/events";

const getEventsData = (events, page, pages) => {
  const loadingData = [{}, {}, {}, {}, {}];
  let eventsData;
  if (pages[page]) {
    eventsData = pages[page].data;
  } else if (events.data.length > 0) {
    eventsData = events.data;
  } else {
    eventsData = loadingData;
  }
  return eventsData;
};

const loadingCell = <div className="h-4 w-full mr-4 loading" />;
const Table = props => {
  const {
    initialHighlights,
    highlights,
    getHighlights,
    trackHighlightedEvent,
    events,
    page,
    pages,
    query,
    clearPages,
    getEvents,
    patchEvent
  } = props;
  const loading = events.data.length <= 0;
  const timeframes = ["Hide Past", "Show All"];
  const [timeframe, setTimeframe] = useState(timeframes[0]);
  const isFirstMount = useRef(true);

  useEffect(() => {
    if (!initialHighlights) {
      // if no highlights in props, initialize highlights
      getHighlights();
    } else if (highlights.length === 0 && initialHighlights.length > 0) {
      // add initial highlights from getHighlights to store
      for (const i in initialHighlights) {
        trackHighlightedEvent(initialHighlights[i].node.replace("events/", ""));
      }
    }
  }, [initialHighlights, highlights, getHighlights, trackHighlightedEvent]);

  const refreshEvents = () => {
    clearPages();
    const today = moment()
      .utc()
      .format();
    if (timeframe === timeframes[0]) {
      getEvents(query, getIndex(page), true, undefined, today);
    } else {
      getEvents(query, getIndex(page), true);
    }
  };

  useEffect(() => {
    // isFirstMount ref is used to skip the timeframe logic on initial render
    if (!isFirstMount.current) {
      refreshEvents();
    } else {
      isFirstMount.current = false;
    }
  }, [timeframe]);

  const handlePatchEvents = (ops, eventSlug) => {
    patchEvent(ops, eventSlug);
    refreshEvents();
  };

  const deleteEvent = eventSlug => {
    const ops = [
      { op: "replace", path: "/availability", value: "NO_CLAIMS" },
      { op: "replace", path: "/visibility", value: "ARCHIVED" }
    ];
    handlePatchEvents(ops, eventSlug);
  };

  const cancelEvent = eventSlug => {
    const ops = [{ op: "replace", path: "/availability", value: "NO_CLAIMS" }];
    handlePatchEvents(ops, eventSlug);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ value }) => (loading ? loadingCell : value)
      },
      {
        Header: "Event",
        accessor: "event",
        Cell: ({ value }) => (loading ? loadingCell : value)
      },
      {
        Header: "# Sold",
        accessor: "sold",
        Cell: ({ value }) => (loading ? loadingCell : value)
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (loading ? loadingCell : value)
      },
      {
        Header: () => <span className="text-center block">Featured</span>,
        accessor: "featured",
        /* eslint-disable-next-line */
        Cell: ({ value, row }) => (
          /* eslint-disable-next-line */
          <FeaturedButton value={value} row={row} />
        ),
        disableSortBy: true
      },
      {
        Header: () => <span className="text-center block">Manage</span>,
        accessor: "more",
        Cell: ({ value }) =>
          !loading && (
            <div className="text-center">
              <ActionButton
                deleteEvent={deleteEvent}
                cancelEvent={cancelEvent}
                link={value.link.replace("events/", "")}
                isPublished={value.isPublished}
                current={value.current}
              />
            </div>
          ),
        disableSortBy: true
      }
    ],
    [loading, query, page, timeframe]
  );

  const eventData = [];
  const today = moment();
  const eventsData = getEventsData(events, page, pages);

  eventsData.forEach(event => {
    const eventDate = moment(event.dateAndTime);
    const { availability, visibility } = event;
    const status = eventStatus(availability, visibility);
    eventData.push({
      date: eventDate.format("l"),
      event: event.name,
      status: today.diff(eventDate) > 0 ? "Past" : status,
      sold: event.ticketsSoldCount ? event.ticketsSoldCount : "0",
      featured:
        event.node && highlights
          ? highlights.includes(event.node.replace("events/", ""))
          : false,
      id: event.node
    });
  });

  // eslint-disable-next-line
  const data = React.useMemo(() => eventData, [events, page, timeframe]);

  const sort = column => {
    return (
      <div className="ml-2 inline">
        {column.isSorted ? (
          column.isSortedDesc ? (
            <FontAwesomeIcon icon={faSortDown} />
          ) : (
            <FontAwesomeIcon icon={faSortUp} />
          )
        ) : (
          <FontAwesomeIcon icon={faSort} className="text-gray-400" />
        )}
      </div>
    );
  };

  const header = headerGroups => {
    return (
      <thead className="bg-gray-200">
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, i) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                key={i}
                className={`text-left p-3 ${
                  column.id === "selection" ? "w-1/12 relative" : ""
                } ${column.id !== "event" ? "sm:table-cell hidden" : ""}`}
              >
                {column.render("Header")}
                {column.canSort ? sort(column) : ""}
              </th>
            ))}
          </tr>
        ))}
      </thead>
    );
  };

  const mobileCell = cell => {
    return (
      <span className="flex flex-wrap w-full justify-between items-center">
        <span>
          <span className="sm:hidden block text-base border-b">
            {cell.row.values.date} <span className="text-gray-300">|</span>{" "}
            <span className="italic">{cell.row.values.status}</span>
          </span>
          {cell.render("Cell")}
        </span>
        {cell.column.id === "event" && cell.row.values.status !== "Past" && (
          <FontAwesomeIcon icon={faEdit} className="block sm:hidden ml-3" />
        )}
      </span>
    );
  };

  const linkedCell = cell => {
    const link = cell.row.values.more.link;
    const hiddenOnMobile = cell.column.id !== "event";

    return (
      <td
        {...cell.getCellProps()}
        className={`${hiddenOnMobile ? "sm:table-cell hidden" : ""}`}
      >
        <Link
          to={`/admin/orders?event=${(link || "").replace("events/", "")}`}
          className="px-3 py-6 w-full flex justify-between items-center"
        >
          {!hiddenOnMobile ? mobileCell(cell) : cell.render("Cell")}
        </Link>
      </td>
    );
  };

  const staticCell = cell => {
    const hiddenOnMobile = cell.column.id !== "event";
    return (
      <td
        {...cell.getCellProps()}
        className={`px-3 py-6 ${hiddenOnMobile && "sm:table-cell hidden"}`}
      >
        {!hiddenOnMobile ? mobileCell(cell) : cell.render("Cell")}
      </td>
    );
  };

  data.map(row => {
    row.more = {
      current: row.status === "Past" ? false : true,
      link: row.id,
      isPublished: row.status === "Published"
    };
    return row;
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
    //selectedFlatRows,
    //state: { selectedRowIds }
  } = useTable(
    {
      columns,
      data
    },
    useSortBy,
    useRowSelect,
    /* eslint-disable-next-line */
    hooks => {
      /*
      // temporarily hide bulk actions //
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className="mx-3">
              {!loading && (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              )}
            </div>
          ),

          Cell: ({ row }) => (
            <div className="mx-3">
              {!loading && (

                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              )}
            </div>
          ),
          disableSortBy: true
        },
        ...columns
      ]);
      */
    }
  );

  const renderContent = () => {
    if (events.errorMessage) {
      return (
        <div className="h-full flex justify-center items-center flex-col">
          Something went wrong. Try again later.
          <FontAwesomeIcon
            icon={faFrown}
            className="fa-8x text-gray-300 mt-3"
          />
        </div>
      );
    } else if (events.data.length <= 0 && !events.isFetching) {
      return (
        <div className="h-full flex justify-center items-center flex-col">
          Your search returned no results.
        </div>
      );
    }
    return (
      <table
        {...getTableProps()}
        className="text-lg bg-white border admin-table mb-12 mx-3"
      >
        {header(headerGroups)}
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i} className="border-t">
                {row.cells.map(cell => {
                  if (
                    cell.column.id === "more" ||
                    cell.column.id === "selection" ||
                    cell.column.id === "featured" ||
                    cell.row.values.status === "Past"
                  ) {
                    return staticCell(cell);
                  }
                  return linkedCell(cell);
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <React.Fragment>
      <Heading
        timeframes={timeframes}
        currentTimeframe={timeframe}
        setTimeframe={setTimeframe}
      />
      {renderContent()}
    </React.Fragment>
  );
};

Table.propTypes = {
  events: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,
  pages: PropTypes.object.isRequired,
  highlights: PropTypes.array,
  initialHighlights: PropTypes.array,
  getHighlights: PropTypes.func.isRequired,
  trackHighlightedEvent: PropTypes.func.isRequired,
  patchEvent: PropTypes.func.isRequired,
  clearPages: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired,
  query: PropTypes.string
};

const mapStateToProps = state => {
  return {
    events: state.events,
    initialHighlights: state.highlights.data,
    highlights: state.admin.highlights
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getHighlights,
    trackHighlightedEvent,
    patchEvent,
    getEvents
  })(Table)
);
