import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MainContainer from "../../core/MainContainer";
import { getEvent } from "../../../ducks/event.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

class Share extends React.Component {
  componentDidMount() {
    this.props.getEvent(this.props.match.params.id);
  }

  renderShareLink(link, icon) {
    return (
      <a
        target="_blank"
        rel="noreferrer noopener"
        href={link}
        className="primary-color hover:text-gray-800 mx-1 fill-current"
        key={link}
      >
        <FontAwesomeIcon icon={icon} />
      </a>
    );
  }

  generateShareInfo() {
    const { name, dateAndTime, slug } = this.props.event;
    const baseUrl = window.location.origin;
    const eventUrl = `${baseUrl}/events/${slug}`;
    const eventDate = moment(dateAndTime).format("dddd, MMMM Do, YYYY");
    const eventTime = moment(dateAndTime).format("h:mm a");

    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${eventUrl}&amp;src=sdkpreparse`;

    const twitterString = `Check out ${name} on ${eventDate}! ${eventUrl}`;
    const twitterLink = `https://twitter.com/intent/tweet?text=${twitterString}`;

    const emailIntro = `Check out ${name} on ${eventDate} at ${eventTime}!`;
    const emailLineBreak = "%0D%0A%0D%0A";
    const emailEvent = `View the event here: ${eventUrl}`;
    const emailBody = `${emailIntro}${emailLineBreak}${emailEvent}`;
    const emailLink = `mailto:?to=%20&body=${emailBody}&subject=${name}`;

    const linkedinLink = `https://www.linkedin.com/shareArticle?mini=true&url=${eventUrl}`;

    return [
      { link: facebookLink, icon: faFacebookF },
      { link: twitterLink, icon: faTwitter },
      { link: emailLink, icon: faEnvelope },
      { link: linkedinLink, icon: faLinkedin }
    ];
  }

  renderSocial() {
    const socialLinks = this.generateShareInfo();
    const links = [];
    for (const social of socialLinks) {
      links.push(this.renderShareLink(social.link, social.icon));
    }
    return links;
  }

  render() {
    const { name } = this.props.event;
    return (
      <MainContainer isAdmin={true} extraClass="py-3">
        <div className="max-w-xl m-auto bg-white border p-6 text-center">
          <h1 className="text-2xl pb-2">Share your event!</h1>
          <h3 className="text-lg pb-2 border-b mb-3">{name}</h3>
          <div className="text-3xl">{this.renderSocial()}</div>
        </div>
      </MainContainer>
    );
  }
}

Share.propTypes = {
  getEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    event: state.event.data
  };
};

export default connect(mapStateToProps, { getEvent })(Share);
