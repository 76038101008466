/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import { useTable } from "react-table";
import TableRow from "./TableRow";
import PropTypes from "prop-types";

function tableRow(disabled, value) {
  if (!disabled) {
    return (
      <TableRow path={`/${value.node}`} dateAndTime={value.dateAndTime}>
        <p className="text-2xl">{value.name}</p>
        <p>{value.venue}</p>
      </TableRow>
    );
  }
  return (
    <TableRow disabled={true}>
      <div className="h-8 w-full loading" />
      <div className="h-4 w-1/2 loading mt-2" />
    </TableRow>
  );
}

function EventsTable(props) {
  const { resolvedData, disabled } = props;
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "event",
        Cell: ({ value }) => tableRow(disabled, value)
      }
    ],
    [disabled]
  );

  const data = React.useMemo(() => resolvedData, [resolvedData]);

  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data
  });

  return (
    <div className="lg:w-2/3 py-5">
      <table {...getTableProps()} className="events w-full">
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i} className="border-b">
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} key={i} className="p-2">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

EventsTable.propTypes = {
  resolvedData: PropTypes.array.isRequired,
  disabled: PropTypes.bool
};

export default EventsTable;
