import React from "react";
import PropTypes from "prop-types";
import AdminInput from "../core/AdminInput";
import AdminTextarea from "../core/AdminTextarea";
import AdminRadioInput from "../core/AdminRadioInput";
import AdminDateInput from "../core/AdminDateInput";
import AdminTimeInput from "../core/AdminTimeInput";
import CheckboxInput from "../../core/CheckboxInput";
import ImageUpload from "../core/ImageUpload";
import FeeModal from "./FeeModal";

import { scrollToTop, validateFieldLength } from "../../../utils";
import moment from "moment";

const INITIAL_STATE = {
  displayModal: false,
  tier: "pro"
};

const tiers = {
  pro: { label: "Pro", fee: "4" },
  premium: { label: "Premium", fee: "6" }
};

class EventInfo extends React.Component {
  state = INITIAL_STATE;

  componentDidMount() {
    scrollToTop();
  }

  componentDidUpdate(prevProps) {
    const prevFormState = prevProps.formState;
    const {
      hasExtras,
      hasRestrictions,
      hasCoupons,
      featureAdded
    } = this.props.formState;
    // featureAdded is used to track checkbox clicks since added<Feature> is
    // true when loading an existing event (props changes as data is loaded)
    const addedExtras = !prevFormState.hasExtras && hasExtras;
    const addedCoupons = !prevFormState.hasCoupons && hasCoupons;
    const addedRestrictions = !prevFormState.hasRestrictions && hasRestrictions;
    const addedProFeature = addedCoupons || addedRestrictions;
    const noExistingProFeatures =
      !prevFormState.hasRestrictions && !prevFormState.hasCoupons;

    if (featureAdded && addedExtras) {
      this.setState({ displayModal: true, tier: "premium" });
    }
    // only show modal for Pro tier if no other Pro features are selected and
    // extras/Premium is also not selected
    if (
      featureAdded &&
      noExistingProFeatures &&
      addedProFeature &&
      !hasExtras
    ) {
      this.setState({ displayModal: true, tier: "pro" });
    }
  }

  onDrop = pictureFile => {
    this.props.handleImageChange(pictureFile);
  };

  removeImage = () => {
    this.props.handleImageChange([]);
  };

  validateSlug = value => {
    // only allow letters, numbers & hyphens
    // no spaces or special characters
    // cannot begin or end with hyphen
    const containsOnlyLettersNumbersHyphens = /^[-A-Za-z0-9]+$/.test(value);
    const beginsOrEndsWithHyphen = /^-|-$/g.test(value);
    const lessThanMinimumStringLength = validateFieldLength(value);
    if (lessThanMinimumStringLength.error) {
      return lessThanMinimumStringLength;
    }

    return {
      error: !containsOnlyLettersNumbersHyphens || beginsOrEndsWithHyphen,
      errorMessage: beginsOrEndsWithHyphen
        ? "Slug may not begin or end with a hyphen(-)."
        : "Slug may only contain letters, numbers, and hyphens."
    };
  };

  validateUrl = () => {
    const { formState } = this.props;
    const { conferenceUrl, eventType } = formState;
    // apparently the most widely used url validation is from this post:
    // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
    /* eslint-disable no-inline-comments */
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    /* eslint-enable no-inline-comments */
    return {
      error: eventType !== "inPerson" && !pattern.test(conferenceUrl),
      errorMessage: "Must be a valid url"
    }
  }

  closeModal = e => {
    e.preventDefault();
    this.setState({ displayModal: false });
  };

  render() {
    const { formState } = this.props;
    const { displayModal, tier } = this.state;
    const {
      title,
      start_date,
      start_time,
      end_date,
      end_time,
      number_of_tickets,
      eventType,
      venue,
      address,
      shippingAddressRequired,
      conferenceUrl,
      description,
      organization,
      hasRestrictions,
      hasCoupons,
      displayErrors,
      slug,
      newEvent,
      isCopied,
      image,
      hasExtras
    } = formState;
    const { label, fee } = tiers[tier];

    return (
      <div className="p-3 flex flex-wrap">
        {displayModal && (
          <FeeModal
            heading={`${label} Feature`}
            body={`Adding this feature increases your event fee to ${fee}% per ticket.`}
            cancel={this.closeModal}
          />
        )}
        <AdminInput
          field="title"
          fieldName="Title"
          type="text"
          value={title || ""}
          onChange={this.props.handleInputChange}
          width="1/2"
          required={true}
          displayError={displayErrors}
          validateField={validateFieldLength}
        />
        <AdminInput
          field="slug"
          fieldName="Slug"
          type="text"
          value={slug || ""}
          onChange={this.props.handleInputChange}
          width="1/2"
          required={true}
          displayError={displayErrors}
          helpText="Unique name displayed in the event's URL. This cannot be changed."
          disabled={!newEvent && !isCopied}
          validateField={this.validateSlug}
        />
        <p className="p-2 pb-0">Event Image</p>
        <ImageUpload
          images={image ? [image] : undefined}
          singleImage={true}
          onImageChange={this.onDrop}
          removeImage={this.removeImage}
          alt={title}
        />
        <AdminDateInput
          allowClear={false}
          field="start_date"
          label="Start Date"
          value={start_date}
          onChange={this.props.handleStartDateInputChange}
          width="1/4"
          required={true}
          disabledDate={date => date.isSameOrBefore(moment().subtract(1, "day"))}
        />
        <AdminTimeInput
          field="start_time"
          label="Start Time"
          value={start_time}
          onChange={this.props.handleStartTimeInputChange}
          width="1/4"
          required={true}
        />
        <AdminDateInput
          allowClear={true}
          field="end_date"
          label="End Date"
          value={end_date || ""}
          onChange={this.props.handleEndDateInputChange}
          width="1/4"
          required={false}
          helpText="Leave blank if your event starts and ends on the same day or has no specified end time."
          disabledDate={date => date.isSameOrBefore(moment(start_date))}
        />
        <AdminTimeInput
          allowClear={true}
          field="end_time"
          label="End Time"
          value={end_time || ""}
          onChange={this.props.handleEndTimeInputChange}
          width="1/4"
          required={false}
          helpText="Leave blank if your event is open-ended and has specified no end time."
        />
        <AdminInput
          field="number_of_tickets"
          fieldName="Ticket Quantity"
          type="number"
          value={number_of_tickets || ""}
          onChange={this.props.handleInputChange}
          width="1/4"
          required={true}
          displayError={displayErrors}
        />
        <AdminRadioInput
          fieldName="eventType"
          label="Type of Event"
          fieldState={eventType}
          handleInputChange={this.props.handleInputChange}
          options={[
            { field: "inPerson", label: "In Person" },
            { field: "online", label: "Online" },
            { field: "both", label: "Both" }
          ]}
          width="3/4"
          containerClass="p-2"
          optionsClass="border w-full p-2 flex items-center"
          labelClass="mb-2"
        />
        {eventType !== "online" && <React.Fragment>
          <AdminInput
            field="venue"
            fieldName="Venue"
            type="text"
            value={venue || ""}
            onChange={this.props.handleInputChange}
            width="1/2"
            required={false}
            displayError={displayErrors}
          />
          <AdminInput
            field="address"
            fieldName="Address"
            type="text"
            value={address || ""}
            onChange={this.props.handleInputChange}
            width="1/2"
            required={eventType !== "online"}
            displayError={displayErrors}
            validateField={validateFieldLength}
            maxLength={200}
          />
        </React.Fragment>}
        {eventType !== "inPerson" && <AdminInput
          field="conferenceUrl"
          fieldName="Conference URL"
          type="text"
          value={conferenceUrl || ""}
          onChange={this.props.handleInputChange}
          required={eventType !== "inPerson"}
          helpText="Conference URL will only be shown to ticketholders."
          displayError={displayErrors}
          validateField={this.validateUrl}
          maxLength={200}
        />}
        <AdminTextarea
          label="Description"
          field="description"
          value={description || ""}
          onChange={this.props.handleInputChange}
          containerClass="w-full p-2"
          required={true}
          displayError={displayErrors}
          validateField={validateFieldLength}
          maxLength={5000}
        />
        <AdminRadioInput
          fieldName="organization"
          fieldState={organization}
          handleInputChange={this.props.handleInputChange}
          options={[
            { field: "sections", label: "Sections" },
            { field: "pools", label: "Pools" }
          ]}
          label="Choose how to organize your tickets"
          helpText="Choose pools if your sections do not have a specific number of
            tickets."
          containerClass="w-full px-2 py-4 border-b flex justify-between flex-wrap"
          labelClass="my-1"
          optionsClass="my-auto"
        />
        <CheckboxInput
          fieldName="hasRestrictions"
          fieldState={hasRestrictions}
          handleInputChange={this.props.handleCheckboxChange}
          options={[{ field: "hasRestrictions", label: "Yes" }]}
          label="Will there be any ticket restrictions?"
          helpText="Click yes if you&#39;d like to limit the number of tickets per
            transaction."
          containerClass={"px-2"}
        />
        <CheckboxInput
          fieldName="hasExtras"
          fieldState={hasExtras}
          handleInputChange={this.props.handleCheckboxChange}
          options={[{ field: "hasExtras", label: "Yes" }]}
          label="Will this event include any merchandise or extras?"
          containerClass={"px-2"}
        />
        <CheckboxInput
          fieldName="shippingAddressRequired"
          fieldState={shippingAddressRequired}
          handleInputChange={this.props.handleCheckboxChange}
          options={[{ field: "shippingAddressRequired", label: "Yes" }]}
          label="Will customers be required to provide a shipping address for this event?"
          containerClass={"px-2"}
        />
        <CheckboxInput
          fieldName="hasCoupons"
          fieldState={hasCoupons}
          handleInputChange={this.props.handleCheckboxChange}
          options={[{ field: "hasCoupons", label: "Yes" }]}
          label="Will this event include any coupons?"
          containerClass={"px-2"}
        />
      </div>
    );
  }
}

EventInfo.propTypes = {
  handleStartDateInputChange: PropTypes.func.isRequired,
  handleStartTimeInputChange: PropTypes.func.isRequired,
  handleEndDateInputChange: PropTypes.func.isRequired,
  handleEndTimeInputChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired
};

export default EventInfo;
