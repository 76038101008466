import React from "react";
import PropTypes from "prop-types";
import uniqid from "uniqid";
import AdminInput from "../core/AdminInput";
import AdminSelect from "../core/AdminSelect";
import EventBlock from "./EventBlock";

import { scrollToTop, validateCouponCode } from "../../../utils";

class EventCoupons extends React.Component {
  componentDidMount() {
    scrollToTop();
  }

  onSelectChange(e, name, id) {
    const event = { target: { name: name, value: e.value, dataset: { id } } };
    this.props.handleBlockChange(event);
  }

  renderAmountTypeSelection(id, typeSelected) {
    const { displayErrors } = this.props;
    const options = [
      {
        value: "percentOff",
        label: "%"
      },
      {
        value: "fixed",
        label: "$"
      }
    ];

    let selectedOption;
    if (typeSelected) {
      selectedOption = options.find(element => element.value === typeSelected);
    } else {
      selectedOption = options[0];
    }

    return (
      <AdminSelect
        options={options}
        field="couponEffectType"
        fieldName="%/$"
        width="2/12"
        onChange={e => this.onSelectChange(e, "couponEffectType", id)}
        id={id}
        value={selectedOption}
        displayError={displayErrors}
      />
    );
  }
  renderCouponTypeSelection(id, typeSelected) {
    const { displayErrors } = this.props;
    const options = [
      {
        value: "permanent",
        label: "Unlimited"
      },
      {
        value: "finiteUse",
        label: "Limited Use"
      }
    ];

    let selectedOption;
    if (typeSelected) {
      selectedOption = options.find(element => element.value === typeSelected);
    } else {
      selectedOption = options[0];
    }

    return (
      <AdminSelect
        options={options}
        field="couponUseType"
        fieldName="Coupon Type"
        width="3/12"
        onChange={e => this.onSelectChange(e, "couponUseType", id)}
        id={id}
        value={selectedOption}
        displayError={displayErrors}
      />
    );
  }

  renderCouponUses(id, couponUses) {
    const { displayErrors, handleBlockChange } = this.props;
    return (
        <AdminInput
          field="uses"
          fieldName="# of Uses"
          type="number"
          onChange={handleBlockChange}
          width="2/12"
          id={id}
          value={String(couponUses)}
          required={true}
          helpText={"Set the maximum number of uses for this discount code."}
          displayError={displayErrors}
        />
    );
  }
  couponCodeHelpText() {
    return "This is the code customers will enter at checkout. " +
    "It must be between 4 and 15 letters long, and cannot contain any punctuation" +
    "or special characters. Also, once a coupon is published, the code cannot be changed." +
     "To alter the code, delete the existing coupon, and create a new one."
  }

  render() {
    const couponBlocks = [];
    const { coupons, couponIds, displayErrors } = this.props;
    if (couponIds.length <= 0) {
      couponIds.push(uniqid());
    }

    for (const id of couponIds) {
      const coupon = coupons[id];
      let code, effectType, amount, couponUseType, couponUses, disabled;
      if (coupon) {
        const { effect, lifetime } = coupon;
        effectType = effect.type;
        code = coupon.code;
        disabled = coupon.disabled;
        couponUseType = lifetime.type;
        if (couponUseType !== "permanent") {
          couponUses = lifetime.uses || 1;
          lifetime.uses = couponUses;
        }
        amount = parseFloat(effect.factor);
      }

      couponBlocks.push(
        <EventBlock
          formBlock="coupon"
          handleFormBlockRemoval={() => this.props.handleBlockRemoval({id, coupon})}
          numberOfFormBlocks={couponIds.length}
          key={id}
          name={code}
          emptyBlock={!code && !amount && !effectType}
        >
          <AdminInput
            field="code"
            fieldName="Coupon Code"
            type="text"
            onChange={this.props.handleBlockChange}
            width={couponUses ? "3/12" : "5/12"}
            id={id}
            validateField={validateCouponCode}
            helpText={this.couponCodeHelpText()}
            value={code || ""}
            required={true}
            disabled={disabled}
            displayError={displayErrors}
          />
          <AdminInput
            field="amount"
            fieldName="Value"
            type="number"
            onChange={this.props.handleBlockChange}
            width="2/12"
            id={id}
            value={String(amount)}
            required={true}
            displayError={displayErrors}
          />
          {this.renderAmountTypeSelection(id, effectType)}
          {this.renderCouponTypeSelection(id, couponUseType)}
          {couponUses && this.renderCouponUses(id, couponUses)}
        </EventBlock>
      );
    }
    return couponBlocks;
  }
}

EventCoupons.propTypes = {
  handleBlockChange: PropTypes.func,
  handleBlockRemoval: PropTypes.func,
  coupons: PropTypes.object,
  couponIds: PropTypes.array,
  displayErrors: PropTypes.bool
};

export default EventCoupons;
