import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class Button extends React.Component {
  render() {
    const {
      label,
      path,
      isLink,
      disabled,
      buttonStyle,
      data = []
    } = this.props;
    const extraClass = this.props.extraClass ? this.props.extraClass : "";
    let styles;
    switch (buttonStyle) {
      case "secondary":
        styles = "secondary-bg";
        break;
      case "secondary-outline":
        styles = "bg-white border border-gray-300 hover:border-gray-400";
        break;
      case "gray":
        styles = "bg-gray-300 hover:bg-gray-400";
        break;
      case "warning":
        styles = "bg-red-500 hover:bg-red-600";
        break;
      default:
        styles = "primary-bg";
    }

    const classes = `p-3 m-3 text-center -button rounded font-semibold tracking-wide ${extraClass} ${
      disabled ? "bg-gray-300 pointer-events-none h-12" : styles
    }`;

    const dataProps = {};
    data.forEach(item => {
      dataProps[item.label] = item.value;
    });

    if (isLink) {
      return (
        <Link className={classes} to={path}>
          {label}
        </Link>
      );
    }
    return (
      <button
        className={classes}
        onClick={this.props.onClick}
        disabled={disabled}
        {...dataProps}
      >
        {label}
      </button>
    );
  }
}

Button.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  extraClass: PropTypes.string,
  path: PropTypes.string,
  isLink: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  buttonStyle: PropTypes.string,
  data: PropTypes.array
};

export default Button;
