import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faUpload } from "@fortawesome/free-solid-svg-icons";
import Button from "../../core/Button";

import ImageUploading from "react-images-uploading";

export function ImageUpload({
  images,
  onImageChange,
  singleImage,
  removeImage,
  alt
}) {
  const maxNumber = 10;

  const onChange = imageList => {
    const newAndOldImages = images ? images.concat(imageList) : imageList;
    onImageChange(newAndOldImages);
  };

  return (
    <div className={`w-full border p-2 m-2 ${!images ? "py-10" : ""}`}>
      <ImageUploading
        multiple={singleImage}
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg", "jpeg", "png"]}
        maxFileSize={5242880}
      >
        {({ onImageUpload, onImageRemove }) => (
          <React.Fragment>
            {!(singleImage && images) && (
              <div className="text-center py-3">
                <Button
                  onClick={e => {
                    e.preventDefault();
                    onImageUpload();
                  }}
                  label={
                    <React.Fragment>
                      <FontAwesomeIcon icon={faUpload} /> Upload Image
                    </React.Fragment>
                  }
                  buttonStyle="gray"
                  extraClass="m-auto"
                />
                <p className="pt-3 text-sm">
                  Max file size 5mb. Accepted formats JPG, JPEG, and PNG.
                </p>
              </div>
            )}
            <div className="flex justify-center flex-wrap">
              {images &&
                images.map((image, index) => (
                  <div key={index} className="relative py-3">
                    <img src={image} alt={alt} className="max-w-xs p-2" />
                    <span>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="absolute text-red-600 bg-white rounded-full text-3xl top-0 right-0 cursor-pointer hover:text-red-700"
                        onClick={e => {
                          e.preventDefault();
                          onImageRemove(index);
                          removeImage(e, image);
                        }}
                      />
                    </span>
                  </div>
                ))}
            </div>
          </React.Fragment>
        )}
      </ImageUploading>
    </div>
  );
}

ImageUpload.propTypes = {
  images: PropTypes.array,
  alt: PropTypes.string,
  onImageChange: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  singleImage: PropTypes.bool
};

export default ImageUpload;
