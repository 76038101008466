/* eslint-disable no-undef */
export const theme = {
  NAME: "greenwich",
  TENANT: "pyxu5a",
  LINKS: [
    {
      url: "https://www.greenwichacademy.org/giving",
      label: "Giving"
    }
  ],
  SOCIAL:
    "https://www.facebook.com/gwichacademy https://www.twitter.com/gwichacademy https://www.instagram.com/gwichacademy/",
  HEADING_1: "Greenwich Academy",
  HEADING_2: "A Ticket Marketplace for"
};
