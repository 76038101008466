import React from "react";

import TableContainer from "../../core/TableContainer";
import Content from "./Content";

class OrdersDashboard extends React.Component {
  renderHeading() {
    return (
      <div className="flex items-center flex-row mx-0 p-1">
        <h1 className="text-3xl mr-auto font-bold px-3">Orders</h1>
      </div>
    );
  }

  render() {
    return (
      <TableContainer paginationWidth="full" isAdmin={true} dataType="orders">
        <Content />
      </TableContainer>
    );
  }
}

export default OrdersDashboard;
