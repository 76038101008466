import React, { useEffect } from "react";

import { connect } from "react-redux";
import { useAuth0 } from "../../react-auth0-spa";

const Login = connect(
    state => ({ marketplace: state.apis.marketplace }))(
        props => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getTokenSilently
  } = useAuth0();

  const getAndSetToken = async () => {
    const token = await getTokenSilently();
    props.marketplace.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  useEffect(() => {
    if (user) {
      getAndSetToken();
    }
    // eslint-disable-next-line
  }, [user]);

  let button;
  if (!isAuthenticated) {
    button = (
      <button
        className={props.buttonClasses}
        onClick={() => loginWithRedirect({})}
      >
        Log in
      </button>
    );
  } else {
    button = (
      <button
        className={props.buttonClasses}
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        Log out
      </button>
    );
  }

  return button;
});

export default Login;
