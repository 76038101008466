/* eslint-disable global-require */
import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import theme from "../../theme";
import SearchBar from "../core/SearchBar";
import Button from "../core/Button";
import Highlights from "./Highlights";
import Header from "../core/Header";
import Footer from "../core/Footer";

import { getHighlights } from "../../ducks/highlights.js";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export class Landing extends React.Component {
  componentDidMount() {
    this.props.getHighlights(
      moment()
        .utc()
        .format()
    );
  }

  render() {
    const heading_large = theme.HEADING_1;
    const heading_small = theme.HEADING_2;
    const hideSearchbar = theme.HIDESEARCHBAR;
    const themeName = theme.NAME;

    return (
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="p-3 flex-grow flex bg-gray-100 text-gray-800 relative">
          <LazyLoadImage
            alt="background"
            wrapperClassName="absolute h-full w-full top-0 left-0 overflow-hidden"
            effect="blur"
            src={require(`../../styles/themes/${themeName}/home-bg.jpg`)}
          />

          <div className="primary-bg absolute h-full w-full top-0 left-0 z-0 opacity-75" />
          <div className="container mx-auto flex flex-col justify-center items-center mr-3 ml-3 sm:mr-auto sm:ml-auto z-10 mt-10">
            <h1 className="primary-text text-2xl md:text-4xl text-center font-bold">
              {heading_small}
              <span className="uppercase tracking-wide text-3xl md:text-5xl block">
                {heading_large}
              </span>
            </h1>
            {!hideSearchbar && (
              <React.Fragment>
                <SearchBar placeholder="Search Events" pathname="/events" />
                <Button
                  label="View All Events"
                  path="/events"
                  isLink={true}
                  extraClass="accent-bg"
                />
              </React.Fragment>
            )}
            <Highlights highlights={this.props.highlights} />
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    highlights: state.highlights
  };
};

export default connect(mapStateToProps, { getHighlights })(Landing);
