const REQUEST = "marketplace/claim/REQUEST";
const SUCCESS = "marketplace/claim/SUCCESS";
const FAILURE = "marketplace/claim/FAILURE";

const initialState = {};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case SUCCESS:
      return { ...state, isFetching: false, payload: action.payload };
    case FAILURE:
      return { ...state, isFetching: false, error: action.payload };
    default:
      return state;
  }
}

export const createClaim = (eventId, data) => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .post(`/events/${eventId}/claims`, data)
    .then(results => dispatch({ type: SUCCESS, payload: results }))
    .catch(error =>
      dispatch({ type: FAILURE, payload: error.response.data, error: true })
    );
};
