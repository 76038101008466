import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ConfirmationModal from "../../core/ConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faBan,
  faCopy,
  faTrash,
  faExternalLinkAlt,
  faThList
} from "@fortawesome/free-solid-svg-icons";
import { setCopyEventId } from "../../../ducks/admin/setCopyEventId";

const INITIAL_STATE = {
  modal: {
    display: false,
    heading: undefined,
    body: undefined,
    actionText: undefined,
    action: undefined
  }
};

class EventMenuItems extends React.Component {
  state = INITIAL_STATE;

  handleCancelEvent = e => {
    e.preventDefault();
    this.props.cancelEvent(this.props.link);
  };

  handleDeleteEvent = e => {
    e.preventDefault();
    this.props.deleteEvent(this.props.link);
  };

  showModal = action => {
    switch (action) {
      case "CANCEL":
        this.setState({
          modal: {
            display: true,
            heading: "Are you sure you'd like to cancel this event?",
            body: "You can make the event live again at any time.",
            actionText: "Yes, CANCEL",
            action: this.handleCancelEvent
          }
        });
        break;
      case "DELETE":
        this.setState({
          modal: {
            display: true,
            heading: "Are you sure you'd like to delete this event?",
            body: "This action cannot be undone.",
            actionText: "Yes, DELETE",
            action: this.handleDeleteEvent
          }
        });
        break;
      default:
    }
  };

  hideModal = () => {
    this.setState({ modal: { display: false } });
  };

  setCopyEventId = () => {
    const { link } = this.props;
    this.props.setCopyEventId(link);
    this.props.history.push("/admin/events/copy");
  };

  render() {
    const { edit, link, isPublished, current } = this.props;
    const { modal } = this.state;
    let editEvent;

    if (!edit && current) {
      editEvent = (
        <Link to={`/admin/events/${link}`}>
          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer whitespace-no-wrap">
            <FontAwesomeIcon icon={faEdit} /> Edit
          </li>
        </Link>
      );
    }

    return (
      <React.Fragment>
        {editEvent}
        {modal.display && (
          <ConfirmationModal
            heading={modal.heading}
            body={modal.body}
            actionText={modal.actionText}
            cancel={this.hideModal}
            action={modal.action}
          />
        )}
        <li
          className="px-4 py-2 hover:bg-gray-100 cursor-pointer whitespace-no-wrap"
          onClick={this.setCopyEventId}
        >
          <FontAwesomeIcon icon={faCopy} /> Copy
        </li>
        {isPublished && current && (
          <li
            className="px-4 py-2 hover:bg-gray-100 cursor-pointer whitespace-no-wrap"
            onClick={() => this.showModal("CANCEL")}
          >
            <FontAwesomeIcon icon={faBan} /> Cancel
          </li>
        )}
        {current && (
          <li
            className="px-4 py-2 hover:bg-gray-100 cursor-pointer whitespace-no-wrap"
            onClick={() => this.showModal("DELETE")}
          >
            <FontAwesomeIcon icon={faTrash} /> Delete
          </li>
        )}
        {isPublished && current && (
          <Link to={`/events/${link}`} target="_blank">
            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer whitespace-no-wrap">
              <FontAwesomeIcon icon={faExternalLinkAlt} /> View
            </li>
          </Link>
        )}
        <Link to={`/admin/orders?event=${link}`}>
          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer whitespace-no-wrap">
            <FontAwesomeIcon icon={faThList} /> Orders
          </li>
        </Link>
      </React.Fragment>
    );
  }
}

EventMenuItems.propTypes = {
  edit: PropTypes.bool,
  link: PropTypes.string,
  setCopyEventId: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  cancelEvent: PropTypes.func,
  deleteEvent: PropTypes.func,
  isPublished: PropTypes.bool,
  current: PropTypes.bool.isRequired
};

export default withRouter(connect(null, { setCopyEventId })(EventMenuItems));
