import React from "react";
import PropTypes from "prop-types";

class FormHeading extends React.Component {
  render() {
    return (
      <div
        className="flex items-center border-b-2 text-xl p-4 font-bold"
        data-testid="form-heading"
      >
        <span className="primary-bg w-10 h-10 text-center rounded-full flex justify-center items-center mr-3">
          {this.props.number}
        </span>{" "}
        {this.props.heading}
      </div>
    );
  }
}

FormHeading.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  heading: PropTypes.string.isRequired
};

export default FormHeading;
