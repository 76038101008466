import React from "react";
import PropTypes from "prop-types";
import AdminInput from "../core/AdminInput";
import CheckboxInput from "../../core/CheckboxInput";
import AdminTextarea from "../core/AdminTextarea";
import EventBlock from "./EventBlock";
import Variations from "./EventExtrasVariations";
import ImageUpload from "../core/ImageUpload";
import { connect } from "react-redux";

import { validateFieldLength } from "../../../utils";
import { validateEventForm } from "../../../ducks/admin/eventForm";
import { updateExtra } from "../../../ducks/admin/event";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";

const INITIAL_STATE = {
  hasVariations: false,
  variations: {}
};

class EventExtra extends React.Component {
  state = INITIAL_STATE;

  componentDidMount() {
    const { extra } = this.props;
    if (extra) {
      const { variations } = extra;
      this.setState({
        variations,
        hasVariations: variations ? true : false
      });
    }
  }

  onImageChange = images => {
    const { id } = this.props;
    this.props.handleImageChange(images, id);
  };

  removeImage = (e, src) => {
    const { extra, id } = this.props;
    const { images } = extra;
    const filteredImages = images.filter(image => image !== src);
    this.props.handleImageChange(filteredImages, id);
  };

  toggleVariations = event => {
    const { checked, id } = event.target;
    const { variations } = this.state;
    const { slug } = this.props;
    if (!checked) {
      // if toggling back to no variations,
      // remove variation fields from form validation
      const { eventFormErrors } = this.props;
      const variationFieldErrors = eventFormErrors.filter(field =>
        field.includes(`${id}-variation`)
      );
      for (const field of variationFieldErrors) {
        this.props.validateEventForm(false, field);
      }
      // when variations is unchecked, the variations for that extra need to be
      // archived. the top-level extra needs to change it's name to it's heading
      // and then unset the heading field.
      const archival = [{ op: "replace", path: "/status", value: "archived" }];

      for (const key in variations) {
        if (key === id) {
          const removeHeading = [
            { op: "remove", path: "/heading" },
            { op: "replace", path: "/name", value: variations[key].heading }
          ];
          this.props.updateExtra(slug, id, removeHeading);
        } else {
          this.props.updateExtra(slug, key, archival);
        }
      }
    }
    this.setState({
      hasVariations: checked,
      expanded: checked
    });
  };

  toggleVariationsDisplay = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded
    }));
  };

  renderQuantity(id, quantity) {
    const { displayErrors } = this.props;
    return (
      <AdminInput
        field="totalQuantity"
        fieldName="Quantity"
        type="number"
        onChange={this.props.handleBlockChange}
        width="1/4"
        id={id}
        value={String(quantity)}
        displayError={displayErrors}
        helpText="Leave blank to give this item an unlimited quantity."
      />
    );
  }

  renderVariationsBlock(id, variations) {
    const expanded = this.state.expanded;
    const {
      eventFormErrors,
      displayErrors,
      handleBlockChange,
      handleBlockRemoval
    } = this.props;
    const hasErrors = eventFormErrors.some(field =>
      field.includes(`${id}-variation`)
    );

    return (
      <div className="w-full p-2 mt-5">
        <div className={`flex ${expanded ? "" : "border-b"}`}>
          <p className="mb-2">Variations </p>
          <div
            className="cursor-pointer px-2 cursor-pointer"
            onClick={() => this.toggleVariationsDisplay(id)}
          >
            <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
          </div>
          {displayErrors && hasErrors && (
            <span className="text-xs italic text-red-500 mt-1">
              Please fill out all required fields in this section.
            </span>
          )}
        </div>
        <Variations
          variations={variations}
          extraId={id}
          displayErrors={displayErrors}
          handleBlockChange={handleBlockChange}
          handleBlockRemoval={handleBlockRemoval}
          hidden={!expanded}
        />
      </div>
    );
  }

  handleExtraRemoval = () => {
    const { extra, id } = this.props;
    if (extra.variations) {
      for (const key in extra.variations) {
        this.props.handleBlockRemoval(key);
      }
    } else {
      this.props.handleBlockRemoval(id);
    }
  };

  renderExtraImages = () => {
    const photos = [];
    const { extra } = this.props;
    if (extra.images) {
      for (const image of extra.images) {
        photos.push(
          <div className="relative" key={image}>
            <img src={image} alt={extra.name} className="max-w-xs p-2" />
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="absolute text-red-600 bg-white rounded-full text-3xl top-0 right-0 cursor-pointer"
              data-src={image}
              onClick={this.removeImage}
            />
          </div>
        );
      }
    }
    return <div className="flex justify-center flex-wrap">{photos}</div>;
  };

  render() {
    const itemBlocks = [];
    const { displayErrors, numberOfFormBlocks, id } = this.props;
    const item = this.props.extra;
    const hasVariations = this.state.hasVariations;

    let name, heading, price, images, description, quantity, variations, status;
    if (item) {
      name = item.name;
      heading = item.heading;
      price = item.price && item.price.replace("USD ", "");
      images = item.images;
      description = item.description;
      quantity = item.totalQuantity;
      variations = item.variations;
      status = item.status;
    }

    if (status !== "archived") {
      itemBlocks.push(
        <EventBlock
          formBlock="Extra"
          handleFormBlockRemoval={() => this.props.handleBlockRemoval(id)}
          numberOfFormBlocks={numberOfFormBlocks}
          key={id}
          name={heading ? heading : name}
          emptyBlock={!name && !price}
        >
          <AdminInput
            field={heading ? "heading" : "name"}
            fieldName="Item Name"
            type="text"
            onChange={this.props.handleBlockChange}
            width="3/4"
            id={id}
            value={heading ? heading : name || ""}
            required={true}
            displayError={displayErrors}
            validateField={validateFieldLength}
          />
          <AdminInput
            field="price"
            fieldName="Price"
            type="number"
            onChange={this.props.handleBlockChange}
            width="1/4"
            id={id}
            value={price || ""}
            required={true}
            displayError={displayErrors}
          />
          <p className="p-2 pb-0 w-full">Event Image</p>
          <ImageUpload
            images={images}
            onImageChange={this.onImageChange}
            removeImage={this.removeImage}
            alt={name}
          />
          <AdminTextarea
            label="Description"
            field="description"
            onChange={this.props.handleBlockChange}
            containerClass="w-full p-2"
            id={id}
            value={description || ""}
            collapsible={true}
            validateField={validateFieldLength}
            maxLength={5000}
          />
          <div className="w-full flex">
            <div className="w-3/4 p-2 flex items-end">
              <CheckboxInput
                fieldName="hasVariations"
                fieldState={hasVariations}
                options={[{ field: id, label: "Yes" }]}
                label="Does this item have variations?"
                helpText="Variations apply to items offered in different sizes, colors, etc."
                handleInputChange={this.toggleVariations}
                bordered={true}
              />
            </div>
            {hasVariations ? null : this.renderQuantity(id, quantity)}
          </div>
          {hasVariations ? this.renderVariationsBlock(id, variations) : null}
        </EventBlock>
      );
    }

    return itemBlocks;
  }
}

EventExtra.propTypes = {
  extra: PropTypes.object,
  handleBlockChange: PropTypes.func,
  handleBlockRemoval: PropTypes.func,
  handleImageChange: PropTypes.func,
  numberOfFormBlocks: PropTypes.number.isRequired,
  displayErrors: PropTypes.bool,
  eventFormErrors: PropTypes.array.isRequired,
  validateEventForm: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  updateExtra: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    eventFormErrors: state.admin.eventFormErrors
  };
};

export default connect(mapStateToProps, { validateEventForm, updateExtra })(
  EventExtra
);
