import React from "react";
import PropTypes from "prop-types";
import Modal from "../core/Modal";
import Button from "../core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import {
  faGoogle,
  faApple,
  faMicrosoft
} from "@fortawesome/free-brands-svg-icons";
import ICalendarLink from "react-icalendar-link";

const icons = {
  Google: faGoogle,
  iCal: faApple,
  Outlook: faMicrosoft
};

export class CalendarModal extends React.Component {
  isIcsLink(type) {
    // iCal and Outlook use .ics files instead of links, user downloads the .ics
    // file and then imports it into iCal or Outlook
    // react-add-to-calendar-hoc plugin should generate the proper .ics file but
    // it's not working for some reason, so react-icalendar-link is used instead
    return type === "iCal" || type === "Outlook";
  }

  renderIcon(type) {
    return (
      <FontAwesomeIcon
        icon={icons[type] ? icons[type] : faCalendar}
        className="primary-color mr-3"
      />
    );
  }

  renderLinks() {
    const links = [];
    const { children, event } = this.props;
    for (const [index, link] of children.entries()) {
      links.push(
        <div
          className={`text-xl text-center font-bold py-3 hover:text-gray-600 ${
            index > 0 ? "border-t" : ""
          }`}
          key={link.key}
        >
          {this.isIcsLink(link.key) ? (
            <ICalendarLink event={event}>
              {this.renderIcon(link.key)} {link.key}
            </ICalendarLink>
          ) : (
            <a href={link.props.href} target="_blank" rel="noreferrer noopener">
              {this.renderIcon(link.key)} {link.key}
            </a>
          )}
        </div>
      );
    }
    return links;
  }

  render() {
    return (
      <Modal>
        <div className="flex flex-col w-full">{this.renderLinks()}</div>
        <Button
          label="Cancel"
          extraClass="min-w-32"
          onClick={e => this.props.onRequestClose(e)}
          buttonStyle="gray"
        />
      </Modal>
    );
  }
}

CalendarModal.propTypes = {
  children: PropTypes.array.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired
};

export default CalendarModal;
