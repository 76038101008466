import React from "react";
import PropTypes from "prop-types";
import Input from "../core/Input";
import CheckboxInput from "../core/CheckboxInput";

const INITIAL_STATE = { shippingSameAsBilling: true };

class FormInputs extends React.Component {
  state = INITIAL_STATE;

  renderAddress({ addressLabel, prefix }) {
    if (!this.props.waitlist) {
      return (
        <React.Fragment>
          <div
            className={`flex flex-wrap ${
              this.props.disabled ? "opacity-50" : ""
            }`}
          >
            <Input
              type="text"
              containerClass="w-full md:flex-1 md:pr-3 py-2 font-bold"
              label={addressLabel}
              field={`${prefix}_address_line1`}
              onChange={this.props.onChange}
              required={true}
              disabled={this.props.disabled}
            />
            <Input
              type="text"
              containerClass="w-full md:flex-1 py-2"
              label="Address Continued"
              field={`${prefix}_address_line2`}
              onChange={this.props.onChange}
              disabled={this.props.disabled}
            />
          </div>

          <div
            className={`flex flex-wrap ${
              this.props.disabled ? "opacity-50" : ""
            } ${
              this.props.selectedTickets.shippingAddressRequired ? "" : "mb-2"
            }`}
          >
            <Input
              type="text"
              containerClass="w-full md:flex-1 md:pr-3 py-2"
              label="City"
              field={`${prefix}_address_city`}
              onChange={this.props.onChange}
              required={true}
              disabled={this.props.disabled}
            />
            <Input
              type="text"
              containerClass="w-1/2 md:flex-1 pr-3 py-2"
              label="State"
              field={`${prefix}_address_state`}
              onChange={this.props.onChange}
              required={true}
              disabled={this.props.disabled}
            />
            <Input
              type="number"
              containerClass="w-1/2 md:flex-1 py-2"
              label="Zip Code"
              field={`${prefix}_address_zip`}
              onChange={this.props.onChange}
              required={true}
              disabled={this.props.disabled}
            />
          </div>
        </React.Fragment>
      );
    }
    return null;
  }

  renderShippingAddress() {
    return <>
      <CheckboxInput
        fieldName="shippingSameAsBilling"
        fieldState={this.props.shippingSameAsBilling}
        handleInputChange={this.props.handleCheckboxChange}
        options={[{ field: "shippingSameAsBilling", label: "Yes" }]}
        label="Is the shipping address the same as the billing address?"
        containerClass={"py-4 px-0"}
      />
      {!this.props.shippingSameAsBilling && this.renderAddress({
        addressLabel: "Shipping Address",
        prefix: "shipping"
      })}
    </>
  }

  render() {
    const {
      onChange,
      disabled,
      selectedTickets,
      waitlist
    } = this.props;
    return (
      <React.Fragment>
        <div
          className={`flex flex-wrap border-b mb-2 ${
            disabled ? "opacity-50" : ""
          } ${waitlist ? "mb-4" : ""}`}
        >
          <Input
            type="text"
            containerClass="w-full md:flex-1 md:pr-3 pt-2 pb-4 font-bold"
            label="Name"
            field="name"
            onChange={onChange}
            required={true}
            disabled={disabled}
          />
          <Input
            type="email"
            containerClass="w-full md:flex-1 pt-2 pb-4 font-bold"
            label="Email"
            field="email"
            onChange={onChange}
            required={true}
            disabled={disabled}
          />
        </div>
        {this.renderAddress({ addressLabel: "Billing Address", prefix: "billing" })}
        {selectedTickets.shippingAddressRequired && this.renderShippingAddress()}
      </React.Fragment>
    );
  }
}

FormInputs.propTypes = {
  onChange: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selectedTickets: PropTypes.object.isRequired,
  shippingSameAsBilling: PropTypes.bool.isRequired,
  waitlist: PropTypes.bool
};

export default FormInputs;
