import React from "react";
import PropTypes from "prop-types";

import AdminInput from "../core/AdminInput";
import AdminSelect from "../core/AdminSelect";
import EventBlock from "./EventBlock";

import uniqid from "uniqid";
import { scrollToTop } from "../../../utils";

const INITIAL_STATE = {
  options: []
};

class EventRestrictions extends React.Component {
  state = INITIAL_STATE;

  componentDidMount() {
    scrollToTop();

    const options = [];
    const { sections } = this.props;
    for (const [sectionKey, sectionData] of Object.entries(sections)) {
      options.push({ value: sectionKey, label: sectionData.name });
    }
    this.setState({ options });
  }

  setupSectionOptions(restrictions, options) {
    let selected = [];
    /* eslint-disable-next-line no-unused-vars */
    for (const [key, restriction] of Object.entries(restrictions)) {
      if (restriction.sections) {
        selected = [...selected, ...restriction.sections];
      }
    }

    for (const option of options) {
      option.isDisabled = selected.includes(option.value);
    }

    return options;
  }

  onSelectChange(e, id) {
    const selectedSectionIds = [];
    for (const item of e) {
      selectedSectionIds.push(item.value);
    }

    const event = {
      target: { name: "sections", value: selectedSectionIds, dataset: { id } }
    };
    this.props.handleBlockChange(event);
  }

  renderSectionSelection(id, selectedSections) {
    const { displayErrors, restrictions } = this.props;
    const options = this.setupSectionOptions(restrictions, this.state.options);

    let selectedOptions = [];
    if (selectedSections) {
      selectedOptions = options.filter(element =>
        selectedSections.includes(element.value)
      );
    }

    return (
      <AdminSelect
        options={options}
        field="sections"
        fieldName="Included Sections"
        width="2/3"
        onChange={e => this.onSelectChange(e, id)}
        id={id}
        value={selectedOptions}
        isMulti={true}
        required={true}
        displayError={displayErrors}
      />
    );
  }

  render() {
    const restrictionBlocks = [];
    const { restrictions, displayErrors } = this.props;
    const { restrictionIds } = this.props;
    if (restrictionIds.length <= 0) {
      restrictionIds.push(uniqid());
    }

    for (let i = 0; i < restrictionIds.length; i++) {
      const id = restrictionIds[i];
      const restriction = restrictions[restrictionIds[i]];

      let limit, sections;

      if (restriction) {
        limit = restriction.limit;
        sections = restriction.sections;
      }

      restrictionBlocks.push(
        <EventBlock
          formBlock="Restriction"
          handleFormBlockRemoval={() => this.props.handleBlockRemoval(id)}
          numberOfFormBlocks={restrictionIds.length}
          key={id}
          name={`the ${limit} Ticket Limit`}
          emptyBlock={!limit && !sections}
        >
          <AdminInput
            field="limit"
            fieldName="Ticket Limit Per Order"
            type="number"
            onChange={this.props.handleBlockChange}
            width="1/3"
            id={id}
            value={limit || ""}
            required={true}
            displayError={displayErrors}
          />
          {this.renderSectionSelection(id, sections)}
        </EventBlock>
      );
    }
    return restrictionBlocks;
  }
}

EventRestrictions.propTypes = {
  restrictions: PropTypes.object.isRequired,
  sections: PropTypes.object.isRequired,
  displayErrors: PropTypes.bool,
  handleBlockChange: PropTypes.func,
  handleBlockRemoval: PropTypes.func,
  restrictionIds: PropTypes.array
};

export default EventRestrictions;
