import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import App from "./components/App";
import reducers from "./ducks";
import Rollbar from "rollbar";
import "./styles/index.css";
import marketplace from "./apis/marketplace";

import { Auth0Provider } from "./react-auth0-spa";
import { RollbarContext } from "./rollbar-context";

import config from "./auth_config.json";
import history from "./utils/history";

/* eslint-disable no-undef */
require(`./styles/themes/${process.env.REACT_APP_THEME}/theme.scss`);

const store = createStore(reducers, {
  apis: {
    marketplace
  }
}, applyMiddleware(thunk));

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    responseType={"id_token token"}
  >
    <RollbarContext.Provider value={
      new Rollbar({
        accessToken: "4088a41508e34d1aa8d01ac011043317",
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: process.env.REACT_APP_STAGE
        }
      })
    }>
      <Provider store={store}>
        <App />
      </Provider>
    </RollbarContext.Provider>
  </Auth0Provider>,
  document.querySelector("#root")
);
