import React from "react";
import PropTypes from "prop-types";
import Modal from "../../core/Modal";
import Button from "../../core/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";

export class FeeModal extends React.Component {
  render() {
    const { heading, cancel, body } = this.props;
    return (
      <Modal>
        <FontAwesomeIcon icon={faDollarSign} className="fa-2x primary-color" />
        <p className="w-full text-center my-6">
          <span className="text-xl font-bold">{heading}</span>
          <br />
          <span className="text-lg">{body}</span>
        </p>
        <Button
          label="Close"
          extraClass="min-w-32"
          onClick={cancel}
          buttonStyle="gray"
        />
      </Modal>
    );
  }
}

FeeModal.propTypes = {
  heading: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  body: PropTypes.string.isRequired
};

export default FeeModal;
