import React from "react";
import EventsTable from "./EventsTable";

class EventsLoading extends React.Component {
  render() {
    /* eslint-disable no-magic-numbers */
    const data = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    return <EventsTable resolvedData={data} disabled={true} />;
  }
}

export default EventsLoading;
