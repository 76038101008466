import React from "react";
import MainContainer from "../../core/MainContainer";
import EventForm from "./EventForm";
import EventButtons from "./EventButtons";
import ActionButton from "../Events/ActionButton";
import ProgressBar from "./ProgressBar";
import Loader from "../../core/Loader";
import PropTypes from "prop-types";

import { eventStatus } from "../../../utils";

const INITIAL_STATE = {
  displayActionMenu: false
};

class Event extends React.Component {
  state = INITIAL_STATE;

  renderForm() {
    const {
      formState,
      handleBlockChange,
      handleBlockRemoval,
      handleStartDateInputChange,
      handleStartTimeInputChange,
      handleEndDateInputChange,
      handleEndTimeInputChange,
      handleInputChange,
      handleCheckboxChange,
      handleImageChange,
      handleNewBlockClick
    } = this.props;
    return (
      <EventForm
        formState={formState}
        handleBlockChange={handleBlockChange}
        handleBlockRemoval={handleBlockRemoval}
        handleNewBlockClick={handleNewBlockClick}
        handleStartDateInputChange={handleStartDateInputChange}
        handleStartTimeInputChange={handleStartTimeInputChange}
        handleEndDateInputChange={handleEndDateInputChange}
        handleEndTimeInputChange={handleEndTimeInputChange}
        handleInputChange={handleInputChange}
        handleCheckboxChange={handleCheckboxChange}
        handleImageChange={handleImageChange}
      />
    );
  }

  renderButtons() {
    const {
      formState,
      handlePrevious,
      eventResponse,
      exitEvent,
      handleNext,
      submit
    } = this.props;
    const { currentBlock, lastStep, availability, visibility } = formState;
    this.status = eventStatus(availability, visibility);

    return (
      <EventButtons
        currentBlock={currentBlock}
        lastStep={lastStep}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        isFetching={eventResponse.isFetching}
        exitEvent={exitEvent}
        submit={submit}
        status={this.status}
      />
    );
  }

  render() {
    const { submit, formState, deleteEvent, cancelEvent } = this.props;
    const {
      newEvent,
      isCopied,
      numberOfSteps,
      currentStepNumber,
      slug,
      loading
    } = formState;

    return (
      <MainContainer
        isAdmin={true}
        extraClass={`py-3 ${!loading && "self-start"}`}
      >
        {loading ? (
          <Loader />
        ) : (
          <form
            className="w-full lg:w-3/4 xl:w-7/12 m-auto"
            onSubmit={submit}
            noValidate
          >
            <div className="flex justify-between items-center mr-3">
              <h1 className="text-3xl font-bold px-3 inline">
                {newEvent ? "New" : isCopied ? "Copy" : "Edit"} Event
              </h1>
              <div className="hidden sm:flex flex-grow items-center pl-3">
                <ProgressBar
                  numberOfSteps={numberOfSteps}
                  currentStepNumber={currentStepNumber}
                />
              </div>
              {!newEvent && !isCopied && (
                <ActionButton
                  cancelEvent={cancelEvent}
                  deleteEvent={deleteEvent}
                  display={this.state.displayActionMenu}
                  edit={true}
                  link={slug || ""}
                  isPublished={this.status === "Published"}
                  current={true}
                />
              )}
            </div>

            <div className="sm:hidden flex flex-grow items-center m-3">
              <ProgressBar
                numberOfSteps={numberOfSteps}
                currentStepNumber={currentStepNumber}
              />
            </div>

            {this.renderForm()}
            {this.renderButtons()}
          </form>
        )}
      </MainContainer>
    );
  }
}

Event.propTypes = {
  formState: PropTypes.object.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  handleBlockChange: PropTypes.func.isRequired,
  handleStartDateInputChange: PropTypes.func.isRequired,
  handleStartTimeInputChange: PropTypes.func.isRequired,
  handleEndDateInputChange: PropTypes.func.isRequired,
  handleEndTimeInputChange: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleBlockRemoval: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleNewBlockClick: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  exitEvent: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  eventResponse: PropTypes.object.isRequired,
  cancelEvent: PropTypes.func.isRequired
};

export default Event;
