/* eslint-disable no-undef */
const themeName = process.env.REACT_APP_THEME;

const { theme } = require(`./styles/themes/${themeName}/config.js`);

const FAVICON_URL = require(`./styles/themes/${themeName}/favicon.ico`);
const LOGO_SRC = require(`./styles/themes/${themeName}/logo.png`);

let result;
if (window.isfeConfig) {
    result = window.isfeConfig.theme;
}
else {
    result = {
        ...theme,
        FAVICON_URL,
        LOGO_SRC
    };
}

export default result;
