import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faArrowRight,
  faArrowLeft,
  faSave,
  faCalendarCheck
} from "@fortawesome/free-solid-svg-icons";

import Button from "../../core/Button";

class EventButtons extends React.Component {
  renderBackButton() {
    return (
      <Button
        label={
          <React.Fragment>
            <FontAwesomeIcon icon={faArrowLeft} className="fa-sm" /> Back
          </React.Fragment>
        }
        extraClass="w-full sm:w-1/3"
        onClick={this.props.handlePrevious}
        disabled={this.props.isFetching}
        buttonStyle="secondary-outline"
      />
    );
  }

  renderNextButton() {
    return (
      <Button
        label={
          <React.Fragment>
            Next <FontAwesomeIcon icon={faArrowRight} className="fa-sm" />
          </React.Fragment>
        }
        extraClass="w-full sm:w-1/3"
        onClick={this.props.handleNext}
        disabled={this.props.isFetching}
        buttonStyle="secondary"
      />
    );
  }

  renderExitButton() {
    return (
      <Button
        onClick={this.props.exitEvent}
        label={
          <React.Fragment>
            <FontAwesomeIcon icon={faBan} className="fa-sm" /> Cancel
          </React.Fragment>
        }
        extraClass="w-full sm:w-1/3"
        disabled={this.props.isFetching}
        buttonStyle="gray"
      />
    );
  }

  renderSaveDraftButton() {
    return (
      <Button
        onClick={this.props.submit}
        label={
          <React.Fragment>
            <FontAwesomeIcon icon={faSave} className="fa-sm" />{" "}
            {this.publicEvent ? "Save & Unpublish" : "Save Draft"}
          </React.Fragment>
        }
        extraClass="w-full sm:w-1/3"
        disabled={this.props.isFetching}
        buttonStyle="secondary"
        data={[
          { label: "visibility", value: "ADMIN_ONLY" },
          { label: "availability", value: "NO_CLAIMS" }
        ]}
      />
    );
  }

  renderPublishButton() {
    const { status } = this.props;
    this.publicEvent = status === "Published" || status === "Canceled";
    return (
      <div className="w-full flex">
        <Button
          type="submit"
          label={
            <React.Fragment>
              <FontAwesomeIcon icon={faCalendarCheck} className="fa-sm" />{" "}
              {this.publicEvent ? "Publish Again" : "Publish Live"}
            </React.Fragment>
          }
          extraClass="flex-grow"
          disabled={this.props.isFetching}
        />
      </div>
    );
  }

  renderSpacer() {
    return <div className="w-full sm:w-1/3 sm:m-3" />;
  }

  render() {
    const { currentBlock, lastStep, status } = this.props;
    const isLastStep = currentBlock === lastStep;
    const existingEvent = Boolean(status);

    return (
      <React.Fragment>
        <div className="flex justify-end flex-wrap sm:flex-no-wrap">
          {currentBlock === "info"
            ? this.renderSpacer()
            : this.renderBackButton()}
          {this.renderExitButton()}
          {isLastStep ? this.renderSaveDraftButton() : this.renderNextButton()}
        </div>
        {(isLastStep || existingEvent) && this.renderPublishButton()}
      </React.Fragment>
    );
  }
}

EventButtons.propTypes = {
  currentBlock: PropTypes.string.isRequired,
  lastStep: PropTypes.string.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  exitEvent: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  status: PropTypes.string
};

export default EventButtons;
