/* eslint react/prop-types: 0 */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import Event from "./Event";
import ErrorPage from "../../core/ErrorPage";
import moment from "moment-timezone";
import { scrollToTop, demoEventData } from "../../../utils";
import {
  validateEventForm,
  clearFormErrors
} from "../../../ducks/admin/eventForm";
import {
  updateEvent,
  createEventBlock,
  updateEventBlock,
  createExtra,
  updateExtra
} from "../../../ducks/admin/event";
import {
  updateEventImage,
  clearEventImage
  } from "../../../ducks/admin/images";

import { getEvent } from "../../../ducks/event";
import {
  updateCoupon,
  getCoupons
  } from "../../../ducks/admin/coupons";
const INITIAL_STATE = {
  newEvent: false,
  isCopied: false,
  currentBlock: "info",
  currentStepNumber: 1,
  numberOfSteps: 2,
  lastStep: "sections",
  organization: "sections",
  hasRestrictions: null,
  hasExtras: null,
  shippingAddressRequired: null,
  sections: {},
  pools: {},
  coupons: {},
  restrictions: {},
  extras: {},
  error: {
    display: false,
    message: "",
    block: "sections"
  },
  displayErrors: false,
  exit: false,
  poolIds: [],
  sectionIds: [],
  couponIds: [],
  restrictionIds: [],
  extraIds: [],
  doesNotExist: false,
  sectionPatches: {},
  couponPatches: {},
  extraPatches: {},
  variationPatches: {},
  loading: false,
  featureAdded: false,
  eventType: "inPerson",
  start_date: moment().format("MM/DD/YYYY"),
  start_time: moment()
    .add(1, "hour")
    .startOf("hour")
    .format("HH:mm:ss")
};

const PERCENT_TO_DECIMAL = 0.01;
const DOLLARS_TO_CENTS = 100;

const CURRENCY_DECIMALS = 2;

class EventContainer extends React.Component {
  state = INITIAL_STATE;

  async componentDidMount() {
    await this.setupFormData();
    this.props.clearEventImage();
  }

  async componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    const timeout = 300;

    if (prevProps.match.params.id !== id) {
      // reset progressBar
      this.setState({
        numberOfSteps: 2,
        currentBlock: "info",
        currentStepNumber: 1
      });
      await this.setupFormData();
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false });
      }, timeout);
    }
  }

  componentWillUnmount() {
    this.props.clearFormErrors();
  }

  async setupFormData() {
    const { id } = this.props.match.params;
    const { copiedEventId } = this.props;
    this.existingSections = [];
    this.existingExtras = [];
    if (id === "new") {
      this.setState({ newEvent: true });
    } else if (id === "demo") {
      this.setState({ ...demoEventData });
    } else {
      // set existing event data to state
      const isCopied = id === "copy";
      this.setState({ isCopied });
      const eventId = isCopied ? copiedEventId : id;
      if (isCopied && !eventId) {
        this.setState({ doesNotExist: true });
        return;
      }

      const copiedFromEvent =
        isCopied && copiedEventId === this.props.event.slug;
      if (!copiedFromEvent) {
        const { error } = await this.props.getEvent(eventId);
        if (error) {
          this.setState({ doesNotExist: true });
          return;
        }
      }
      await this.props.getCoupons(eventId);

      const { event, existingCoupons } = this.props;
      const preFormattedCoupons = existingCoupons.coupons || [];
      const { ticketPools, ticketBlocks, extras, perClaimRestrictions } = event;
      ticketBlocks.forEach(item => {
        this.existingSections.push(item.itemId);
      });

      if (extras.length > 0) {
        extras.forEach(item => {
          this.existingExtras.push(item.itemId);
        });
      }

      // update numberOfSteps & organization
      // filter out unlimited ticketBlocks & compare number of pools vs blocks
      // to determine event organization
      // if organized by sections, each section will have its own pool
      const ticketBlocksWithPools = ticketBlocks.filter(
        item => item.poolKey !== undefined
      );
      if (Object.keys(ticketPools).length !== ticketBlocksWithPools.length) {
        this.setState(prevState => ({
          numberOfSteps: prevState.numberOfSteps + 1,
          organization: "pools"
        }));
      }
      if (perClaimRestrictions.length > 0) {
        this.setState(prevState => ({
          numberOfSteps: prevState.numberOfSteps + 1,
          hasRestrictions: true
        }));
      }
      const activeExtras = extras.filter(extra => extra.status === "active");
      if (activeExtras.length > 0) {
        this.setState(prevState => ({
          numberOfSteps: prevState.numberOfSteps + 1,
          hasExtras: true
        }));
      }

      if (preFormattedCoupons.length > 0) {
        this.setState(prevState => ({
          numberOfSteps: prevState.numberOfSteps + 1,
          hasCoupons: true
        }));
      }

      // event Info
      let quantity = 0;
      const image = event.photos && event.photos[0];
      for (const key in ticketPools) {
        if (ticketPools[key].totalTickets) {
          quantity += ticketPools[key].totalTickets;
        }
      }

      const dateAndTime = moment(event.dateAndTime);

      if (event.conferenceUrl && event.venue.address) {
        this.setState({ eventType: "both" });
      } else if (event.conferenceUrl) {
        this.setState({ eventType: "online" });
        this.props.validateEventForm(false, "address");
      } else if (event.venue.address) {
        this.setState({ eventType: "inPerson" });
        this.props.validateEventForm(false, "conferenceUrl");
      }

      this.setState({
        title: event.name,
        slug: isCopied ? "" : event.slug,
        start_date: dateAndTime.format("YYYY-MM-DD"),
        start_time: dateAndTime.format("HH:mm:ss"),
        number_of_tickets: String(quantity),
        venue: event.venue.name,
        address: event.venue.address,
        conferenceUrl: event.conferenceUrl,
        description: event.description,
        availability: isCopied ? null : event.availability,
        visibility: isCopied ? null : event.visibility,
        shippingAddressRequired: event.shippingAddressRequired,
        image: image && image.url,
        highlighted: event.highlighted
      });

      if (event.endDateAndTime) {
        const endDateAndTime = moment(event.endDateAndTime);
        this.setState({
          end_date: endDateAndTime.format("YYYY-MM-DD"),
          end_time: endDateAndTime.format("HH:mm:ss")
        })
      }

      // event Sections
      const sections = {};
      const sectionIds = [];
      for (const section of ticketBlocks) {
        sections[section.itemId] = {
          name: section.sectionName,
          price: section.price && section.price.replace("$", ""),
          quantity: section.poolKey ? section.totalTickets : "",
          pool: section.poolKey,
          description: section.description,
          status: section.status
        };
        sectionIds.push(section.itemId);
      }
      this.setState({ sections, sectionIds });

      // event Pools
      const pools = {};
      const poolIds = [];
      for (const pool in ticketPools) {
        pools[pool] = {
          name: ticketPools[pool].fullName,
          quantity: String(ticketPools[pool].totalTickets)
        };
        poolIds.push(pool);
      }
      this.setState({ pools, poolIds });

      // event Extras
      const existingExtras = {};
      const extraIds = [];
      const headings = {};

      for (const item of extras) {
        const photos = [];
        // if item has no heading, it's a basic extra without variations
        // if item does have heading, it's a variation and we need to group it
        // with other variations under that heading
        if (item.images) {
          for (const image of item.images) {
            photos.push(image.url);
          }
          item.images = photos;
        }

        if (!item.heading) {
          existingExtras[item.itemId] = item;
          extraIds.push(item.itemId);
        } else {
          const headingId = Object.keys(headings).find(
            key => headings[key] === item.heading
          );
          if (headingId) {
            existingExtras[headingId].variations[item.itemId] = item;
          } else {
            headings[item.itemId] = item.heading;
            extraIds.push(item.itemId);
            existingExtras[item.itemId] = {
              variations: { [item.itemId]: item },
              name: item.name,
              heading: item.heading,
              description: item.description,
              images: item.images,
              price: item.price,
              totalQuantity: item.totalQuantity,
              itemId: item.itemId,
              status: item.status
            };
          }
        }
      }
      this.setState({ extras: existingExtras, extraIds });

      // event Restrictions
      const restrictions = {};
      const restrictionIds = [];
      for (const key in perClaimRestrictions) {
        const restriction = perClaimRestrictions[key];
        restrictions[`CR${key}`] = {
          limit: String(restriction.maxCount),
          sections: restriction.includedBlocks
        };
        restrictionIds.push(`CR${key}`);
      }
      this.setState({ restrictions, restrictionIds });

      // event Coupons
      const coupons = {};
      const couponIds = [];
      for (const index in preFormattedCoupons) {
        const { _id, effect, lifetime } = preFormattedCoupons[index];
        if (effect.type === "percentOff") {
          effect.factor /= PERCENT_TO_DECIMAL;
        } else if (effect.type === "fixed") {
          effect.factor /= DOLLARS_TO_CENTS;
        }
        const eventSlug = JSON.parse(_id)[0];
        const code = JSON.parse(_id)[1];
        coupons[`CP${index}`] = {
          effect,
          lifetime,
          code,
          eventSlug,
          disabled: true
        };
        couponIds.push(`CP${index}`);
      }
      this.setState({ coupons, couponIds });
    }
  }

  handleNext = async ev => {
    ev.preventDefault();
    const {
      currentBlock,
      organization,
      hasRestrictions,
      hasExtras,
      hasCoupons,
      slug,
      newEvent,
      isCopied
    } = this.state;

    // prevent submission if any errored fields
    if (this.props.eventFormErrors.length > 0) {
      this.setState({ displayErrors: true });
      return;
    }

    // check that slug does not already exist for new event
    if ((newEvent || isCopied) && currentBlock === "info") {
      const { error } = await this.props.getEvent(slug);
      if (!error) {
        const slugError = {
          display: true,
          message: `An event with the slug "${slug}" already exists. Please choose a unique slug for your event.`,
          block: currentBlock
        };

        this.setState({ error: slugError });
        return;
      }
    }

    this.setState({ error: { display: false } });
    this.setState({ displayErrors: false });

    const validationError = this.validateTicketQuantities();
    if (validationError) {
      return;
    }

    const hasPools = organization === "pools";
    const lastStep = hasCoupons
      ? "coupons"
      : hasRestrictions
      ? "restrictions"
      : hasExtras
      ? "extras"
      : "sections";

    this.props.clearFormErrors();

    this.setState(prevState => ({
      currentStepNumber: prevState.currentStepNumber + 1
    }));

    switch (currentBlock) {
      case "info":
        this.setState({ currentBlock: hasPools ? "pools" : "sections" });
        break;
      case "pools":
        this.setState({ currentBlock: "sections" });
        break;
      case "sections":
        this.setState({
          currentBlock: hasExtras
            ? "extras"
            : hasRestrictions
            ? "restrictions"
            : "coupons"
        });
        break;
      case "extras":
        this.setState({
          currentBlock: hasRestrictions ? "restrictions" : "coupons"
        });
        break;
      case "restrictions":
        this.setState({ currentBlock: "coupons" });
        break;
      default:
        break;
    }

    if (currentBlock === "info") {
      this.setState({ lastStep });
    }
  };

  handlePrevious = ev => {
    ev.preventDefault();
    const {
      currentBlock,
      organization,
      hasExtras,
      hasRestrictions
    } = this.state;
    const hasPools = organization === "pools";

    this.setState(prevState => ({
      currentStepNumber: prevState.currentStepNumber - 1
    }));

    switch (currentBlock) {
      case "pools":
        this.setState({ currentBlock: "info" });
        break;
      case "sections":
        this.setState({ currentBlock: hasPools ? "pools" : "info" });
        break;
      case "extras":
        this.setState({ currentBlock: "sections" });
        break;
      case "restrictions":
        this.setState({ currentBlock: hasExtras ? "extras" : "sections" });
        break;
      case "coupons":
        this.setState({
          currentBlock: hasRestrictions
            ? "restrictions"
            : hasExtras
            ? "extras"
            : "sections"
        });
        break;
      default:
        break;
    }

    this.setState({
      displayErrors: false
    });
    this.props.clearFormErrors();
  };

  updateEventData = async (visibility, availability) => {
    const {
      slug,
      image,
      start_date,
      start_time,
      end_date,
      end_time,
      title,
      eventType,
      venue,
      address,
      shippingAddressRequired,
      conferenceUrl,
      description,
      sections,
      pools,
      organization,
      restrictions,
      newEvent,
      sectionPatches,
      extraPatches,
      highlighted,
      extras,
      hasCoupons,
      hasExtras,
      hasRestrictions,
      coupons
    } = this.state;
    const eventSlug = slug;
    const reformattedStartDate = moment(start_date).format("YYYY-MM-DD");
    const dateTime = moment(`${reformattedStartDate} ${start_time}`)
      .utc()
      .format();
    const timezone = moment.tz.guess();
    const photos = [];
    if (image) {
      photos.push({ url: image, alt: title });
    }
    const eventData = {
      name: title,
      dateAndTime: dateTime,
      venue: { timezone: timezone },
      shippingAddressRequired,
      description: description,
      ticketPools: {},
      perClaimRestrictions: [],
      photos: photos,
      highlighted: highlighted
    };

    if (eventType !== "online") {
      eventData.venue.name = venue;
      eventData.venue.address = address;
    }

    if (eventType !== "inPerson") {
      eventData.conferenceUrl = conferenceUrl;
    }

    const endDateTime = moment(`${end_date} ${end_time}`);
    if (endDateTime.isValid() && endDateTime.isAfter(moment(dateTime))) {
      eventData.endDateAndTime = endDateTime.utc().format();
    }

    if (availability) {
      eventData.availability = availability;
    }
    if (visibility) {
      eventData.visibility = visibility;
    }

    const poolKeys = {};
    const { ticketPools } = this.props.event;
    const preExistingPoolKeys = ticketPools ? Object.keys(ticketPools) : [];

    let keyNumber = 1;
    // noPools means this is either a new event or an event changing organization
    // from pools to sections (pools state is reset when that happens)
    const noPools = Object.entries(pools).length === 0;
    // if organized by sections, we need to create a pool for each section
    const organizedBySections = organization === "sections";
    const makePools = organizedBySections ? sections : pools;
    for (const id in makePools) {
      const pool = makePools[id];
      const unlimitedSection = organizedBySections && !pool.quantity;
      if (!unlimitedSection) {
        // get pool key (pool.pool) assigned to this section if it already exists
        const key = organizedBySections ? pool.pool : id;
        // if new event or new pool, create poolKey
        // adding keyNumber to ensure poolKeys are unique
        const poolKey =
          !preExistingPoolKeys.includes(key) || noPools
            ? /* eslint-disable-next-line */
              (pool.name.slice(0, 2) + keyNumber).toUpperCase()
            : key;
        poolKeys[id] = poolKey;

        if (noPools && !newEvent) {
          const poolPatch = [
            { op: "replace", path: "/poolKey", value: poolKey }
          ];
          this.props.updateEventBlock(slug, id, poolPatch);
        }
        if (pool.quantity) {
          eventData.ticketPools[poolKey] = {
            totalTicketCount: parseInt(pool.quantity, 10),
            fullName: pool.name
          };
        }

        keyNumber += 1;
      }
    }
    // create the event with info + pool data first because
    // event needs to exist before we can create ticketBlocks
    // then get block ids to use for restrictions and
    // update event again
    await this.props.updateEvent(eventData, eventSlug);
    const sectionIds = {};
    for (const key in sections) {
      if (this.existingSections.indexOf(key) < 0) {
        const section = sections[key];
        let poolKey;

        if (organization === "sections" && section.quantity) {
          poolKey = poolKeys[key];
        } else if (organization === "pools" && section.pool) {
          poolKey = poolKeys[section.pool];
        } else {
          poolKey = undefined;
        }

        let price = section.price;
        const priceIsFormattedCorrectly = /^USD [0-9]{1,3}.[0-9]{2}$/.test(
          price
        );
        if (!priceIsFormattedCorrectly) {
          // remove any extra weird characters and reformat
          price = price.replace(/[^0-9.]/g, "");
          price = `USD ${parseFloat(price).toFixed(CURRENCY_DECIMALS)}`;
        }

        const { payload } = await this.props.createEventBlock(
          {
            block: { name: section.name },
            price: price,
            description: section.description,
            poolKey: poolKey
          },
          eventSlug
        );
        // get generated itemId for section
        // so we can use it when setting up restrictions
        sectionIds[key] = payload;
      } else {
        // when creating an event restrictions section doesn't have access to
        // proper section ids, so temporary ids get mapped to real ids after
        // sections are created, for consistency when editing an event we're
        // mapping real id to real id
        sectionIds[key] = key;
      }
    }

    const extraIds = {};
    for (const key in extras) {
      const extra = extras[key];
      let price = extra.price;

      const priceIsFormattedCorrectly = /^USD [0-9]{1,3}.[0-9]{2}$/.test(price);
      if (!priceIsFormattedCorrectly) {
        // remove any extra weird characters and reformat
        price = price.replace(/[^0-9.]/g, "");
        price = `USD ${parseFloat(price).toFixed(CURRENCY_DECIMALS)}`;
      }

      // if it's a basic extra without variations, simply createExtra
      // if it has variations, loop through variations and createExtra for
      // each variation

      const {
        description,
        images = [],
        variations,
        name,
        totalQuantity,
        heading
      } = extra;
      // setup extra photos
      const photos = [];
      if (images) {
        for (const image of images) {
          photos.push({ url: image, alt: name });
        }
      }

      const basicData = { description, images: photos, price };

      if (this.existingExtras.indexOf(key) < 0) {
        if (variations) {
          for (const id in variations) {
            const variation = variations[id];
            const { payload } = await this.props.createExtra(
              {
                ...basicData,
                heading: name,
                name: variation.name,
                totalQuantity: variation.totalQuantity
                  ? parseInt(variation.totalQuantity, 10)
                  : "unlimited"
              },
              eventSlug
            );
            extraIds[key] = payload;
          }
        } else {
          const { payload } = await this.props.createExtra(
            {
              ...basicData,
              name: name,
              totalQuantity: totalQuantity
                ? parseInt(totalQuantity, 10)
                : "unlimited"
            },
            eventSlug
          );
          extraIds[key] = payload;
        }
      } else if (
        this.existingExtras.indexOf(key) >= 0 &&
        extras[key].variations
      ) {
        // extra exists and it has variations
        // if it has no heading, we need to patch name and patch heading

        extraIds[key] = key;
        if (!heading) {
          this.props.updateExtra(eventSlug, key, [
            { op: "replace", path: "/heading", value: name },
            { op: "replace", path: "/name", value: variations[key].name }
          ]);
        }
        for (const id in variations) {
          if (this.existingExtras.indexOf(id) < 0) {
            const variation = variations[id];
            const { payload } = await this.props.createExtra(
              {
                ...basicData,
                heading: heading ? heading : name,
                name: variation.name,
                totalQuantity: variation.totalQuantity
                  ? parseInt(variation.totalQuantity, 10)
                  : "unlimited"
              },
              eventSlug
            );

            extraIds[key] = payload;
          }
        }
        // create new extra
      } else {
        // if it is an existing extra with variations
        // loop through variations to check for new ones
        // that are not in existing variations
        // create new extra for those
        extraIds[key] = key;
      }
    }

    Object.keys(sectionPatches).forEach(id => {
      const patches = [];
      let price = "USD ";

      sectionPatches[id].forEach(field => {
        let path = `/${field}`;
        let newValue = sections[id][field];
        const preExistingPoolKeys = ticketPools ? Object.keys(ticketPools) : [];
        const pools = { ...this.state.pools };
        const poolKey = sections[id].pool ? sections[id].pool : poolKeys[id];

        switch (field) {
          case "quantity":
            // if newValue is undefined, then block has unlimited tickets
            // and any poolKey assigned to it needs to be removed
            // if block changes from unlimited tickets to quantity, new poolKey
            // needs to added via patch
            path =
              !newValue || !preExistingPoolKeys.includes(poolKey)
                ? "/poolKey"
                : undefined;
            newValue = !newValue ? undefined : poolKey;
            // quantity is handled by pools, not ticketBlocks patch
            if (pools[poolKey]) {
              pools[poolKey].quantity = newValue;
              this.setState({ pools: pools });
            }
            break;
          case "name":
            path = "/block/name";
            break;
          case "pool":
            path = "/poolKey";
            newValue =
              newValue === "unlimited" ? undefined : poolKeys[newValue];
            break;
          case "price":
            newValue = price += parseFloat(sections[id][field]).toFixed(
              CURRENCY_DECIMALS
            );
            break;
          case "description":
            // Quill always includes <p><br></p>, so filter out HTML markup
            // to check if description is empty, if so patch remove op
            newValue =
              newValue.replace(/<[^>]+>/g, "").length <= 0
                ? undefined
                : newValue;
            break;
          default:
            break;
        }

        if (path && newValue === undefined) {
          patches.push({ op: "remove", path: path });
        } else if (path) {
          patches.push({ op: "replace", path: path, value: newValue });
        }
      });
      this.props.updateEventBlock(eventSlug, id, patches);
    });

    Object.keys(extraPatches).forEach(id => {
      const patches = [];
      const photos = [];
      let price = "USD ";
      extraPatches[id].forEach(field => {
        const path = `/${field}`;
        let newValue = extras[id] && extras[id][field];
        // for extras that are variations, the name and totalQuantity fields are
        // controlled via the variations on the parent extra ie:
        // {xtr_id: {variations: {xtr_id: {name: "name", totalQuantity: "20"}}}}
        const hasVariations = extras[id] && extras[id].variations;
        const isVariationField = hasVariations
          ? field === "name" || field === "totalQuantity"
          : false;

        if (!extras[id] || (extras[id] && isVariationField)) {
          const variationParent = Object.keys(extras).filter(
            item => extras[item].variations && extras[item].variations[id]
          )[0];
          newValue = extras[variationParent].variations[id][field];
        }

        const variations = extras[id] && extras[id].variations;
        // need to patch price, description, and images for variations
        switch (field) {
          case "heading":
            for (const extra in variations) {
              // update heading for all of the variations in this extra
              this.props.updateExtra(eventSlug, extra, [
                { op: "replace", path: path, value: newValue }
              ]);
            }
            break;
          case "price":
            newValue = price += parseFloat(extras[id][field]).toFixed(CURRENCY_DECIMALS);
            break;
          case "totalQuantity":
            newValue = !newValue ? "unlimited" : parseInt(newValue, 10);
            if (newValue === "unlimited") {
              patches.push({ op: "remove", path: "/availableQuantity" });
            } else {
              patches.push({
                op: "replace",
                path: "/availableQuantity",
                value: newValue
              });
            }
            break;
          case "description":
            // Quill always includes <p><br></p>, so filter out HTML markup
            // to check if description is empty, if so patch remove op
            newValue =
              newValue.replace(/<[^>]+>/g, "").length <= 0
                ? undefined
                : newValue;
            break;
          case "images":
            for (const photo of newValue) {
              photos.push({ url: photo, alt: extras[id].name });
            }
            newValue = photos.length > 0 ? photos : undefined;
            break;
          default:
            break;
        }

        if (path && newValue === undefined) {
          patches.push({ op: "remove", path: path });
        } else if (path) {
          patches.push({ op: "replace", path: path, value: newValue });
        }
      });
      this.props.updateExtra(eventSlug, id, patches);
    });

    const preExistingExtras = this.props.event.extras || [];
    const activeExtras = preExistingExtras.filter(
      extra => extra.status === "active"
    );
    if (activeExtras.length > 0 && !hasExtras) {
      const archival = [{ op: "replace", path: "/status", value: "archived" }];
      activeExtras.forEach(extra => {
        this.props.updateExtra(slug, extra.itemId, archival);
      });
    }

    if (hasRestrictions) {
      for (const key in restrictions) {
        const restriction = restrictions[key];
        const includedBlocks = [];
        restriction.sections.forEach(item => {
          includedBlocks.push(sectionIds[item]);
        });
        const claimRestriction = {
          type: "maxTicketCount",
          maxCount: parseInt(restriction.limit, 10),
          includedBlocks
        };
        eventData.perClaimRestrictions.push(claimRestriction);
      }
    }

    for (const coupon of Object.values(coupons)) {
      let parsedFactor = parseFloat(coupon.effect.factor);

      if (coupon.effect.type === "percentOff") {
        parsedFactor *= PERCENT_TO_DECIMAL;
      }

      const couponEffect = {
        factor: parsedFactor,
        type: coupon.effect.type
      };

      const status = hasCoupons ? "ACTIVE" : "ARCHIVED";
      await this.props.updateCoupon(
        { effect: couponEffect, lifetime: coupon.lifetime, status },
        coupon.eventSlug,
        coupon.code
      );
    }

    await this.props.updateEvent(eventData, eventSlug);
    return this.props.eventResponse.success;
  };

  exitEvent = ev => {
    ev.preventDefault();
    this.setState({ exit: true });
  };

  deleteEvent = async () => {
    await this.updateEventData("ARCHIVED", "NO_CLAIMS");
    this.props.history.push("/admin");
  };

  cancelEvent = async () => {
    await this.updateEventData("PUBLIC", "NO_CLAIMS");
    this.props.history.push("/admin");
  };

  submit = async ev => {
    ev.preventDefault();
    // prevent submission if any errored fields
    if (this.props.eventFormErrors.length > 0) {
      this.setState({ displayErrors: true });
      return;
    }

    this.setState({ displayErrors: false });

    const validationError = this.validateTicketQuantities();
    if (validationError) {
      return;
    }

    const { attributes } = ev.target;
    const visibility = attributes.visibility && attributes.visibility.value;
    const availability =
      attributes.availability && attributes.availability.value;
    const success = await this.updateEventData(visibility, availability);

    if (success) {
      this.setState({ share: true });
    } else {
      const { currentBlock } = this.state;
      const eventError = {
        display: true,
        message: `Something went wrong creating this event. Try again later.`,
        block: currentBlock
      };

      this.setState({ error: eventError });
    }
  };

  validateTicketQuantities() {
    const { organization, number_of_tickets, currentBlock } = this.state;
    let ticketQuantities = 0;
    let difference = 0;
    let operation, heading;
    const error = { ...this.state.error };
    const validatePools = currentBlock === "pools";
    const validateSections =
      organization === "sections" && currentBlock === "sections";

    if (validatePools || validateSections) {
      // check that pool or section quantities add up to event quantity
      const block = this.state[currentBlock];

      Object.keys(block).forEach(item => {
        if (block[item].quantity) {
          ticketQuantities += parseInt(block[item].quantity, 10);
        }
      });

      if (ticketQuantities !== parseInt(number_of_tickets, 10)) {
        heading = organization === "pools" ? "Pool" : "Section";
        difference = number_of_tickets - ticketQuantities;
        operation = difference > 0 ? "add" : "remove";
        difference = difference < 0 ? difference * -1 : difference;
        const plural = difference > 1 ? "s" : "";

        error.display = true;
        error.message = `${heading} ticket quantities should add up to equal ${number_of_tickets}, the event's number of tickets. Please ${operation} ${difference} ticket${plural}.`;
        error.block = currentBlock;

        this.setState({ error });
        return true;
      }
      error.display = false;
      this.setState({ error });
    }
    return false;
  }

  handleStartDateInputChange = event => {
    const { name, value } = event.target;
    const { start_time } = this.state;
    this.setState({
      end_date: null,
      end_time: null,
      [name]: value
    });

    if (moment(`${value} ${start_time}`).isBefore(moment())) {
      this.setState({
        start_time: moment().add(1, "hour").startOf("hour").format("hh:mm a")
      });
    }
  };

  handleStartTimeInputChange = event => {
    const { name, value } = event.target;
    const { start_date } = this.state;

    if (!moment(`${start_date} ${value}`).isBefore(moment())) {
      this.setState({
        end_date: null,
        end_time: null,
        [name]: value
      });
    }
  };

  handleEndDateInputChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleEndTimeInputChange = event => {
    const { name, value } = event.target;
    const {
      start_date,
      start_time,
      end_date
    } = this.state;

    const endDateTime = moment(`${end_date || start_date} ${value}`);
    const startDateTime = moment(`${start_date} ${start_time}`);

    if (!end_date) {
      this.setState({ end_date: start_date });
    }

    if (value && endDateTime.isBefore(startDateTime)) {
      this.setState({ [name]: startDateTime.add(1, "hour").format("hh:mm a")});
    } else {
      this.setState({ [name]: value });
    }
  };

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });

    if (name === "organization") {
      this.setState(prevState => ({
        numberOfSteps:
          value === "pools"
            ? prevState.numberOfSteps + 1
            : prevState.numberOfSteps - 1
      }));
      if (value === "sections") {
        // if changing organization from pools to sections
        // clear and reset pools to make new pools for each section
        this.setState({ pools: {} });
      }
    }

    if (name === "eventType") {
      if (value === "online") {
        this.props.validateEventForm(false, "address");
      }
      if (value === "inPerson") {
        this.props.validateEventForm(false, "conferenceUrl");
      }
    }
  };

  handleNewBlockClick = blockName => {
    const blockIds = `${blockName.slice(0, -1)}Ids`;
    const newId = `BL${this.state[blockIds].length + 1}`;
    const ids = [...this.state[blockIds]];
    ids.push(newId);
    this.setState({ [blockIds]: ids });
  };

  handleBlockChange = (event, block) => {
    const { name, value, dataset } = event.target;
    const { id } = dataset;
    const sections = { ...this.state[block] };
    if (block === "sections" && !this.state.newEvent) {
      // if editing existing event's sections
      // create an object with ticketBlock/section fields that need patching
      // only add existing sections to patches
      if (this.existingSections.indexOf(id) >= 0) {
        const patches = { ...this.state.sectionPatches };
        if (!patches[id]) {
          patches[id] = [];
        }
        if (patches[id].indexOf(name) < 0) {
          patches[id].push(name);
        }
        this.setState({ sectionPatches: patches });
      }
    }
    if (block === "coupons") {
      // New coupon
      if (!sections[id]) {
        sections[id] = {
          code: "",
          effect: {
            type: "percentOff",
            factor: 0
          },
          eventSlug: this.state.slug,
          lifetime: {
            type: "permanent"
          }
        };
      }
      switch (name) {
        case "code":
          sections[id].code = value;
          break;
        case "amount":
          sections[id].effect.factor = value;
          break;
        case "uses":
          sections[id].lifetime.uses = parseInt(value);
          break;
        case "couponEffectType":
          sections[id].effect.type = value;
          break;
        case "couponUseType":
          sections[id].lifetime.type = value;
          break;
        default:
          sections[id][name] = value;
      }
    }

    /*
    if it is a variation and variationId is not included in existingExtras
    then we need to create a new extra, not add a patch
    */
    if (block === "extras" && !this.state.newEvent) {
      const isVariation = name === "variations";
      const isExistingExtra = this.existingExtras.indexOf(id) >= 0;
      const patches = { ...this.state.extraPatches };
      if (isExistingExtra) {
        const { variationId, field } = dataset;
        const patchId = isVariation ? variationId : id;
        const fieldToPatch = isVariation ? field : name;
        const variationIsNew =
          isVariation && this.existingExtras.indexOf(variationId) < 0;
        if (!variationIsNew) {
          if (!patches[patchId]) {
            patches[patchId] = [];
          }
          if (patches[patchId].indexOf(fieldToPatch) < 0) {
            patches[patchId].push(fieldToPatch);
          }
        }
      }

      this.setState({ extraPatches: patches });
    }

    if (block !== "coupons") {
      if (sections[id]) {
        sections[id][name] = value;
      } else {
        sections[id] = {
          [name]: value
        };
      }
    }
    this.setState({ [block]: sections });
  };

  handleBlockRemoval = (block, blockName) => {
    let coupon;
    if (blockName === "coupons") {
      coupon = block.coupon;
      block = block.id;
    }

    let canBeRemoved = true;

    if (blockName === "pools") {
      const { sections } = this.state;
      for (const key in sections) {
        if (sections[key].pool === block) {
          canBeRemoved = false;
          const poolError = {
            display: true,
            message: `You must reassign sections in this pool before removing it.`,
            block: "pools"
          };

          this.setState({ error: poolError });
          break;
        }
      }
    }

    if (canBeRemoved) {
      // remove block/pool/etc from event data
      const blocks = { ...this.state[blockName] };
      const blockIds = `${blockName.slice(0, -1)}Ids`;
      const ids = [...this.state[blockIds]];
      const newSectionIds = ids.filter(item => item !== block);
      delete blocks[block];
      this.setState({
        [blockName]: blocks,
        [blockIds]: newSectionIds
      });

      // also remove fields from array of errored fields
      const errors = this.props.eventFormErrors;
      const removed = errors.filter(field => field.includes(block));
      for (let i = 0; i < removed.length; i++) {
        this.props.validateEventForm(false, removed[i]);
      }
    }

    const { slug } = this.state;
    const archival = [{ op: "replace", path: "/status", value: "archived" }];

    if (blockName === "coupons") {
      if (coupon) {
        const { lifetime, effect, code } = coupon;
        this.props.updateCoupon(
          { lifetime,
            status: "ARCHIVED",
            effect
          },
          slug,
          code
        );
      }
    }

    if (blockName === "sections") {
      this.props.updateEventBlock(slug, block, archival);
    }

    if (blockName === "extras") {
      this.props.updateExtra(slug, block, archival);
    }
  };

  handleCheckboxChange = event => {
    const { name, checked } = event.target;
    let block;
    switch (name) {
      case "hasRestrictions":
        block = "restrictions";
        break;
      case "hasExtras":
        block = "extras";
        break;
      case "hasCoupons":
        block = "coupons";
        break;
      default:
        block = null;
    }

    if (name !== "shippingAddressRequired") {
      this.setState(prevState => ({
        [name]: checked,
        numberOfSteps: checked
          ? prevState.numberOfSteps + 1
          : prevState.numberOfSteps - 1
      }));
      if (checked) {
        this.setState({ featureAdded: true });
        if (block !== "coupons") {
          this.setState({ [block]: {} });
        }
      } else {
        this.setState({ [name]: checked });
      }
    } else {
      this.setState({ [name]: checked });
    }
  };

  uploadImage = async image => {
    await this.props.updateEventImage(image);
    const { src, error } = this.props.image;
    const { currentBlock } = this.state;
    if (error) {
      scrollToTop();
      this.setState({
        imageError: true,
        error: {
          display: true,
          message:
            "There was a problem uploading your image. Please try again or choose a new image.",
          block: currentBlock
        }
      });
    }
    return src;
  };

  handleImageChange = async (images, id) => {
    const { currentBlock, newEvent } = this.state;
    let src;
    // new images will be Files, existing images will be strings

    if (currentBlock === "info") {
      if (images.length > 0 && typeof images[0] !== "string") {
        src = await this.uploadImage(images[0].file);
      }
      this.setState({ image: src });
    } else if (currentBlock === "extras") {
      const extras = { ...this.state.extras };
      const isExistingExtra = this.existingExtras.indexOf(id) >= 0;
      const photos = [];

      for (const id in images) {
        if (typeof images[id] !== "string") {
          src = await this.uploadImage(images[id].file);
          if (src) {
            photos.push(src);
          }
        } else {
          photos.push(images[id]);
        }
      }

      const newImages = photos.length > 0 ? photos : undefined;
      if (extras[id]) {
        extras[id].images = newImages;
      } else {
        extras[id] = { images: photos };
      }

      this.setState({ extras });

      // patch existing extras
      if (!newEvent && isExistingExtra) {
        const patches = { ...this.state.extraPatches };
        if (!patches[id]) {
          patches[id] = [];
        }
        if (patches[id].indexOf("images") < 0) {
          patches[id].push("images");
        }
        this.setState({ extraPatches: patches });
      }
    }
  };

  render() {
    const { visibility, availability } = this.props.event;
    const { exit, share, slug, doesNotExist } = this.state;
    const eventDeleted =
      visibility === "ARCHIVED" && availability === "NO_CLAIMS";
    if (exit) {
      return <Redirect push to="/admin" />;
    } else if (share) {
      return <Redirect push to={`/admin/events/${slug}/share`} />;
    } else if (doesNotExist || eventDeleted) {
      return <ErrorPage isAdmin={true} />;
    }
    return (
      <Event
        formState={this.state}
        eventData={this.props.event}
        handlePrevious={this.handlePrevious}
        handleNext={this.handleNext}
        exitEvent={this.exitEvent}
        handleBlockChange={this.handleBlockChange}
        handleBlockRemoval={this.handleBlockRemoval}
        handleStartDateInputChange={this.handleStartDateInputChange}
        handleStartTimeInputChange={this.handleStartTimeInputChange}
        handleEndDateInputChange={this.handleEndDateInputChange}
        handleEndTimeInputChange={this.handleEndTimeInputChange}
        handleNewBlockClick={this.handleNewBlockClick}
        handleInputChange={this.handleInputChange}
        handleCheckboxChange={this.handleCheckboxChange}
        handleImageChange={this.handleImageChange}
        submit={this.submit}
        deleteEvent={this.deleteEvent}
        cancelEvent={this.cancelEvent}
        eventResponse={this.props.eventResponse}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    eventFormErrors: state.admin.eventFormErrors,
    eventResponse: state.admin.event,
    event: state.event.data,
    existingCoupons: state.admin.coupons.data || {},
    isFetching: state.event.isFetching,
    copiedEventId: state.admin.copiedEvent,
    image: state.admin.images
  };
};

export default withRouter(
  connect(mapStateToProps, {
    validateEventForm,
    clearFormErrors,
    updateEvent,
    createEventBlock,
    updateEventBlock,
    getEvent,
    getCoupons,
    updateCoupon,
    updateEventImage,
    clearEventImage,
    createExtra,
    updateExtra
  })(EventContainer)
);
