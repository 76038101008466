import React from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Favicon from "react-favicon";
import Loader from "./core/Loader";

import { useAuth0 } from "../react-auth0-spa.js";

import theme from "../theme";
import Landing from "./Landing";
import EventsList from "./EventsList";
import Event from "./Event";
import Checkout from "./Checkout";
import Tickets from "./Tickets";
import TicketConfirmation from "./TicketConfirmation";
import ErrorPage from "./core/ErrorPage";
import Order from "./Order";
import Admin from "./Admin";

function App() {
  const { loading } = useAuth0();
  const { selectedTickets } = useSelector(state => state);

  const hasTickets = Object.getOwnPropertyNames(selectedTickets).length > 0;

  return (
    <Router>
      <Favicon url={theme.FAVICON_URL} />
      {loading && (
        <div className="h-screen">
          <Loader />
        </div>
      )}
      <Switch>
        <Route exact path="/tickets/:claim" component={Tickets} />
        <Route
          exact
          path="/tickets/:claim/:id"
          component={TicketConfirmation}
        />
        <Route exact path="/" component={Landing} />
        <Route exact path="/events" component={EventsList} />
        <Route exact path="/events/:id" component={Event} />
        <Route
          path="/checkout"
          render={props => <Checkout {...props} hasTickets={hasTickets} />}
        />
        <Route exact path="/orders/:id" component={Order} />
        <Route path="/admin" component={Admin} />
        <Route component={ErrorPage} />
      </Switch>
    </Router>
  );
}

App.propTypes = {
  selectedTickets: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    selectedTickets: state.selectedTickets,
    admin: state.admin
  };
};

export default connect(mapStateToProps, {})(App);
