const REQUEST = "marketplace/events/REQUEST";
const SUCCESS = "marketplace/events/SUCCESS";
const FAILURE = "marketplace/events/FAILURE";

const initialState = { data: [], more: undefined };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.records,
        more: action.payload.more
      };
    case FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload.message
      };
    default:
      return state;
  }
}

export const getEvents = (
  query,
  start,
  isAdmin,
  highlightedOnly,
  after
) => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .get("/records", {
      params: {
        q: query || "",
        start: start,
        limit: 10,
        adminOnly: isAdmin,
        order: "date-asc",
        highlighted: highlightedOnly,
        afterDateTime: after
      }
    })
    .then(results => dispatch({ type: SUCCESS, payload: results.data }))
    .catch(error => dispatch({ type: FAILURE, payload: error, error: true }));
};
