import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils";

import { fetchTickets } from "../../ducks/purchasedTickets";

import theme from "../../theme";
import Ticket from "./ticket";
import Loader from "../core/Loader";
import ErrorPage from "../core/ErrorPage";

export class Tickets extends React.Component {
  componentDidMount() {
    const claimId = this.props.match.params.claim;
    this.props.fetchTickets(claimId);
  }

  renderTickets() {
    const { tickets } = this.props;
    return Object.keys(tickets).map(item => {
      return (
        <Ticket
          ticket={tickets[item]}
          claim={this.props.match.params.claim}
          key={tickets[item].ticketId}
          index={item}
          total={tickets.length}
        />
      );
    });
  }

  renderEventInfo() {
    const { event } = this.props;
    const eventDate = formatDate(event.dateAndTime);
    return (
      <div className="mb-3 mt-6 border p-3">
        <p className="my-1 text-lg w-full text-center">{event.name}</p>
        <p className="my-1 text-lg w-full text-center">
          {eventDate.monthShort} {eventDate.day}, {eventDate.year}{" "}
          {eventDate.time} @ {event.venue.name}
        </p>
        <p className="my-1 text-lg w-full text-center">{event.venue.address}</p>
      </div>
    );
  }

  render() {
    const { event, isFetching } = this.props;
    if (isFetching) {
      return (
        <div className="flex items-center min-h-screen justify-center">
          <Loader />
        </div>
      );
    } else if (event) {
      return (
        <div className="flex items-center min-h-screen mx-6 content-center flex-wrap justify-center flex-col print:flex-row">
          <div className="mt-3 print:w-full">
            <Link to="/">
              {/* eslint-disable global-require */}
              <img
                src={theme.LOGO_SRC}
                alt="logo"
                className="max-w-xs p-3 mx-auto"
              />
            </Link>
            {this.renderEventInfo()}
          </div>
          {this.renderTickets()}
        </div>
      );
    }
    return <ErrorPage />;
  }
}

export const mapStateToProps = state => {
  return {
    tickets: state.purchasedTickets.tickets,
    event: state.purchasedTickets.event,
    isFetching: state.purchasedTickets.isFetching
  };
};

export default connect(mapStateToProps, { fetchTickets })(Tickets);
