/* eslint-disable no-undef */
export const theme = {
  // NAME needs to match the theme folder name EXACTLY
  NAME: "savethefoodaustin",
  TENANT: "dfyuvd",
  // External header links
  LINKS: [
    {
      url: "https://www.savethefoodaustin.org/",
      label: "Home"
    },
    {
      url: "https://www.savethefoodaustin.org/donate",
      label: "Donate"
    }
  ],
  // Social media links for footer
  SOCIAL:
    "https://www.facebook.com/savethefoodatx https://www.instagram.com/savethefoodaustin",
  // This might seem a bit backwards, but HEADING_1 is the larger text on the
  // second line of the landing page, while HEADING_2 is the first line but it's
  // smaller in size
  HEADING_1: "SAVE THE FOOD",
  // Below are optional
  // Check client's questionnaire response for HIDESEARCHBAR
  // DEMO should only be true for sites we use as demos
  HIDESEARCHBAR: false,
  DEMO: false
};
