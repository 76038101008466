import React from "react";
import PropTypes from "prop-types";

import ErrorMessage from "../core/ErrorMessage";
import FormHeading from "../core/FormHeading";
import EventInfo from "./EventInfo";
import EventSections from "./EventSections";
import EventRestrictions from "./EventRestrictions";
import EventPools from "./EventPools";
import EventExtras from "./EventExtras";
import EventCoupons from "./EventCoupons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

class EventForm extends React.Component {
  handleNewBlockClick = e => {
    e.preventDefault();
    const { currentBlock } = this.props.formState;
    this.props.handleNewBlockClick(currentBlock);
  };

  handleBlockRemoval = id => {
    const { currentBlock } = this.props.formState;
    this.props.handleBlockRemoval(id, currentBlock);
  };

  handleBlockChange = event => {
    const { currentBlock } = this.props.formState;
    this.props.handleBlockChange(event, currentBlock);
  };

  renderError(error) {
    if (error.display && error.block === this.props.formState.currentBlock) {
      return <ErrorMessage message={error.message} />;
    }
    return null;
  }

  renderCurrentBlock() {
    const {
      formState,
      handleInputChange,
      handleCheckboxChange,
      handleStartDateInputChange,
      handleStartTimeInputChange,
      handleEndDateInputChange,
      handleEndTimeInputChange,
      handleImageChange
    } = this.props;
    const {
      currentBlock,
      sections,
      pools,
      restrictions,
      extras,
      coupons,
      organization,
      displayErrors,
      poolIds,
      sectionIds,
      restrictionIds,
      extraIds,
      couponIds,
      slug
    } = formState;

    const blockProps = {
      handleBlockChange: this.handleBlockChange,
      handleBlockRemoval: this.handleBlockRemoval,
      displayErrors
    };

    switch (currentBlock) {
      case "sections":
        return (
          <EventSections
            sections={sections}
            pools={pools}
            organization={organization}
            sectionIds={sectionIds}
            {...blockProps}
          />
        );
      case "extras":
        return (
          <EventExtras
            extras={extras}
            extraIds={extraIds}
            slug={slug}
            handleImageChange={handleImageChange}
            {...blockProps}
          />
        );
      case "pools":
        return <EventPools pools={pools} poolIds={poolIds} {...blockProps} />;
      case "restrictions":
        return (
          <EventRestrictions
            restrictions={restrictions}
            sections={sections}
            restrictionIds={restrictionIds}
            {...blockProps}
          />
        );
      case "coupons":
        return (
          <EventCoupons
            coupons={coupons}
            couponIds={couponIds}
            {...blockProps}
          />
        );
      default:
        return (
          <EventInfo
            formState={formState}
            handleStartDateInputChange={handleStartDateInputChange}
            handleStartTimeInputChange={handleStartTimeInputChange}
            handleEndDateInputChange={handleEndDateInputChange}
            handleEndTimeInputChange={handleEndTimeInputChange}
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
            handleImageChange={handleImageChange}
          />
        );
    }
  }

  renderNewBlockButton() {
    const { currentBlock } = this.props.formState;
    const blockTitle =
      currentBlock.charAt(0).toUpperCase() + currentBlock.slice(1, -1);
    if (currentBlock !== "info") {
      return (
        <button
          className="px-3 py-6 text-xl w-full text-center cursor-pointer"
          onClick={this.handleNewBlockClick}
        >
          <FontAwesomeIcon icon={faPlus} /> Add {blockTitle}
        </button>
      );
    }
    return null;
  }

  render() {
    const { currentBlock, error, currentStepNumber } = this.props.formState;
    const blockTitle =
      currentBlock.charAt(0).toUpperCase() + currentBlock.slice(1);

    return (
      <div className="bg-white m-3 border">
        <FormHeading number={currentStepNumber} heading={blockTitle} />
        {this.renderError(error)}
        {this.renderCurrentBlock()}
        {this.renderNewBlockButton()}
      </div>
    );
  }
}

EventForm.propTypes = {
  handleBlockChange: PropTypes.func.isRequired,
  handleBlockRemoval: PropTypes.func.isRequired,
  handleStartDateInputChange: PropTypes.func.isRequired,
  handleStartTimeInputChange: PropTypes.func.isRequired,
  handleEndDateInputChange: PropTypes.func.isRequired,
  handleEndTimeInputChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  handleNewBlockClick: PropTypes.func.isRequired,
  formState: PropTypes.object,
  event: PropTypes.object
};

export default EventForm;
