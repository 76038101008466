const REQUEST = "marketplace/admin/event/coupons/REQUEST";
const SUCCESS = "marketplace/admin/event/coupon/SUCCESS";
const UPDATE_COUPON = "marketplace/admin/event/coupon/UPDATE_COUPON";
const FAILURE = "marketplace/admin/event/coupons/FAILURE";

const initialState = {};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case SUCCESS:
      return { ...state, isFetching: false, data: action.payload };
    case UPDATE_COUPON:
      return { ...state, isFetching: false, success: action.payload };
    case FAILURE:
      return { ...state, isFetching: false, success: action.payload };
    default:
      return state;
  }
}

export const getCoupons = (eventSlug) => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .get(`/events/${eventSlug}/coupons?status=active`)
    .then((results) => dispatch({ type: SUCCESS, payload: results.data }))
    .catch(() => dispatch({ type: FAILURE, payload: false }));
};

export const updateCoupon =
    (data, eventSlug, couponId) => (dispatch, getState) => {
  dispatch({ type: REQUEST });
  const { effect, lifetime } = data;

  if (effect.type === "fixed") {
    effect.factor *= 100;
  }
  else if (effect.type === "percentOff" && effect.factor > 1) {
    effect.factor /= 100;
  }
  if (lifetime.type !== "permanent" && lifetime.uses === undefined) {
    lifetime.type = "oneTimeUse";
  }

  return getState().apis.marketplace
    .put(`/events/${eventSlug}/coupons/${couponId}`, data)
    .then(() => dispatch({ type: UPDATE_COUPON, payload: true }))
    .catch(() => dispatch({ type: FAILURE, payload: false }));
};
