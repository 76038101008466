const SUCCESS = "marketplace/trackHighlightedEvent/SUCCESS";

export default function reducer(state = [], action = {}) {
  switch (action.type) {
    case SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const trackHighlightedEvent = (eventId) => (dispatch, getState) => {
  const currentHighlighted = getState().admin.highlights;
  if (currentHighlighted.includes(eventId)) {
    currentHighlighted.splice(currentHighlighted.indexOf(eventId), 1);
  } else {
    currentHighlighted.push(eventId);
  }

  dispatch({ type: SUCCESS, payload: currentHighlighted });
};
