/* eslint-disable no-undef */
const {
  theme
} = require(`./styles/themes/${process.env.REACT_APP_THEME}/config.js`);

let stageConfig;
switch (process.env.REACT_APP_STAGE) {
  case "local":
    stageConfig = {
        BASE_API_ENDPOINT: "http://localhost:8080",
        AUDIENCE: "dev.wltm.bandwagonfanclub.com",
        TENANT_ID: "abcdef"
    };
    break;
  case "dev":
    stageConfig = {
        BASE_API_ENDPOINT: "https://dev.mkt.bandwagonfanclub.com",
        AUDIENCE: "dev.wltm.bandwagonfanclub.com",
        TENANT_ID: "jxvwde"
    };
    break;
  default:
    stageConfig = {
        BASE_API_ENDPOINT: "https://be.wltm.bandwagonfanclub.com",
        AUDIENCE: "wltm.bandwagonfanclub.com",
        TENANT_ID: theme.TENANT
    };
}

const dynamicConfig = window.isfeConfig || {};

const config = merge(
        stageConfig,
        {
            BASE_API_ENDPOINT: process.env.REACT_APP_BASE_API_ENDPOINT,
            AUDIENCE: process.env.REACT_APP_AUDIENCE,
            TENANT_ID: process.env.REACT_APP_TENANT_ID
        },
        dynamicConfig);

export default config;

function merge(...args) {
    let result = {};
    for (let next of args) {
        result = Object.assign(result, Object.fromEntries(
                Object.entries(next).filter(([k, v]) => v !== undefined)));
    }
    return result;
};
