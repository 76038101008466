/* eslint-disable no-undef */
export const theme = {
  NAME: "reachout",
  TENANT: "fyqx7a",
  LINKS: [
    {
      url: "http://reachoutnetwork.org/",
      label: "Reach Out Network"
    }
  ],
  SOCIAL:
    "https://www.instagram.com/reachout_atl/ https://www.facebook.com/reachoutnetworkinc/",
  HEADING_1: "Mental Health Series",
  HEADING_2: "Reach Out Network"
};
