/* eslint-disable global-require */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faCircle
} from "@fortawesome/free-solid-svg-icons";
import { Swipeable } from "react-swipeable";

const Carousel = ({ images, containerClass }) => {
  const [imageIndex, updateImageIndex] = useState(0);

  const numberOfImages = images.length;
  const handlePrevious = () => {
    updateImageIndex(prevImageIndex =>
      prevImageIndex === 0 ? numberOfImages - 1 : prevImageIndex - 1
    );
  };
  const handleNext = () => {
    updateImageIndex(prevImageIndex =>
      prevImageIndex === numberOfImages - 1 ? 0 : prevImageIndex + 1
    );
  };

  const galleryDots = [];
  const galleryImages = [];

  if (images.length > 0) {
    images.forEach((image, index) => {
      galleryDots.push(
        <FontAwesomeIcon
          icon={faCircle}
          key={image.url}
          className={`mx-1 my-2 hover:primary-color cursor-pointer ${
            index === imageIndex ? "primary-color" : ""
          }`}
          onClick={() => updateImageIndex(index)}
        />
      );
      galleryImages.push(
        <div className={index === imageIndex ? "" : "hidden"} key={image.url}>
          <Swipeable onSwipedLeft={handleNext} onSwipedRight={handlePrevious}>
            <img src={image.url} alt={image.alt} className="m-auto" />
          </Swipeable>
        </div>
      );
    });
  }

  return (
    <div className={`${containerClass} py-3 md:mb-6 mb-0 relative`}>
      <div className="relative">
        {images.length > 1 && (
          <button
            className="absolute h-full p-3 z-10 flex items-center hover:opacity-50"
            onClick={handlePrevious}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        )}
        {galleryImages}
        {images.length > 1 && (
          <button
            className="absolute h-full p-3 z-10 flex items-center top-0 right-0 hover:opacity-50"
            onClick={handleNext}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        )}
      </div>
      {images.length > 1 && (
        <div className="text-2xs flex justify-center absolute w-full text-gray-400">
          {galleryDots}
        </div>
      )}
    </div>
  );
};

Carousel.propTypes = {
  images: PropTypes.array.isRequired,
  containerClass: PropTypes.string
};

export default Carousel;
