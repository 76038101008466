import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import { patchEvent } from "../../../ducks/admin/event";
import { trackHighlightedEvent } from "../../../ducks/admin/highlightedEvents";

const INITIAL_STATE = {
  isHighlighted: false
};

class FeaturedButton extends React.Component {
  state = INITIAL_STATE;
  componentDidMount() {
    if (this.props.row.original.id) {
      this.updateSlugAndHighlighted();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.row !== this.props.row) {
      this.updateSlugAndHighlighted();
    }
  }

  updateSlugAndHighlighted() {
    this.eventSlug = this.props.row.original.id.replace("events/", "");
    this.setState({ isHighlighted: this.props.value });
  }

  toggleFeatured = async () => {
    const patch = [
      { op: "replace", path: "/highlighted", value: !this.state.isHighlighted }
    ];
    const { payload } = await this.props.patchEvent(patch, this.eventSlug);
    if (payload) {
      this.props.trackHighlightedEvent(this.eventSlug);
      this.setState(prevState => ({
        isHighlighted: !prevState.isHighlighted
      }));
    }
  };

  render() {
    const { row } = this.props;
    if (!row.original.more.current || row.original.status !== "Published") {
      return null;
    }
    const style = this.state.isHighlighted
      ? "text-yellow-500"
      : "text-gray-300";
    const label = this.state.isHighlighted
      ? "Unfeature Event"
      : "Feature Event";
    return (
      <div
        className={`m-auto text-center cursor-pointer ${style}`}
        onClick={this.toggleFeatured}
        aria-label={label}
      >
        <FontAwesomeIcon icon={faStar} />
      </div>
    );
  }
}

FeaturedButton.propTypes = {
  row: PropTypes.object.isRequired,
  value: PropTypes.bool,
  patchEvent: PropTypes.func.isRequired,
  trackHighlightedEvent: PropTypes.func.isRequired
};

export default connect(null, { patchEvent, trackHighlightedEvent })(
  FeaturedButton
);
