import React from "react";
import PropTypes from "prop-types";

import AdminInput from "../core/AdminInput";
import AdminTextarea from "../core/AdminTextarea";
import EventBlock from "./EventBlock";

import uniqid from "uniqid";

import AdminSelect from "../core/AdminSelect";
import { scrollToTop, validateFieldLength } from "../../../utils";

class EventSections extends React.Component {
  componentDidMount() {
    scrollToTop();
  }

  onSelectChange(e, id) {
    const event = { target: { name: "pool", value: e.value, dataset: { id } } };
    this.props.handleBlockChange(event);
  }

  renderPoolSelection(id, pool) {
    const options = [{ value: "unlimited", label: "No Pool / Unlimited" }];
    const { pools, displayErrors } = this.props;
    for (const [poolKey, { name }] of Object.entries(pools)) {
      options.push({ value: poolKey, label: name });
    }

    let selectedOption;
    if (pool) {
      selectedOption = options.find(element => element.value === pool);
    } else {
      selectedOption = options[0];
    }

    return (
      <AdminSelect
        options={options}
        field="pool"
        fieldName="Pool"
        width="1/3"
        onChange={e => this.onSelectChange(e, id)}
        id={id}
        value={selectedOption}
        required={true}
        displayError={displayErrors}
      />
    );
  }

  renderQuantity(id, quantity) {
    return (
      <AdminInput
        field="quantity"
        fieldName="Quantity"
        type="number"
        onChange={this.props.handleBlockChange}
        width="1/4"
        id={id}
        value={String(quantity) || ""}
        displayError={this.props.displayErrors}
        helpText="Leave blank to give this section unlimited tickets."
        placeholder="-"
      />
    );
  }

  render() {
    const sectionBlocks = [];
    const { sections, organization, displayErrors, sectionIds } = this.props;

    if (sectionIds.length <= 0) {
      sectionIds.push(uniqid());
    }
    for (let i = 0; i < sectionIds.length; i++) {
      const id = sectionIds[i];
      const section = sections[sectionIds[i]];

      let name, price, quantity, description, pool, status;
      if (section) {
        name = section.name;
        price = section.price && section.price.replace("USD ", "");
        quantity = section.quantity;
        description = section.description;
        pool = section.pool;
        status = section.status;
      }

      let numberOfSections = 0;
      for (const id of sectionIds) {
        if (!sections[id] || sections[id].status !== "archived") {
          numberOfSections += 1;
        }
      }

      if (status !== "archived") {
        sectionBlocks.push(
          <EventBlock
            formBlock="Section"
            handleFormBlockRemoval={() => this.props.handleBlockRemoval(id)}
            numberOfFormBlocks={numberOfSections}
            key={id}
            name={name}
            emptyBlock={!name && !price && !quantity && !description && !pool}
          >
            <AdminInput
              field="name"
              fieldName="Section Name"
              type="text"
              onChange={this.props.handleBlockChange}
              width="1/2"
              id={id}
              value={name || ""}
              required={true}
              displayError={displayErrors}
              validateField={validateFieldLength}
              maxLength={50}
            />
            <AdminInput
              field="price"
              fieldName="Price"
              type="number"
              onChange={this.props.handleBlockChange}
              width={organization === "pools" ? "1/6" : "1/4"}
              id={id}
              value={String(price)}
              required={true}
              displayError={displayErrors}
            />
            {organization === "pools"
              ? this.renderPoolSelection(id, pool, quantity)
              : this.renderQuantity(id, quantity)}
            <AdminTextarea
              label="Description"
              field="description"
              onChange={this.props.handleBlockChange}
              containerClass="w-full p-2"
              id={id}
              value={description || ""}
              collapsible={true}
              validateField={validateFieldLength}
              maxLength={5000}
            />
          </EventBlock>
        );
      }
    }
    return sectionBlocks;
  }
}

EventSections.propTypes = {
  pools: PropTypes.object.isRequired,
  sections: PropTypes.object.isRequired,
  organization: PropTypes.string.isRequired,
  handleBlockChange: PropTypes.func,
  handleBlockRemoval: PropTypes.func,
  sectionIds: PropTypes.array,
  displayErrors: PropTypes.bool
};

export default EventSections;
