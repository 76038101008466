const SUCCESS = "marketplace/orderSuccess/SUCCESS";
const SELECT = "marketplace/ticketSelection/SELECT";

export default function reducer(state = false, action = {}) {
  switch (action.type) {
    case SUCCESS:
      return action.payload;
    case SELECT:
      return false;
    default:
      return state;
  }
}

export const setOrderSuccess = () => dispatch => {
  dispatch({ type: SUCCESS, payload: true });
};
