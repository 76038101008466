import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import QRCode from "qrcode.react";
import MainContainer from "../core/MainContainer";
import Button from "../core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPrint,
  faCalendar
} from "@fortawesome/free-solid-svg-icons";
import { getOrder } from "../../ducks/admin/order";
import { fetchTickets } from "../../ducks/purchasedTickets";
import { getEvent } from "../../ducks/event";
import { rollbarConsumer } from "../../rollbar-context";
import ErrorPage from "../core/ErrorPage";
import Loader from "../core/Loader";
import AddToCalendarHOC, {
  onRequestClose,
  SHARE_SITES
} from "react-add-to-calendar-hoc";
import CalendarModal from "./CalendarModal";
import moment from "moment";

const decimals = 2;

class Order extends React.Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getOrder(`clm_${id}`);
    this.props.fetchTickets(`clm_${id}`);
  }

  componentDidUpdate(prevProps) {
    const { event } = this.props.order.data;
    if (!prevProps.order.data.event && event) {
      this.props.getEvent(event);
    }
  }

  renderHeading() {
    const { id } = this.props.match.params;
    const { isAdmin, event } = this.props;
    return (
      <div>
        <h1
          className={`${
            isAdmin ? "text-3xl" : "text-xl"
          } print:text-xl font-bold px-3 py-1 truncate mb-2 text-center w-full`}
        >
          Order #{id}
        </h1>
        <div className="w-full flex justify-center items-center">
          <Button
            buttonStyle="secondary"
            onClick={() => window.print()}
            extraClass="min-w-24 text-gray-600 print:hidden"
            label={
              <React.Fragment>
                Print <FontAwesomeIcon icon={faPrint} />
              </React.Fragment>
            }
          />
          {Object.keys(event).length > 0 && this.renderCalendar()}
        </div>
      </div>
    );
  }

  renderButton() {
    const { event } = this.props.order.data;
    const { isAdmin } = this.props;
    if (isAdmin) {
      return (
        <div className="text-center sm:text-left mt-5 print:hidden">
          <Button
            label={
              <React.Fragment>
                <FontAwesomeIcon icon={faArrowLeft} /> Back to Orders
              </React.Fragment>
            }
            extraClass="block min-w-40 max-w-2xs m-auto sm:m-3"
            isLink={true}
            path={`/admin/orders?event=${event}`}
          />
        </div>
      );
    }
    return (
      <div className="text-center mt-5 print:hidden">
        <Button
          label="Shop for More Tickets"
          extraClass="block min-w-40 max-w-2xs m-auto"
          isLink={true}
          path={`/events`}
        />
      </div>
    );
  }

  renderLabel(label) {
    return <p className="text-xl border-b mx-2 p-1 pb-2 font-bold">{label}</p>;
  }

  renderInfo(info) {
    return <p className="p-1 mx-2">{info}</p>;
  }

  renderConferenceUrl(url) {
    const pattern = /^(https?):\/\//;
    const absoluteUrl = pattern.test(url) ? url : `http://${url}`;

    return (
      <p className="p-1 mx-2">
        Join this event here:
        <a
          href={absoluteUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {` ${absoluteUrl}`}
        </a>
      </p>
    );
  }

  renderCustomer() {
    const { data } = this.props.order;
    const { customerInfo } = data;

    return (
      <div className="pb-6">
        {this.renderLabel("Customer")}
        {this.renderInfo(customerInfo ? customerInfo.name : "")}
        {this.renderInfo(customerInfo ? customerInfo.email : "")}
      </div>
    );
  }

  renderEvent() {
    const { order, event } = this.props;
    const { eventName, eventConferenceUrl } = order.data;
    const { venue } = event;
    const { address, name } = venue || {};

    return (
      <div className="pb-6">
        {this.renderLabel("Event")}
        {this.renderInfo(eventName || "")}
        {address && this.renderInfo(`${name ? `${name} at ` : ""}${address}`)}
        {eventConferenceUrl && this.renderConferenceUrl(eventConferenceUrl)}
      </div>
    );
  }

  renderItems(item) {
    const { data } = this.props.order;

    switch (item.type) {
      case "item": {
        const { itemDetails } = data.claim[item.id];
        return (
          <div className="flex justify-between py-2 px-3" key={item.id}>
            <p>
              {itemDetails.block ? itemDetails.block.name : itemDetails.name}:
              {` ${item.unitPrice.replace("USD ", "$")} x ${item.quantity}`}
            </p>
            <p className="ml-auto">
              $
              {(
                item.quantity * parseFloat(item.unitPrice.replace("USD ", ""))
              ).toFixed(decimals)}
            </p>
          </div>
        );
      }
      case "coupon": {
        return (
          <div className="flex justify-between py-2 px-3" key={item.code}>
            <p>Coupon &quot;{item.code}&quot;</p>
            <p className="ml-auto">{item.effect.replace("USD ", "$")}</p>
          </div>
        );
      }
      default: {
        this.props.rollbar.warning("Unknown line item type: " + item.type);
      }
    }
  }

  renderDetails() {
    const { data } = this.props.order;
    const { invoice } = data;
    const sections = invoice.lineItems;
    const lineItems = [];
    sections.forEach(section => {
      lineItems.push(this.renderItems(section));
    });

    return (
      <div>
        {this.renderLabel("Order Details")}
        {lineItems}
        {invoice.stripeFee && (
          <div className="flex justify-between py-2 px-3">
            <p>Transaction Fee</p>
            <p className="ml-auto">{invoice.stripeFee.replace("USD ", "$")}</p>
          </div>
        )}
        <div className="flex justify-between py-2 px-3 font-bold pb-6">
          <p>Order Total</p>
          <p className="ml-auto">{invoice.grandTotal.replace("USD ", "$")}</p>
        </div>
      </div>
    );
  }

  renderTickets() {
    const { tickets } = this.props;
    const qrCodes = [];

    // only generate QR codes for tickets, not extras
    for (const ticket of tickets.filter(ticket =>
      ticket.blockId.includes("tbk")
    )) {
      qrCodes.push(this.renderQRCode(ticket));
    }

    return (
      <React.Fragment>
        {this.renderLabel("Tickets")}
        <div className="flex flex-wrap">{qrCodes}</div>
      </React.Fragment>
    );
  }

  renderQRCode(ticket) {
    const total = this.props.tickets.length;
    const claim = this.props.order.data.id;
    const { ticketId, signature } = ticket;
    const params = new URLSearchParams({ s: signature, t: total });
    const baseUrl = window.location.origin;
    const confirmation = `/tickets/${claim}/${ticketId}?${params}`;
    const QRconfirmation = `${baseUrl}${confirmation}`;
    return (
      <Link to={confirmation} key={ticketId}>
        <QRCode
          value={QRconfirmation}
          className="w-full my-3 px-3 print:hidden"
          renderAs="svg"
          size={150}
        />
        <QRCode
          value={QRconfirmation}
          className="w-full my-3 px-3 hidden print:block"
          renderAs="svg"
          size={100}
        />
      </Link>
    );
  }

  renderCalendar() {
    const { event } = this.props;
    const { dateAndTime, endDateAndTime, description, venue, name } = event;
    const startDateTime = moment(dateAndTime);
    const endDateTime = endDateAndTime
      ? moment(endDateAndTime)
      : startDateTime.clone().add(1, "hours");
    const duration = moment.duration(endDateTime.diff(startDateTime)).asHours();
    // start/endDateTime for Google + Yahoo, start/endTime for iCal + Outlook

    const { data } = this.props.order;
    const { eventConferenceUrl } = data;

    const calendarDescription = venue.address && eventConferenceUrl ?
      `${description}\n\nThis event will also be held online at ${eventConferenceUrl}` :
      description;

    const calendarEvent = {
      description: calendarDescription,
      duration,
      endDatetime: endDateTime.format("YYYYMMDDTHHmmssZ"),
      endTime: endDateTime.format("YYYY-MM-DDTHH:mm:ssZ"),
      location: venue.address || eventConferenceUrl,
      startDatetime: startDateTime.format("YYYYMMDDTHHmmssZ"),
      startTime: startDateTime.format("YYYY-MM-DDTHH:mm:ssZ"),
      title: name
    };
    const AddToCalendarModal = AddToCalendarHOC(Button, CalendarModal);
    return (
      <AddToCalendarModal
        event={calendarEvent}
        items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL, SHARE_SITES.OUTLOOK]}
        buttonProps={{
          label: (
            <React.Fragment>
              Add To Calendar <FontAwesomeIcon icon={faCalendar} />
            </React.Fragment>
          ),
          buttonStyle: "secondary",
          extraClass: "print:hidden"
        }}
        className="inline-block"
        dropdownProps={{ onRequestClose, event: calendarEvent }}
      />
    );
  }

  renderOrder() {
    const orderLoaded = Boolean(this.props.order.data.claim);
    const { isAdmin } = this.props;
    return (
      <div className="bg-white px-3 py-6 border mx-3 mb-6 mt-3">
        {orderLoaded ? (
          <React.Fragment>
            {this.renderCustomer()}
            {this.renderEvent()}
            {this.renderDetails()}
            {isAdmin && this.renderTickets()}
          </React.Fragment>
        ) : (
          <Loader />
        )}
      </div>
    );
  }

  renderOrderSuccessMessage() {
    return (
      <p className="p-3 text-center text-3xl font-bold print:hidden border-double border-b-4 mx-3 mb-6">
        Thank you for your order!
      </p>
    );
  }

  render() {
    if (this.props.order.errorMessage) {
      return <ErrorPage />;
    }
    return (
      <MainContainer isAdmin={this.props.isAdmin} extraClass="py-3">
        <div className="print:max-w-3xl max-w-xl m-auto my-6 print:my-0 w-full">
          {!this.props.isAdmin && this.renderOrderSuccessMessage()}
          {this.renderHeading()}
          {this.renderOrder()}
          {this.renderButton()}
        </div>
      </MainContainer>
    );
  }
}

export const mapStateToProps = state => {
  return {
    order: state.admin.order,
    tickets: state.purchasedTickets.tickets,
    event: state.event.data
  };
};

export default rollbarConsumer(
  connect(mapStateToProps, { getOrder, fetchTickets, getEvent })(Order)
);
