/* eslint-disable no-undef */
export const theme = {
  // NAME needs to match the theme folder name EXACTLY
  NAME: "divinc",
  TENANT: "tin5v8",
  // External header links
  LINKS: [
    {
      url: "https://www.divinc.org/",
      label: "DivInc"
    },
    {
      url: "https://www.divinc.org/champions-of-change",
      label: "Champions of Change"
    }
  ],
  // Social media links for footer
  SOCIAL:
    "https://www.facebook.com/DivIncTX https://www.instagram.com/wearedivinc/ https://www.linkedin.com/company/divincatx/ https://twitter.com/wearedivinc",
  // This might seem a bit backwards, but HEADING_1 is the larger text on the
  // second line of the landing page, while HEADING_2 is the first line but it's
  // smaller in size
  HEADING_1: "DivInc events!",
  HEADING_2: "Join us for upcoming",
  // Below are optional
  // Check client's questionnaire response for HIDESEARCHBAR
  // DEMO should only be true for sites we use as demos
  HIDESEARCHBAR: false,
  DEMO: false
};
