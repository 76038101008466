/* eslint-disable react/display-name */
import React from "react";
import { withRouter } from "react-router";
import TableContainer from "../../core/TableContainer";
import Content from "./Content";
import moment from "moment";

const Events = () => {
  return (
    <TableContainer
      paginationWidth="full"
      isAdmin={true}
      dataType="events"
      after={moment()
        .utc()
        .format()}
    >
      <Content />
    </TableContainer>
  );
};

export default withRouter(Events);
