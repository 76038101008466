const REQUEST = "marketplace/waitlist/REQUEST";
const SUCCESS = "marketplace/waitlist/SUCCESS";
const FAILURE = "marketplace/waitlist/FAILURE";

const initialState = {};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case SUCCESS:
      return { ...state, isFetching: false, payload: action.payload };
    case FAILURE:
      return { ...state, isFetching: false, error: action.payload };
    default:
      return state;
  }
}

export const addToWaitlist =
    (eventId, email, items) => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .put(`/events/${eventId}/waitlistEntries/${email}`, items)
    .then(results => dispatch({ type: SUCCESS, payload: results }))
    .catch(error =>
      dispatch({ type: FAILURE, payload: error.response.data, error: true })
    );
};
