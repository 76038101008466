import React from "react";
import PropTypes from "prop-types";
import AdminInput from "../core/AdminInput";
import Button from "../../core/Button";

import uniqid from "uniqid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faPlus } from "@fortawesome/free-solid-svg-icons";

const INITIAL_STATE = {
  variations: {},
  variationIds: []
};

const minimumVariations = 2;

class EventExtrasVariations extends React.Component {
  state = INITIAL_STATE;

  async componentDidMount() {
    await this.setupVariationIds();
  }

  setupVariationIds = () => {
    const { variations = {} } = this.props;
    let variationIds = [];
    for (const item of Object.values(variations)) {
      if (item.status === "active") {
        variationIds.push(item.itemId);
      }
    }

    if (variationIds.length === 0) {
      // minimum 2 variations
      // if only 1 variation, then it's not really a variation
      variationIds = [this.props.extraId, uniqid()];
    }

    this.setState({ variationIds });
  };

  updateExtra = (variations, field, variationId) => {
    const { extraId } = this.props;
    const blockEvent = {
      target: {
        name: "variations",
        value: variations,
        dataset: { id: extraId, field, variationId }
      }
    };

    this.props.handleBlockChange(blockEvent);
  };

  handleVariationChange = event => {
    const variations = { ...this.props.variations };
    const { extraId } = this.props;
    const { name, value, dataset } = event.target;
    const { id } = dataset;
    const field = name.replace(`${extraId}-variation-`, "");

    variations[id] = Object.assign({}, variations[id], { [field]: value });

    this.setState({ variations });
    this.updateExtra(variations, field, id);
  };

  handleVariationRemoval = (event, variationId) => {
    const variations = { ...this.props.variations };
    const variationIds = [...this.state.variationIds];
    const newVariationIds = variationIds.filter(item => item !== variationId);
    delete variations[variationId];
    this.setState({
      variations,
      variationIds: newVariationIds
    });
    this.props.handleBlockRemoval(variationId);
  };

  handleNewVariation = event => {
    event.preventDefault();
    const variationIds = [...this.state.variationIds];
    const newId = uniqid();
    variationIds.push(newId);
    this.setState({ variationIds });
  };

  renderVariationsList() {
    const variationsList = [];
    const { displayErrors, variations = {}, extraId } = this.props;
    const { variationIds } = this.state;
    for (const [index, id] of variationIds.entries()) {
      /* eslint-disable-next-line */
      let { name = "", totalQuantity = "" } = variations[id] || {};
      totalQuantity = String(totalQuantity);
      variationsList.push(
        <div className="flex" key={id}>
          <AdminInput
            field={`${extraId}-variation-name`}
            fieldName="Name"
            type="text"
            onChange={this.handleVariationChange}
            width="2/3"
            id={id}
            value={name}
            required={true}
            displayError={displayErrors}
          />
          <div
            className={`w-1/3 flex items-end ${
              displayErrors && !name ? "mb-5" : ""
            }`}
          >
            <AdminInput
              field={`${extraId}-variation-totalQuantity`}
              fieldName="Quantity"
              type="number"
              onChange={this.handleVariationChange}
              width="full"
              id={id}
              value={totalQuantity}
              displayError={displayErrors}
              helpText="Leave blank to give this variation an unlimited quantity."
            />
            {index >= minimumVariations && (
              <button
                aria-label="Remove Variation"
                className="px-2 py-3"
                onClick={event => {
                  event.preventDefault();
                  this.handleVariationRemoval(event, id);
                }}
              >
                <FontAwesomeIcon
                  className="text-3xl text-gray-400 hover:text-gray-500"
                  icon={faTimesCircle}
                />
              </button>
            )}
          </div>
        </div>
      );
    }
    return variationsList;
  }

  renderVariationsBlock() {
    return (
      <div className={`border p-2 ${this.props.hidden ? "hidden" : ""}`}>
        {this.renderVariationsList()}
        <div className="w-full">
          <Button
            label={
              <React.Fragment>
                <FontAwesomeIcon icon={faPlus} /> Add Variation
              </React.Fragment>
            }
            buttonStyle="gray"
            onClick={this.handleNewVariation}
          />
        </div>
      </div>
    );
  }

  render() {
    return this.renderVariationsBlock();
  }
}

EventExtrasVariations.propTypes = {
  handleBlockChange: PropTypes.func,
  handleBlockRemoval: PropTypes.func,
  displayErrors: PropTypes.bool,
  extraId: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  variations: PropTypes.object
};

export default EventExtrasVariations;
