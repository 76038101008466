import React from "react";
import PropTypes from "prop-types";

import Input from "../core/Input";
import Button from "../core/Button";

const INITIAL_STATE = {
  displayError: false,
  promo: undefined
};

const percentageMultiplier = 100;

class PromoCode extends React.Component {
  state = INITIAL_STATE;

  componentDidUpdate(prevProps) {
    const timeout = 3500;
    if (prevProps.intent.error) {
      this.setState({ displayError: true });
      setTimeout(() => {
        this.setState({ displayError: false });
      }, timeout);
    }
  }

  renderPromoLabel() {
    const { intent } = this.props;
    const { couponDetails } = intent;
    const { displayError, promo } = this.state;

    if (displayError) {
      return (
        <span className="italic text-red-500">This promo code is invalid!</span>
      );
    }
    if (couponDetails && couponDetails[promo]) {
      const coupon = couponDetails[promo];
      let promoMessage;
      switch (coupon.effect.type) {
        case "percentOff": {
          promoMessage = `${Math.round(
            coupon.effect.factor * percentageMultiplier
          )}% off`;
          break;
        }
        default: {
          break;
        }
      }
      return (
        <span className="italic secondary-color">
          {promoMessage} promo code applied!
        </span>
      );
    }
    return "Enter your promo code";
  }

  handlePromoChange = e => {
    this.setState({ promo: e.target.value });
    this.props.handlePromoChange(e);
  };

  render() {
    const { handleApplyPromo } = this.props;

    return (
      <form
        className="flex items-end md:flex-no-wrap flex-wrap"
        onSubmit={handleApplyPromo}
      >
        <div className="flex-grow m-3">
          <Input
            label={this.renderPromoLabel()}
            type="text"
            field="promo"
            onChange={this.handlePromoChange}
          ></Input>
        </div>
        <Button
          type="submit"
          label="Apply Code"
          extraClass="secondary-bg md:w-auto w-full"
        ></Button>
      </form>
    );
  }
}

PromoCode.propTypes = {
  handlePromoChange: PropTypes.func.isRequired,
  handleApplyPromo: PropTypes.func.isRequired,
  intent: PropTypes.object.isRequired
};

export default PromoCode;
