import React from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "../../react-auth0-spa";
import { Link } from "react-router-dom";
import theme from "../../theme";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faTwitterSquare,
  faYoutube,
  faMedium,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const Footer = props => {
  const { user, loginWithRedirect } = useAuth0();

  const renderSocial = () => {
    /* eslint-disable no-undef */
    const app_social = theme.SOCIAL;
    if (app_social) {
      const social = app_social.split(" ");
      const icons = {
        facebook: faFacebookSquare,
        instagram: faInstagram,
        twitter: faTwitterSquare,
        youtube: faYoutube,
        medium: faMedium,
        linkedin: faLinkedin
      };

      return social.map(item => {
        //let site = isolate social network name from url
        const url = new URL(item.replace("www.", ""));
        const site = url.hostname.split(".")[0];
        const icon = icons[site] ? icons[site] : faLink;
        return (
          <a
            href={item}
            target="_blank"
            rel="noopener noreferrer"
            key={item}
            className="footer-link"
          >
            <FontAwesomeIcon className="fa-3x p-2" icon={icon} />
          </a>
        );
      });
    }
    return null;
  };

  const renderLinks = () => {
    const { isAdmin } = props;
    const url = isAdmin ? "/" : "/admin";
    const label = isAdmin ? "Back to Marketplace" : "Admin Dashboard";
    if (!user) {
      return (
        <p
          className="w-full text-center cursor-pointer hover:underline footer-link"
          onClick={() =>
            loginWithRedirect({
              redirect_uri: `${window.location.origin}/admin`
            })
          }
        >
          Admin Dashboard
        </p>
      );
    }

    return (
      <p className="w-full text-center footer-link">
        <Link to={url}>{label}</Link>
      </p>
    );
  };

  const renderBwLinks = () => {
    return <p className="pt-2 w-full text-center footer-link">
      Powered by IdealSeat:
      <a target="_blank" rel="noopener noreferrer" href="https://www.idealseat.com/" > About Us </a>
      -<a target="_blank" rel="noopener noreferrer" href="https://www.idealseat.com/questionnaire"> Sign Up </a>
    </p>
  };

  return (
    <footer className="p-3 footer print:hidden">
      <div className="container mx-auto flex justify-center items-center flex-col">
        <div className="p-3 flex items-center justify-center flex-wrap">
          {!props.isAdmin && renderSocial()}
          {renderLinks()}
          {renderBwLinks()}
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  isAdmin: PropTypes.bool
};

export default Footer;
