/* eslint-disable no-undef */
export const theme = {
  NAME: "srpac",
  TENANT: "pyxu5a",
  LINKS: [
    {
      url: "https://greenvillerec.com/swamprabbit/",
      label: "About"
    },
    {
      url: "https://www.visitgreenvillesc.com/",
      label: "Visit GVL"
    }
  ],
  SOCIAL:
    "https://www.facebook.com/VisitGreenvilleSC/ https://twitter.com/Greenville_SC https://www.instagram.com/visitgreenvillesc/?hl=en https://www.youtube.com/user/GreenvilleCVB",
  HEADING_1: "Join us for a show!",
  HEADING_2: "Independent, Vibrant, Local, & Adventurous",
  DEMO: true
};
