import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

class SelectInput extends React.Component {
  render() {
    const {
      options,
      containerClass,
      field,
      label,
      onChange,
      value,
      required,
      id,
      isMulti,
      error
    } = this.props;

    const fieldId = id ? `${id}-${field}` : field;

    const colourStyles = {
      control: styles => ({
        ...styles,
        borderColor: error ? "#f56565" : "#e2e8f0",
        minHeight: "42px",
        borderRadius: "0px",
        boxShadow: "none",
        ":hover": {
          ...styles[":active"],
          borderColor: "#cbd5e0"
        }
      }),
      option: (styles, { isSelected, isDisabled }) => {
        return {
          ...styles,
          backgroundColor: isSelected && "#f7fafc",
          color: isSelected ? "#2d3748" : isDisabled && "#e2e8f0",
          fontStyle: isDisabled && "italic"
        };
      },
      multiValue: styles => {
        return {
          ...styles,
          backgroundColor: "#e2e8f0"
        };
      }
    };

    return (
      <div className={containerClass}>
        <label htmlFor={fieldId}>
          <p className="mb-2 flex items-center flex-wrap">
            {label}{" "}
            {required && (
              <FontAwesomeIcon
                icon={faAsterisk}
                className="text-2xs secondary-color ml-1"
              />
            )}
          </p>
        </label>
        <Select
          options={options}
          id={fieldId}
          value={value}
          onChange={onChange}
          required={required}
          isMulti={isMulti}
          styles={colourStyles}
          placeholder=""
        />
        {error && (
          <p className="text-xs italic mt-1 text-red-500">
            This field is required.
          </p>
        )}
      </div>
    );
  }
}

SelectInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  containerClass: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  id: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  error: PropTypes.bool
};

export default SelectInput;
