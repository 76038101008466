import React from "react";
import PropTypes from "prop-types";
import ItemContainer from "./ItemContainer";

class Tickets extends React.Component {
  renderTickets(tickets, eventPools) {
    const { event, selectedTickets, selectTickets, eventPool } = this.props;
    const { ticketBlocks } = selectedTickets;
    let numberOfTicketsSelectedInPool = 0;

    if (ticketBlocks) {
      Object.keys(ticketBlocks).forEach(item => {
        const section = ticketBlocks[item];
        if (section.poolKey === eventPool && section.numberOfTickets > 0) {
          numberOfTicketsSelectedInPool += section.numberOfTickets;
        }
      });
    }

    const maxTicketsInPool =
      event.ticketPools[eventPool] &&
      event.ticketPools[eventPool].availableTickets -
        numberOfTicketsSelectedInPool;

    return tickets.map(section => {
      const pool = event.ticketPools[section.poolKey];
      const availableTickets = section.poolKey
        ? pool.availableTickets
        : section.availableTickets;
      const totalTickets = section.poolKey
        ? pool.totalTickets
        : section.totalTickets;
      if (section.poolKey === eventPool) {
        return (
          <ItemContainer
            item={section}
            name={section.sectionName}
            itemType="ticketBlocks"
            percentAvailable={availableTickets / totalTickets}
            event={event}
            selectedTickets={selectedTickets}
            selectTickets={selectTickets}
            key={section.sectionName}
            availableTickets={availableTickets}
            totalTickets={totalTickets}
            eventPools={eventPools}
            maxTicketsInPool={maxTicketsInPool}
          />
        );
      }
      return null;
    });
  }

  renderPoolHeading() {
    const { event, displayPoolTitle, waitlist, eventPool } = this.props;

    if (eventPool && displayPoolTitle) {
      return (
        <span className="text-xl mr-2">
          {event.ticketPools[eventPool].fullName}
        </span>
      );
    } else if (waitlist) {
      return <span className="text-2xl mr-2">Waitlist Tickets</span>;
    } else if (!eventPool && displayPoolTitle) {
      return <span className="text-2xl mr-2">Special Tickets</span>;
    }
    return null;
  }

  renderWaitlist() {
    const { event, selectedTickets, selectTickets, waitlist } = this.props;
    const sectionData = {
      availableTickets: 500,
      itemId: "waitlist",
      price: "USD 0",
      sectionName: "Waitlist",
      totalTickets: 500
    };
    if (waitlist) {
      return (
        <ItemContainer
          item={sectionData}
          name={sectionData.sectionName}
          itemType="ticketBlocks"
          percentAvailable={null}
          event={event}
          selectedTickets={selectedTickets}
          selectTickets={selectTickets}
          key="waitlist"
          availableTickets={sectionData.availableTickets}
          totalTickets={sectionData.totalTickets}
        />
      );
    }
    return null;
  }

  renderSection() {
    const { event, eventPools, waitlist } = this.props;
    if (waitlist) {
      return this.renderWaitlist();
    }
    const ticketBlocks = event.ticketBlocks.filter(
      item => item.status === "active"
    );
    return this.renderTickets(ticketBlocks, eventPools);
  }

  render() {
    return (
      <React.Fragment>
        <h2 className="mb-6">{this.renderPoolHeading()}</h2>
        {this.renderSection()}
      </React.Fragment>
    );
  }
}

Tickets.propTypes = {
  event: PropTypes.object.isRequired,
  selectedTickets: PropTypes.object.isRequired,
  selectTickets: PropTypes.func.isRequired,
  eventPool: PropTypes.string,
  eventPools: PropTypes.array,
  displayPoolTitle: PropTypes.bool,
  waitlist: PropTypes.bool
};

export default Tickets;
