import React from "react";
import PropTypes from "prop-types";
import HelpBubble from "./HelpBubble";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

class InputLabel extends React.Component {
  render() {
    const {
      field,
      label,
      required,
      id,
      helpText
    } = this.props;

    const fieldId = id ? `${id}-${field}` : field;

    return (
      <label htmlFor={fieldId}>
        <div className="mb-2 flex items-center flex-wrap">
          {label}
          {required && (
            <FontAwesomeIcon
              icon={faAsterisk}
              className="text-2xs secondary-color ml-1"
            />
          )}
          {helpText && <HelpBubble helpText={helpText} />}
        </div>
      </label>
    );
  }
}

InputLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  field: PropTypes.string.isRequired,
  required: PropTypes.bool,
  id: PropTypes.string,
  helpText: PropTypes.string
};

export default InputLabel;
