/* eslint-disable no-undef */
export const theme = {
  // NAME needs to match the theme folder name EXACTLY
  NAME: "holmes",
  TENANT: "28uazm",
  LINKS: [
    {
      url: "https://www.holmessweets.com",
      label: "Home Page"
    },
    {
      url: "https://www.holmessweets.com/buy-treats-online",
      label: "Shop Online"
    }
  ],
  SOCIAL: "https://www.instagram.com/holmessweets",
  HEADING_1: "whole ingredients. pure taste.",
  HEADING_2: "Holmes Sweet Home"
};
