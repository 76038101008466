/* eslint-disable no-undef */
export const theme = {
  // NAME needs to match the theme folder name EXACTLY
  NAME: "example",
  TENANT: "abc123",
  // External header links
  LINKS: [
    {
      url: "https://bandwagonfanclub.com/",
      label: "BandwagonFanClub"
    },
    {
      url: "https://idealseat.com/",
      label: "IdealSeat"
    }
  ],
  // Social media links for footer
  SOCIAL:
    "https://www.instagram.com/idealseat/ https://www.facebook.com/IdealSeat/ https://www.linkedin.com/company/idealseat/",
  // This might seem a bit backwards, but HEADING_1 is the larger text on the
  // second line of the landing page, while HEADING_2 is the first line but it's
  // smaller in size
  HEADING_1: "BandwagonFanClub!",
  HEADING_2: "Welcome to an IdealSeat marketplace for",
  // Below are optional
  // Check client's questionnaire response for HIDESEARCHBAR
  // DEMO should only be true for sites we use as demos
  HIDESEARCHBAR: true,
  DEMO: true
};
