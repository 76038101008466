import React from "react";
import PropTypes from "prop-types";

import EventActionMenu from "../core/EventActionMenu";
import EventMenuItems from "./EventMenuItems";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faChevronDown } from "@fortawesome/free-solid-svg-icons";

const INITIAL_STATE = {
  displayMenu: false
};

class ActionButton extends React.Component {
  state = INITIAL_STATE;

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  renderButton() {
    const { edit } = this.props;
    if (edit) {
      return (
        <button
          className="bg-gray-300 hover:bg-gray-400 w-8 h-8 text-center rounded-full flex justify-center items-center cursor-pointer ml-6"
          aria-label="view more event actions"
          onClick={e => {
            e.preventDefault();
            this.setState(prevState => ({
              displayMenu: !prevState.displayMenu
            }));
          }}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
      );
    }
    return (
      <FontAwesomeIcon
        icon={faEllipsisV}
        className="cursor-pointer"
        onClick={() =>
          this.setState(prevState => ({
            displayMenu: !prevState.displayMenu
          }))
        }
      />
    );
  }

  deleteEvent = eventSlug => {
    this.setState({ displayMenu: false });
    this.props.deleteEvent(eventSlug);
  };

  cancelEvent = eventSlug => {
    this.setState({ displayMenu: false });
    this.props.cancelEvent(eventSlug);
  };

  render() {
    const { displayMenu } = this.state;
    const { edit, link, isPublished, current } = this.props;

    return (
      <div className="relative" ref={this.wrapperRef}>
        {this.renderButton()}
        <EventActionMenu display={displayMenu} position="right">
          <EventMenuItems
            edit={edit}
            link={link}
            cancelEvent={this.cancelEvent}
            isPublished={isPublished}
            deleteEvent={this.deleteEvent}
            current={current}
          />
        </EventActionMenu>
      </div>
    );
  }
}

ActionButton.propTypes = {
  edit: PropTypes.bool,
  link: PropTypes.string.isRequired,
  cancelEvent: PropTypes.func,
  deleteEvent: PropTypes.func,
  isPublished: PropTypes.bool,
  current: PropTypes.bool.isRequired
};

export default ActionButton;
