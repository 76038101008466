import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemContainer from "../ItemContainer";
import Carousel from "../Carousel";
import {
  faAsterisk,
  faChevronDown,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import { createMarkup } from "../../../utils";

const INITIAL_STATE = {
  expanded: false
};

class ExtraWithVariations extends React.Component {
  state = INITIAL_STATE;

  renderDescriptionAndVariations() {
    const { expanded } = this.state;
    const { variations } = this.props;
    const { description, images } = variations[0];
    const hasImage = images && images[0];
    const hasDescription = description && description.length > 0;
    const hasBoth = hasDescription && hasImage;

    if (expanded) {
      return (
        <div className="border border-t-0 bg-white">
          <div className="flex flex-wrap sm:flex-no-wrap">
            {hasImage && (
              <Carousel
                images={images}
                containerClass={hasBoth ? "sm:w-2/5" : "sm:w-3/5 m-auto"}
              />
            )}
            {hasDescription && (
              <div
                className={`px-6 py-3 ${hasBoth ? "sm:w-3/5" : ""}`}
                dangerouslySetInnerHTML={createMarkup(description)}
              />
            )}
          </div>

          {this.renderVariations(variations)}
        </div>
      );
    }
    return null;
  }

  renderToggle() {
    const { expanded } = this.state;
    return (
      <button
        className="text-gray-400 text-lg px-3 cursor-pointer"
        onClick={() =>
          this.setState(prevState => ({
            expanded: !prevState.expanded
          }))
        }
      >
        <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
      </button>
    );
  }

  renderExtraName(name) {
    if (this.props.isRestrictedExtra) {
      return (
        <span>
          <FontAwesomeIcon
            icon={faAsterisk}
            className="fa-xs secondary-color"
          />{" "}
          {name}{" "}
        </span>
      );
    }
    return `${name} `;
  }

  renderVariations(variations) {
    const { event, selectedTickets, selectTickets } = this.props;
    const variationElements = [];

    for (const variation of variations) {
      const percentAvailable =
        variation.availableQuantity / variation.totalQuantity;
      const unlimited = variation.totalQuantity === "unlimited";

      variationElements.push(
        <ItemContainer
          item={variation}
          event={event}
          itemType="extras"
          name={variation.name}
          unlimited={unlimited}
          percentAvailable={percentAvailable}
          selectedTickets={selectedTickets}
          selectTickets={selectTickets}
          key={variation.name}
          isVariation={true}
        />
      );
    }
    return variationElements;
  }

  render() {
    const { eventIsCanceled, variations } = this.props;
    const { price, heading } = variations[0];

    return (
      <div className="w-full">
        <div className="my-5">
          <div className="border mt-3 p-6 flex justify-between items-center bg-white">
            <div>
              <p className={`${eventIsCanceled && "line-through"}`}>
                {this.renderExtraName(heading)}
                <span className="font-bold">{price.replace("USD ", "$")}</span>
              </p>
            </div>
            {this.renderToggle()}
          </div>
          {this.renderDescriptionAndVariations()}
        </div>
      </div>
    );
  }
}

ExtraWithVariations.propTypes = {
  variations: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  isRestrictedExtra: PropTypes.bool,
  eventIsCanceled: PropTypes.bool,
  selectedTickets: PropTypes.object.isRequired,
  selectTickets: PropTypes.func.isRequired
};

export default ExtraWithVariations;
