/* eslint-disable no-undef */
export const theme = {
  // NAME needs to match the theme folder name EXACTLY
  NAME: "hamtest",
  TENANT: "u5n9td",
  // External header links
  LINKS: [
    {
      url: "https://shieldsbetter.com/",
      label: "Shieldsbetter"
    }
  ],
  // Social media links for footer
  SOCIAL: "https://twitter.com/hamptos",
  // This might seem a bit backwards, but HEADING_1 is the larger text on the
  // second line of the landing page, while HEADING_2 is the first line but it's
  // smaller in size
  HEADING_1: "Production Migration",
  HEADING_2: "Hampton's Idealseat Test for",
  // Below are optional
  // Check client's questionnaire response for HIDESEARCHBAR
  // DEMO should only be true for sites we use as demos
  HIDESEARCHBAR: true
};
