import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
// NOTE: will probably rename or refactor this as app grows
class EmptyPage extends React.Component {
  renderButton() {
    if (this.props.onClick) {
      return (
        <Button label={this.props.buttonLabel} onClick={this.props.onClick} />
      );
    }
    return (
      <Button label={this.props.buttonLabel} path="/events" isLink={true} />
    );
  }

  render() {
    return (
      <div className="w-full flex flex-wrap flex-col items-center justify-center flex-grow">
        <h1 className="text-2xl text-center">{this.props.heading}</h1>
        {this.renderButton()}
      </div>
    );
  }
}

EmptyPage.propTypes = {
  heading: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default EmptyPage;
