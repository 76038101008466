import React from "react";
import PropTypes from "prop-types";

class EventFlag extends React.Component {
  render() {
    return (
      <div
        className={`flex flex-col items-center w-20 py-3 mx-3 event-flag mb-3 font-bold tracking-wide uppercase ${
          this.props.disabled ? "bg-gray-300 h-20" : "secondary-bg"
        }`}
      >
        <p>{this.props.month ? this.props.month : ""}</p>
        <p className="text-2xl">{this.props.day}</p>
      </div>
    );
  }
}

EventFlag.propTypes = {
  month: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default EventFlag;
