import React from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import Button from "./Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export class ConfirmationModal extends React.Component {
  render() {
    const { heading, actionText, cancel, action, body } = this.props;
    return (
      <Modal>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="fa-2x text-red-500"
        />
        <p className="w-full text-center my-6">
          <span className="text-xl font-bold">{heading}</span>
          <br />
          <span className="text-lg">{body}</span>
        </p>
        <Button
          label="Cancel"
          extraClass="min-w-32"
          onClick={cancel}
          buttonStyle="gray"
        />
        <Button
          label={actionText}
          extraClass="min-w-32"
          onClick={action}
          buttonStyle="warning"
        />
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = {
  heading: PropTypes.string.isRequired,
  actionText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  action: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  body: PropTypes.string.isRequired
};

export default ConfirmationModal;
