import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Buttons from "./Buttons";
import FormInputs from "./FormInputs";
import Summary from "./Summary";

import { selectTickets } from "../../ducks/selectedTickets";
import { addToWaitlist } from "../../ducks/waitlist";
import { setOrderSuccess } from "../../ducks/orderSuccess";

const INITIAL_STATE = {
  charging: false,
  error: false,
  errorMessage: "Something went wrong! Try again."
};

class Waitlist extends Component {
  state = INITIAL_STATE;
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async submit(ev) {
    ev.preventDefault();
    this.setState({ charging: true });
    const { email } = this.state;
    const { event, ticketBlocks } = this.props.selectedTickets;
    const { numberOfTickets } = ticketBlocks.waitlist;
    const items = { waitlist: { count: numberOfTickets } };

    // prettier-ignore
    const { error } = await this.props.addToWaitlist(event, email, { items });

    if (error) {
      this.setState({ charging: false, error: true });
      if (this.props.waitlist.error.code === "EMAIL_ALREADY_ON_WAITLIST") {
        this.setState({
          errorMessage: "This email is already on the waitlist for this event."
        });
      }
    } else {
      this.props.selectTickets({});
      this.props.setOrderSuccess();
    }
  }

  renderForm() {
    const { charging } = this.state;
    const { selectTickets } = this.props;
    return (
      <form
        onSubmit={this.submit}
        className="md:w-1/2 w-full flex flex-wrap flex-col"
      >
        <h1 className="text-2xl py-3 mb-3 border-b w-full">
          {this.props.heading}
        </h1>
        {this.renderError()}
        <FormInputs
          onChange={this.handleInputChange}
          disabled={charging}
          waitlist={true}
        />
        <Buttons
          charging={charging}
          selectTickets={selectTickets}
          waitlist={true}
        />
      </form>
    );
  }

  renderError() {
    const { error } = this.state;
    if (error) {
      return (
        <p className="text-red-500 text-xl text-center py-2">
          {this.state.errorMessage}
        </p>
      );
    }
    return null;
  }

  renderSummary() {
    const { selectedTickets } = this.props;
    return <Summary selectedTickets={selectedTickets} isWaitlist={true} />;
  }

  render() {
    return (
      <React.Fragment>
        {this.renderForm()}
        {this.renderSummary()}
      </React.Fragment>
    );
  }
}

Waitlist.propTypes = {
  selectTickets: PropTypes.func.isRequired,
  addToWaitlist: PropTypes.func.isRequired,
  selectedTickets: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  setOrderSuccess: PropTypes.func.isRequired,
  waitlist: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    waitlist: state.waitlist
  };
};

export default connect(mapStateToProps, {
  selectTickets,
  addToWaitlist,
  setOrderSuccess
})(Waitlist);
