import React from "react";
import PropTypes from "prop-types";

export class Modal extends React.Component {
  render() {
    return (
      <div className="modal fixed inset-0 h-full w-full z-10 flex justify-center items-center">
        <div
          className="bg-white border p-6 max-w-lg flex justify-center items-center flex-wrap"
          data-testid="modal"
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.array.isRequired
};

export default Modal;
