import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";

export class TablePagination extends React.Component {
  renderNext() {
    const { more, handleNext } = this.props;

    const disabled = !more;
    const color = more ? "accent-bg" : "bg-gray-200 text-gray-500";

    return (
      <button
        className={`py-2 px-4 -button ml-auto ${color}`}
        onClick={handleNext}
        disabled={disabled}
      >
        <p className="text-lg">
          <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
        </p>
      </button>
    );
  }

  renderPrevious() {
    const { handlePrevious, currentIndex } = this.props;

    const disabled = currentIndex <= 0;
    const color = currentIndex > 0 ? "accent-bg" : "bg-gray-200 text-gray-500";

    return (
      <button
        className={`py-2 px-4 -button ${color}`}
        onClick={handlePrevious}
        disabled={disabled}
      >
        <p className="text-lg">
          <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
        </p>
      </button>
    );
  }

  render() {
    const { width } = this.props;
    return (
      <div className={`flex justify-between px-4 lg:w-${width}`}>
        {this.renderPrevious()}
        {this.renderNext()}
      </div>
    );
  }
}

TablePagination.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  more: PropTypes.bool,
  width: PropTypes.string.isRequired
};

export default withRouter(TablePagination);
