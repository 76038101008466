const initialState = { key: undefined };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case "GET_CAPTCHA_KEY_REQUEST":
      return { ...state, isFetching: true };
    case "GET_CAPTCHA_KEY_SUCCESS":
      return { ...state, isFetching: false, key: action.payload };
    case "GET_CAPTCHA_KEY_FAILURE":
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload.message
      };
    default:
      return state;
  }
}

export const getCaptchaKey = () => (dispatch, getState) => {
  dispatch({ type: "GET_CAPTCHA_KEY_REQUEST" });

  return getState().apis.marketplace
    .get("/config/captchaPublishableKey")
    .then(results =>
      dispatch({ type: "GET_CAPTCHA_KEY_SUCCESS", payload: results.data })
    )
    .catch(error =>
      dispatch({ type: "GET_CAPTCHA_KEY_FAILURE", payload: error, error: true })
    );
};
