import React from "react";
import PropTypes from "prop-types";

import HelpBubble from "../core/HelpBubble";

class CheckboxInput extends React.Component {
  renderOptions() {
    const { fieldName, fieldState, handleInputChange, options } = this.props;

    return options.map(option => {
      return (
        <span className="pl-4" key={option.field}>
          <input
            type="checkbox"
            id={option.field}
            name={fieldName}
            onChange={handleInputChange}
            checked={fieldState || false}
          />
          <label htmlFor={option.field} className="pl-2">
            {option.label}
          </label>
        </span>
      );
    });
  }

  renderField() {
    const { label, helpText, bordered, containerClass } = this.props;

    return (
      <div
        className={`${containerClass} w-full flex justify-between flex-wrap ${
          bordered ? "py-2 border mt-8" : "py-4"
        }`}
      >
        <div className="inline flex flex-grow">
          {label}
          {helpText && <HelpBubble helpText={helpText} />}
        </div>
        <span>{this.renderOptions()}</span>
      </div>
    );
  }

  render() {
    return this.renderField();
  }
}

CheckboxInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldState: PropTypes.bool,
  handleInputChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  bordered: PropTypes.bool,
  containerClass: PropTypes.string
};

export default CheckboxInput;
