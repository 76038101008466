import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { createMarkup } from "../../utils";
import Carousel from "./Carousel";

const INITIAL_STATE = {
  expanded: false
};

class Item extends React.Component {
  state = INITIAL_STATE;

  renderDescription() {
    const { item } = this.props;
    const { description, images } = item;
    const hasImage = images && images[0];
    const hasDescription = description && description.length > 0;
    const hasBoth = hasDescription && hasImage;

    return (
      <div className="border border-t-0 bg-white">
        <div className="px-3 flex flex-wrap sm:flex-no-wrap">
          {hasImage && (
            <Carousel
              images={images}
              containerClass={hasBoth ? "sm:w-2/5" : "sm:w-3/5 m-auto"}
            />
          )}
          {hasDescription && (
            <div
              className={`px-6 py-3 ${hasBoth ? "sm:w-3/5" : ""}`}
              dangerouslySetInnerHTML={createMarkup(description)}
            />
          )}
        </div>
      </div>
    );
  }

  renderDescriptionButton() {
    return (
      <button
        className="mr-auto ml-3 text-gray-400 text-lg"
        onClick={() =>
          this.setState(prevState => ({
            expanded: !prevState.expanded
          }))
        }
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </button>
    );
  }

  lowAvailability(itemsLeft) {
    const { displayLowItems, displayError } = this.props;
    if (displayLowItems || displayError) {
      const message = itemsLeft === "0" ? "None " : `Only ${itemsLeft}`;
      return (
        <p className={`text-sm italic ${displayError && "text-red-500"}`}>
          {message} left!
        </p>
      );
    }
    return null;
  }

  renderExtraName(name) {
    if (this.props.isRestricted) {
      return (
        <span>
          <FontAwesomeIcon
            icon={faAsterisk}
            className="fa-xs secondary-color align-baseline"
          />{" "}
          {name}
        </span>
      );
    }
    return name;
  }

  render() {
    const {
      item,
      price,
      numberOfItems,
      eventIsCanceled,
      isVariation,
      availableQuantity,
      itemName
    } = this.props;
    const { expanded } = this.state;
    const { description, images, poolKey } = item;
    const hasDescription = description || (images && images[0]);
    const name = item.heading ? `${item.heading}: ${itemName}` : itemName;

    return (
      <div className="w-full">
        <div className={isVariation ? "" : "my-5"}>
          <div
            className={`${
              isVariation ? "border-t" : "border mt-3"
            } p-6 flex justify-between items-center bg-white`}
          >
            <div>
              <p className={`${eventIsCanceled && "line-through"}`}>
                {this.renderExtraName(name)}
                {!isVariation && <span className="font-bold"> {price}</span>}
              </p>
              {this.lowAvailability(availableQuantity)}
            </div>
            {!isVariation && hasDescription && this.renderDescriptionButton()}
            <input
              type="number"
              className="border p-2 w-24 ml-3"
              min="0"
              max={availableQuantity}
              value={numberOfItems}
              onChange={this.props.handleItemSelection}
              data-name={name}
              data-price={price.replace("$", "")}
              data-id={item.itemId}
              placeholder="0"
              disabled={eventIsCanceled}
              data-poolkey={poolKey}
            ></input>
          </div>
          {hasDescription && expanded && this.renderDescription()}
        </div>
      </div>
    );
  }
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  price: PropTypes.string.isRequired,
  numberOfItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  displayLowItems: PropTypes.bool.isRequired,
  displayError: PropTypes.bool.isRequired,
  handleItemSelection: PropTypes.func.isRequired,
  isRestricted: PropTypes.bool,
  eventIsCanceled: PropTypes.bool,
  isVariation: PropTypes.bool,
  availableQuantity: PropTypes.number,
  itemName: PropTypes.string.isRequired
};

export default Item;
