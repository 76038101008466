import React from "react";
import PropTypes from "prop-types";
import EventsTable from "./EventsTable";
import { formatDate, eventStatus } from "../../utils";

class EventsResults extends React.Component {
  render() {
    if (this.props.events.length > 0) {
      const data = this.props.events;
      const resolvedData = data.map(row => {
        const eventDate = formatDate(row.dateAndTime);
        const location = row.venue.name || row.venue.address || "Online";
        const isCanceled =
          eventStatus(row.availability, row.visibility) === "Canceled";
        row.event = {
          dateAndTime: {
            month: eventDate.monthShort,
            day: eventDate.day
          },
          name: isCanceled ? `CANCELED: ${row.name}` : row.name,
          venue: location,
          node: row.node
        };
        return row;
      });

      return <EventsTable resolvedData={resolvedData} />;
    }
    return <p className="mx-3 my-6">Your search returned no results.</p>;
  }
}

EventsResults.propTypes = {
  events: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
};

export default EventsResults;
