import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import SearchBar from "../core/SearchBar";
import EventsLoading from "./EventsLoading";
import EventsResults from "./EventsResults";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown } from "@fortawesome/free-solid-svg-icons";

class Content extends React.Component {
  renderHeading() {
    const { query, events } = this.props;
    const { pathname } = this.props.location;
    const heading = query
      ? `Search Results: ${query}`
      : events.errorMessage
      ? "Something went wrong!"
      : "All Events";
    return (
      <div className="flex items-center lg:w-2/3 md:flex-row flex-col mx-3 md:mx-0">
        <h1 className="text-2xl md:ml-3 mr-auto">{heading}</h1>
        <SearchBar placeholder="New Search" pathname={pathname} />
      </div>
    );
  }

  renderContent() {
    const { events, page, pages } = this.props;
    const eventsData = !pages[page] ? events.data : pages[page].data;

    if (events.isFetching) {
      return <EventsLoading />;
    } else if (!events.errorMessage) {
      return <EventsResults events={eventsData} />;
    }
    return (
      <div className="h-full flex justify-center items-center">
        <FontAwesomeIcon icon={faFrown} className="fa-8x text-gray-300" />
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderHeading()}
        {this.renderContent()}
      </React.Fragment>
    );
  }
}

Content.propTypes = {
  query: PropTypes.string,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pages: PropTypes.object,
  events: PropTypes.object,
  location: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    events: state.events
  };
};

export default withRouter(connect(mapStateToProps, {})(Content));
