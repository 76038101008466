import React from "react";
import { formatDate } from "../../utils";
import { Link } from "react-router-dom";
import Ticket from "./Ticket";
import PropTypes from "prop-types";

const INITIAL_STATE = {
  imageError: false
};

class Highlight extends React.Component {
  state = INITIAL_STATE;

  onImageError() {
    this.setState({ imageError: true });
  }

  renderImage() {
    const { event } = this.props;
    const image = event.photos && event.photos[0];

    const { imageError } = this.state;
    if (imageError || !image) {
      return <Ticket />;
    }
    return (
      <img
        onError={() => this.onImageError()}
        className="object-cover w-full h-full"
        src={image.url}
        alt={image.alt}
      />
    );
  }

  renderHighlight() {
    const { event } = this.props;
    const { imageError } = this.state;
    const eventDate = formatDate(event.dateAndTime);

    return (
      <Link to={`/${event.node}`} className="w-full">
        <div className="p-3">
          <div
            className={`lg:h-40 h-48 border ${
              imageError ? " bg-gray-200" : ""
            }`}
          >
            {this.renderImage()}
          </div>
          <div className="pt-3">
            <p className="primary-color font-semibold">
              {eventDate.weekday}, {eventDate.monthShort} {eventDate.day} at {eventDate.time}
            </p>
            <p className="text-xl font-bold">{event.name}</p>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    return (
      <div className="lg:w-2/6 ">
        <div className="flex bg-white mx-6 my-3 flex-wrap rounded hover:shadow-xl">
          {this.renderHighlight()}
        </div>
      </div>
    );
  }
}

Highlight.propTypes = {
  event: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired
};

export default Highlight;
