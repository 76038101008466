const REQUEST = "marketplace/tickets/REQUEST";
const SUCCESS = "marketplace/tickets/SUCCESS";
const FAILURE = "marketplace/tickets/FAILURE";

const initialState = { tickets: [] };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case SUCCESS:
      return {
        ...state,
        isFetching: false,
        tickets: action.payload.tickets,
        event: action.payload.event
      };
    case FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload.message
      };
    default:
      return state;
  }
}

export const fetchTickets = claimId => (dispatch, getState) => {
  dispatch({ type: REQUEST });

  return getState().apis.marketplace
    .get(`/claims/${claimId}/tickets`)
    .then(results => dispatch({ type: SUCCESS, payload: results.data }))
    .catch(error => dispatch({ type: FAILURE, payload: error, error: true }));
};
