import React from "react";
import PropTypes from "prop-types";

import HelpBubble from "../../core/HelpBubble";

class AdminRadioInput extends React.Component {
  renderOptions() {
    const { fieldName, fieldState, handleInputChange, options } = this.props;

    return options.map(option => {
      return (
        <span className="pl-4" key={option.field}>
          <input
            type="radio"
            id={option.field}
            name={fieldName}
            value={option.field}
            onChange={handleInputChange}
            checked={fieldState === option.field}
          />
          <label htmlFor={option.field} className="pl-2">
            {option.label}
          </label>
        </span>
      );
    });
  }

  renderField() {
    const { label, helpText, width, containerClass, optionsClass, labelClass } = this.props;

    return (
      <div className={`w-full${width ? ` sm:w-${width}` : ""} ${containerClass}`}>
        <div className={`${labelClass} inline flex flex items-center flex-wrap`}>
          {label}
          {helpText && <HelpBubble helpText={helpText} />}
        </div>
        <span className={`${optionsClass}  `}>{this.renderOptions()}</span>
      </div>
    );
  }

  render() {
    return this.renderField();
  }
}

AdminRadioInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldState: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  helpText: PropTypes.string,
  width: PropTypes.string,
  containerClass: PropTypes.string,
  optionsClass: PropTypes.string
};

export default AdminRadioInput;
